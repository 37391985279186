import { Injectable } from '@angular/core';
import { BProduct } from '../region/region';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { isNil, omitBy } from 'lodash';

export type TProductData = {
  id: string;
  name: string;
  productGroupIds: string[];
  therapeuticAreaIds: string[];
  isActive: boolean;
  productCode: string;
  removedTherapeuticAreaId?: number | null;
  addedTherapeuticAreaId?: number;
};

export type TProductDialogData = {
  product: BProduct;
};

export type TProductUpdateResponse = {
  message?: string;
  hasMetadataDeactivation?: boolean;
  hasDeactivatedRelatedMetadata?: boolean;
  hasAffectedRelatedTherapeuticArea?: boolean;
};

export type TProductParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  name?: string;
  productGroupIds?: string;
  therapeuticAreaIds?: string;
  isActive?: string;
  productCode?: number;
};

@Injectable({ providedIn: 'root' })
export class ProductRestService {
  private readonly URL: string;

  constructor(private http: HttpClient, urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/metadata/products');
  }

  fetchProducts(params: TProductParams): Observable<SimplePage<BProduct>> {
    return this.http
      .get<SimplePage<BProduct>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          return { ...response, records: BProduct.fromRestArray(response.records) };
        })
      );
  }

  edit(data: TProductData): Observable<TProductUpdateResponse> {
    return this.http.put<TProductUpdateResponse>(this.URL, omitBy(data, isNil));
  }

  create(data: TProductData): Observable<void> {
    return this.http.post<void>(this.URL, omitBy(data, isNil));
  }
}
