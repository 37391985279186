export const russianLangData = {
  MEDICAL_INFO_SYSTEM: 'Медицинская Информационная Система',
  ACTIVE_EVENTS: 'Активные события',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Выберите событие для просмотра пользователей.',
  BACK_TO_THE_EVENT_SELECTION: 'Вернуться к выбору мероприятия',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Если вы забыли свой пароль',
  LOGIN: 'Авторизоваться',
  RESET_IT_HERE: 'сбросить это здесь',
  CHANGE_LANGUAGE_TO: 'Изменить язык на',
  TEST_SYSTEM: 'СИСТЕМА ИСПЫТАНИЙ',
  HELP: 'Помогите',
  THIS_IS_TEST_ENV: 'Это тестовая среда, если вы хотите вставить реальный запрос, перейдите на',
  USER_OR_PASS_IS_INCORRECT: 'Логин или пароль неверны',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'Мы не нашли то, что вы искали',
  VIDEO_TUTORIALS: 'Видео уроки',
  STILL_DIDNT_FIND: 'Все еще не нашел то, что вы ищете,',
  ASK_THE_SUPPORT_TEAM: 'Задайте вопрос в службу поддержки',
  LOGGED_IN_AS: 'Вы вошли как',
  MY_PROFILE: 'Мой профайл',
  LOGOUT: 'Выйти',
  RETRIEVING_YOUR_ACCESS_INFO: 'Получение информации о вашем доступе',
  LOGGED_IN_CAN_CLOSE: 'Вы вошли в систему, теперь вы можете закрыть эту вкладку',
  TOKEN_HAS_NOT_BEEN_SAVED: 'Токен не был сохранен должным образом, обратитесь в службу поддержки',
  SHARE_POINT_LOGIN: 'Вход в SharePoint',
  LOGIN_TO_SHARE_POINT: 'Пожалуйста, войдите в SharePoint, чтобы продолжить',
  PASSWORD_RESET: 'Сброс пароля',
  RESET_PASSWORD: 'Сброс пароля',
  INSERT_YOUR_EMAIL_TO_RESET_PASS:
    'Пожалуйста, введите адрес электронной почты, чтобы сбросить пароль',
  CANCEL: 'Отмена',
  CANCEL_UPPERCASE: 'ОТМЕНА',
  RETURN_TO_LOGIN: 'Вернуться к логину',
  USERNAME: 'имя пользователя',
  USERNAME_IS_REQUIRED: 'Имя пользователя требуется',
  PASSWORD: 'пароль',
  PASSWORD_IS_REQUIRED: 'необходим пароль',
  FIRST_NAME: 'Имя',
  LAST_NAME: 'Фамилия',
  REGISTER: 'регистр',
  LOADING: 'загрузка',
  INSERT_NEW_PASS: 'Пожалуйста, введите ваш новый пароль',
  VERIFYING_TOKEN: 'Проверочный токен',
  PASSWORD_DO_NOT_MATCH: 'Пароли не соответствуют',
  PASS_RESET_SUCCESSFUL: 'Сброс пароля успешен',
  SAVE_NEW_PASS: 'Сохранить новый пароль',
  GO_TO_INBOX: 'Перейти в папку «Входящие»',
  SELECT_ALL: 'Выбрать все',
  DESELECT_ALL: 'Убрать выделение со всего',
  DO_NOT_SHOW_AGAIN: 'Не показывай снова',
  INQUIRY: 'запрос',
  ATTACHMENT_WITH_THIS_INQUIRY: '> Приложения с этим запросом',
  USER_DETAILS: 'Данные пользователя',
  CLOSE: 'близко',
  TEAMS: 'Команды',
  TEAM: 'команда',
  SPECIALIZATION_IS: 'Специализация',
  REQUIRED: 'обязательный',
  RESULTS_PER_PAGE: 'результатов на страницу',
  RESULTS_FOUND: 'Найдено результатов',
  EXPORT: 'экспорт',
  PERSONAL_SETTINGS: 'Личные настройки',
  TEAM_SETTINGS: 'Настройки команды',
  USER_SETTINGS_OF: 'ПОЛЬЗОВАТЕЛЬСКИЕ НАСТРОЙКИ',
  SET_TEMPLATE: 'Установить шаблон',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Уведомления будут перезаписаны текущими',
  PREVIEW: 'предварительный просмотр',
  NOTIFICATIONS: 'УВЕДОМЛЕНИЯ',
  ROLE: 'РОЛЬ',
  TEMPLATE_NAME: 'ИМЯ ШАБЛОНА',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Сохранить этого пользователя как шаблон',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Добавить шаблон для вашего пользователя и скопировать роль и настройки для уведомлений',
  AUTH_AND_NOTIFY_TEMPLATE: 'Шаблон авторизации и уведомлений',
  MY_ROLES: 'Мои роли',
  STATUS: 'ПОЛОЖЕНИЕ ДЕЛ',
  THIS_ROLE_IS_TEMP:
    'Эта роль является временной, она была дана в связи с тем, что этот пользователь является депутатом',
  CREATED: 'СОЗДАНО',
  APPROVED_REJECTED: 'УТВЕРЖДЕН / REJECTED',
  APPROVED_REJECTED_BY: 'УТВЕРЖДЕНО / ОТКАЗАНО',
  ARCHIVE: 'АРХИВ',
  SAVE: 'Сохранить',
  IMMEDIATE_EFFECT: 'Немедленный эффект',
  OUT_OF_OFFICE_DEPUTY: 'Вне офиса заместителя',
  AUTO_ASSIGN_TO: 'AUTO ASSIGN TO',
  GEOGRAPHY: 'ГЕОГРАФИЯ',
  ALIGNMENT: 'ALIGNMENT',
  Alignments: 'Трассы',
  Address: 'Адрес',
  PHONE_FAX: 'Телефон / факс',
  Email: 'Электронное письмо',
  Contacts: 'контакты',
  WORK_INFO: 'Информация о работе',
  LAST_NAME_IS: 'Фамилия',
  PERSONAL_INFO: 'Личные данные',
  USER: 'пользователь',
  ID: 'ID',
  UPDATED_FROM: 'обновлено с',
  ON: 'на',
  CRM: 'CRM',
  IMPORTED_FROM: 'импортировано из',
  CHANGE_PASS: 'Сменить пароль',
  NOTIFICATION_IN_TEMPLATE: 'Уведомления в шаблоне',
  CONTINUE: 'Продолжить',
  DONT_SHOW_AGAIN: 'Больше не показывать это сообщение',
  USING_THE_TABS_ON_THE: 'используя вкладки в верхней части экрана',
  PERSONAL_PROFILE_SETTINGS: 'Настройки личного профиля',
  AND_YOUR: 'и ваш',
  YOU_CAN_SWITCH_BETWEEN: 'Вы можете переключаться между',
  WELCOME_TO_YOUR_USER_PROFILE: 'Добро пожаловать в ваш профиль пользователя',
  SAVE_THIS_USER_AS: 'Сохранить этого пользователя как шаблон',
  INCLUDE_NOTIFY_SETTINGS: 'Включает настройки уведомлений',
  INCLUDE_ROLES_SETTINGS: 'Включает настройки ролей',
  ACTIVE_NOTIFICATIONS: 'АКТИВНЫЕ УВЕДОМЛЕНИЯ',
  SET_THIS_USER_AS_A_: 'Установить эту авторизацию пользователя и уведомления как новый шаблон',
  REQUEST_AUTH: 'Запрос авторизации',
  REQUEST: 'Запрос',
  FEEDBACK_SUPPORT: 'Обратная связь и поддержка',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Если вы все еще не можете найти то, что ищете, обратитесь в службу поддержки! Кроме того, если у вас есть предложение для будущих улучшений, это правильное место.',
  TEMPLATE: 'шаблон',
  SIGNATURE: 'Подписи',
  Disclaimers: 'Отказ от ответственности',
  Greetings: 'Приветствую',
  CUSTOMIZE: 'АДАПТАЦИЯ',
  SAVE_UPPERCASE: 'СПАСТИ',
  DELETE: 'УДАЛИТЬ',
  RESET_TO_DEFAULT: 'ВОССТАНОВЛЕНИЕ ЗНАЧЕНИЙ ПО УМОЛЧАНИЮ',
  AFTER_SIGNATURE: 'После подписи',
  BEFORE_SIGNATURE: 'Перед подписью',
  AFTER_ANSWER: 'После ответа',
  BEFORE_ANSWER: 'Перед ответом',
  AFTER_GREET: 'После приветствия',
  BEFORE_GREET: 'Перед приветствием',
  Any: 'Любые',
  HCP: 'HCP',
  NO_HCP: 'Нет HCP',
  Position: 'Позиция',
  ON_LABEL: 'На этикетке',
  OFF_LABEL: 'Off Label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Вы пытаетесь настроить шаблон',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'У вас нет разрешения на это. Если вы хотите продолжить, будет создана копия шаблона, и вы внесете в нее изменения.',
  CREATE_COPY: 'Создать копию',
  RECEIVED_ON: 'Получено на',
  SENDER_EMAIL: 'E-mail отправителя',
  SUBJECT: 'Тема',
  UNSPAM: 'Unspam',
  UPLOAD: 'Загрузить',
  APPROVED: 'Одобренный',
  DRAFT: 'Черновой вариант',
  SELECT_STATUS: 'Выберите статус',
  UPLOAD_AS: 'Загрузить как',
  TITLE: 'заглавие',
  REPOSITORY: 'вместилище',
  BROWSE: 'Просматривать',
  URL: 'URL',
  APPROVED_ON: 'Утверждено на',
  AUTH_HOLDER: 'Держатель авторизации',
  AUTH_NUMBERS: 'Номера авторизации',
  APPROVED_FOR: 'Утверждено для',
  SELECT_ONE_REGION: 'Выберите один регион',
  OWNED_BY: 'Принадлежит',
  KEYWORDS: 'Ключевые слова',
  TOPICS: 'темы',
  CATEGORIES: 'категории',
  PRODUCTS: 'Продукты',
  PRODUCT: 'Товар',
  LANGUAGE_IS_REQUIRED: 'Язык требуется',
  LANGUAGE: 'язык',
  TARGET_CUSTOMERS: 'Целевые клиенты',
  TYPE_IS_REQUIRED: 'Тип обязателен',
  TYPE: 'Тип',
  TITLE_IS_REQUIRED: 'Название обязательно',
  DEFAULT_REFERENCE_IS_CREATED_:
    'Ссылка по умолчанию создается в стиле Ванкувер. Если вы хотите изменить это, введите всю ссылку здесь',
  REFERENCE: 'Ссылка',
  DOI: 'DOI',
  PUBMED_ID: 'Опубликованный идентификатор',
  PAGES: 'страницы',
  ISSUE: 'вопрос',
  VOLUME: 'объем',
  JOURNAL: 'журнал',
  AUTHORS: 'Авторы',
  ABSTRACT: 'Аннотация',
  REFERENCE_IS_REQUIRED: 'Ссылка обязательна',
  THIS_DOC_IS_APPROVED: 'Этот документ утвержден. Нажмите здесь, чтобы открыть',
  ORIGINAL_DOCUMENT_AND_: 'оригинал документа и вставьте свои комментарии',
  EDIT_FILE: 'Редактировать файл',
  PROPERTIES: 'свойства',
  OPEN: 'открыто',
  LOCALIZED_DOCUMENTS: 'Локализованные документы',
  ORIGINAL_DOCUMENTS: 'Оригинальный документ',
  INTERNAL_APPROVAL_NUMBER: 'Внутренний номер утверждения',
  CREATED_BY: 'Создан',
  EXPIRATION_DATE: 'Срок годности',
  VERSION_NUMBER: 'Номер версии',
  NO_VERSION_AVAILABLE: 'Нет версии',
  ARCHIVED_IF_NO_DRAFT: 'В архиве: если нет черновиков / утвержденных версий.',
  APPROVED_FOR_USERS: 'Одобрено: для пользователей.',
  DRAFT_FOR_AUTHORS_: 'Черновик: для авторов и редакторов.',
  VERSION_LEGEND: 'Версия легенда',
  VERSION: 'версия',
  SELECT_ONE_LANG: 'Выберите один язык.',
  SELECT_ONE_TYPE: 'Выберите один тип.',
  INTERNAL_NUMBER_REQUIRED: 'Требуется внутренний номер одобрения.',
  FILE: 'файл',
  ATTACH_FILE: 'Прикрепить файл',
  LITERATURE: 'Литература',
  SLIDE_DECK: 'Слайд-палуба',
  PIL: 'PIL',
  FAQ: 'часто задаваемые вопросы',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Добавить шаг утверждения',
  ADD_PARALLEL_STEP: 'Добавить параллельный шаг',
  DELETE_STEP: 'Удалить шаг',
  DELETE_ALL_PARALLEL_STEPS: 'Удалить все параллельные шаги',
  CHANGE_STATUS_NAME: 'Изменить имя статуса',
  SET_REQUIRED_ROLE: 'Установите необходимую роль',
  SET_TEAM_MEMBER: 'Установить члена команды',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'У вас нет прав для изменения этого процесса.',
  SAVE_ROLE: 'СОХРАНИТЬ РОЛЬ',
  CREATE_NEW_WORKFLOW: 'СОЗДАТЬ НОВЫЙ РАБОЧИЙ ПОТОК',
  CREATE_NEW_STEP: 'СОЗДАТЬ НОВЫЙ ШАГ',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Пожалуйста, введите количество параллельных шагов утверждения, то есть шагов, которые утверждены одновременно (не последовательно). Если у вас нет параллельных шагов утверждения, введите 1. Вы всегда можете изменить количество шагов утверждения позже.',
  APPROVAL_MUST_BE_AT_LEAST: 'Утверждение должно быть не менее',
  INSERT_THE_NEW_STATUS_NAME: 'Вставьте новое имя статуса',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Вставьте новое имя рабочего процесса.',
  INSERT_THE_NEW_STEP_NAME: 'Вставьте новое имя шага.',
  NEW_DOCUMENT: 'Новый документ',
  CREATE: 'Создайте',
  DOCUMENT_MANAGEMENT: 'Управление документами',
  CONFIRM: 'Подтверждение',
  CONFIRM_PROCESS: 'ПОДТВЕРДИТЕ ПРОЦЕСС',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'ОПРЕДЕЛИТЬ ШАГИ ОФИЦИАЛЬНОГО УТВЕРЖДЕНИЯ И КОМАНДУ',
  SET_DETAILS: 'УСТАНОВИТЬ ДЕТАЛИ',
  SELECT_TYPE: 'ВЫБЕРИТЕ ТИП',
  COMPLETE_LOCALIZATION_PROCESS: 'Полный процесс локализации',
  CREATE_NEW_VERSION: 'Создать новую версию',
  CREATE_NEW_DOCUMENT: 'Создать новый документ',
  IS_PRIVATE: 'Частный',
  SHARED_WITH_OTHER_TEAMS: 'Поделиться с другими командами',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Пожалуйста, введите количество последовательных необходимых шагов утверждения. Если два утверждения параллельны, это считается одним шагом. Вы всегда можете изменить количество шагов утверждения позже.',
  DONE: 'Готово',
  SUBMIT_FOR_APPROVAL: 'Отправить на утверждение',
  YOU_HAVE_TO_CONFIRM_THE_: 'Вы должны подтвердить процесс перед загрузкой.',
  SELECT_YOUR_DEC_TYPE: 'Выберите тип вашего документа',
  TARGET_AUDIENCE: 'Целевая аудитория',
  PROCESS_DETAILS: 'Детали процесса',
  START_DATE: 'Дата начала',
  DOC_DETAILS: 'Детали документа',
  CONFIRMATION: 'подтверждение',
  APPROVAL_PROCESS: 'Процесс утверждения',
  SCHEDULED_EXP_DATE: 'Запланированная дата окончания срока действия',
  SCHEDULED_PUBLISHING_DATE: 'Запланированная дата публикации',
  NO_PROCESS_SELECTED: 'Процесс не выбран',
  COUNTRIES: 'страны',
  DOC_ID: 'Идентификатор документа',
  THERAPEUTIC_AREA: 'Терапевтическая зона',
  FILTER: 'Фильтр',
  BACK: 'назад',
  ATTACH: 'Прикреплять',
  COPY_ANSWER: 'Скопировать ответ',
  SIMILAR_INQUIRIES: 'Подобные Запросы',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'Слайды',
  MEDICAL_DOC: 'Медицинский документ',
  SHOW: 'Показать',
  LOAD_MORE: 'Загрузи больше',
  NO_RESULT_FOUND: 'Результаты не найдены.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'может использоваться для поиска всего предложения, - может использоваться для исключения из результатов поиска в пределах указанного слова',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Поиск медицинских документов или аналогичных запросов',
  SEARCH: 'ПОИСК',
  DRAFT_VERSION: 'Черновая версия',
  AVAILABLE_ACTIONS: 'Доступные действия',
  OPEN_DOC: 'Открытый документ',
  UPLOAD_FILE: 'Загрузить файл',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    'Документ все еще находится на утверждении, если вы хотите изменить содержимое документа, вы можете открыть документ в Sharepoint или загрузить обновленную версию.',
  FINALIZE_LOCALIZATION: 'Завершить локализацию',
  THIS_DOC_HAS_BEEN_LOCALIZED:
    'Этот документ локализован, процесс должен быть завершен до утверждения.',
  Preview: 'предварительный просмотр',
  PREVIEW_AND_SEND: 'Предварительный просмотр и отправка',
  PREVIEW_AS_PDF: 'Предварительный просмотр в формате PDF',
  PUBLISHED_VERSION: 'Опубликованная версия',
  NEW_VERSION: 'Новая версия',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'Документ все еще находится в утверждении, если вы хотите изменить содержимое документа, загрузите другой файл.',
  OUT_TO: 'из',
  NEXT: 'следующий',
  SELECTED_SLIDES: 'Избранные слайды',
  GO_TO_SLIDE: 'Перейти к слайду',
  PREV: 'предыдущая',
  SELECT_SLIDE: 'Выберите слайд',
  ANSWER: 'Ответ',
  BACK_TO_STANDARD_VIEW: 'Вернуться к стандартному виду.',
  UPLOAD_NEW_FILE: 'Загрузить новый файл',
  CREATE_FILE: 'СОЗДАТЬ ФАЙЛ',
  EDITING_WORD_DOC: 'Редактирование документа Word',
  INSERT_FILE_NAME: 'Вставьте имя файла',
  PROCEED_WITH_THE_LINK_: 'Приступить к ссылке на файл',
  A_NEW_WINDOW_WILL_BE_:
    'Откроется новое окно с пустым файлом, запишите содержимое и закройте вкладку',
  INSERT_THE_NAME_FOR_:
    'Введите имя для вложения, которое вы хотите создать, и продолжите создание файла.',
  INSTRUCTIONS: 'инструкции',
  CREATE_NEW_WORD_FILE_FOR: 'Создать новый файл слова для',
  FROM: 'от',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Здесь вы можете найти последние запросы, которые вы вставили в систему, как отправленные, так и еще не отправленные. Вы можете повторно использовать запросы, которые вы вставили, чтобы создать больше похожих запросов или вернуться к запросам, которые вы не закончили вставлять.',
  MY_DRAFT_INQ: 'Мои проекты запросов',
  WARNING_CHANGES_NOT_SAVED: 'Предупреждение: изменения не сохранены',
  PAGE_THAT_WILL_BE_LOST: 'страница, которая будет потеряна, если вы решите продолжить.',
  YOU_HAVE_UNUSED: 'У вас есть несохраненные изменения в',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Вы уверены, что хотите покинуть эту страницу без сохранения?',
  LEAVE_THIS_PAGE: 'Покиньте эту страницу',
  STAY_ON: 'Оставаться',
  ASSIGNED_TO: 'Назначено',
  THIS_INQ_HAS_BEEND_INSERTED_: 'Этот запрос был вставлен во время мероприятия.',
  STATUS_IN_APPROVAL: 'Статус: в утверждении',
  THE_RESPONSIBLE_FOR_THE_ANSWER_: 'Ответственный за утверждение ответа должен принять ответ.',
  STATUS_DRAFT: 'Статус: Проект',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Если вы хотите создать актуальный запрос, перейдите на вкладку отправки и нажмите кнопку «Создать».',
  INQ_IMPORTED_FROM: 'Запрос импортирован из',
  SEND_NEW_ANSWER: 'ОТПРАВИТЬ НОВЫЙ ОТВЕТ',
  RETURN_TO_INBOX: 'ВЕРНУТЬСЯ В ВХОД',
  SUBMITTED_FOR_APPROVAL: 'Передано на утверждение',
  ANSWER_SUBMITTED_ON: 'Ответ отправлен',
  BY: 'по',
  INQ_HAS_BEEN_CLOSED: 'Запрос был закрыт без ответа.',
  BCC: 'Bcc',
  ANSWER_GIVEN_ON: 'Ответ дан на',
  ANSWER_SENT_ON: 'Ответ отправлен',
  INBOX: 'входящие',
  OPEN_ORIGINAL_INQ: 'Открыть оригинальный запрос',
  CLOSE_ANSWER_NOT_NEEDED: 'Закрыть: Anser не нужен',
  HISTORY: 'история',
  ADD_A_NOTE: 'Добавить примечание',
  ADD_A_NOTE_INTO_: 'Добавьте заметку в историю запросов.',
  SHARE_OR_START_A_FOLLOW_UP: 'Поделиться или начать продолжение',
  SEE_ALL_INQ_RELATED_TO: 'Посмотреть все запросы, связанные с',
  SRD_NEEDED: 'Требуется SRD',
  ANSWER_UNAVAILABLE: 'Ответ недоступен',
  QUESTION: 'Вопрос',
  SUBMIT: 'ПРЕДСТАВИТЬ',
  ANSWER_UPPERCASE: 'ОТВЕТ',
  DETAILS: 'ПОДРОБНОСТИ',
  SPLIT_INQ: 'Сплит Запрос',
  ADD_QUESTION: 'Добавить вопрос',
  ENTER_QUESTION: 'Введите вопрос',
  MARK_AS_ANSWERED: 'Пометить как ответ',
  TO_BE_ANSWERED: 'Быть отвеченным',
  FOLLOW_UP_RECEIVED: 'Продолжение получено',
  ERROR: 'ошибка',
  SHOW_ORIGINAL_MESSAGE: 'Показать оригинальное сообщение',
  SEND: 'послать',
  DO_NO_SEND: 'Не отправлять',
  OK: 'Хорошо',
  MAX_LENGTH_FOR_ZIP_CODE: 'Максимальная длина почтового индекса',
  CHARACTERS: 'персонажи',
  ADDRESS_1_IS_: 'Адрес 1',
  LABEL_IS: 'Ярлык',
  NO_QUESTION_INSERTED: 'Вопрос не вставлен',
  WARNING: 'ПРЕДУПРЕЖДЕНИЕ',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'Продолжение не доступно для этого взаимодействия.',
  INSERT_INQ_TEXT: 'Вставить текст запроса',
  SHOW_MORE: 'Показать больше',
  OUT_OF: 'снаружи',
  INQ_TO_BE_SENT: 'Запросы для отправки',
  HIDE_INQ: 'Скрыть запросы',
  SHARE: 'Поделиться',
  HCP_PROFILE_OF: 'HCP ПРОФИЛЬ',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Заполните все обязательные поля ниже и хотя бы один адрес электронной почты, а затем сохраните для предварительного просмотра уведомления о получении GDPR.',
  CONFIRMATION_SENT_BY: 'Уведомление отправлено',
  COUNTRY_IS: 'Страна',
  COUNTRY: 'Страна',
  AT_LEAST_ONE_CONTACT_IS_: 'По крайней мере, один контакт',
  EMAIL_IS: 'Электронная почта',
  EMAIL_LABEL_IS: 'Метка электронной почты',
  PHONE_FAX_IS: 'Телефон / факс',
  PHONE_FAX_NUMBER_IS: 'Номер телефона / факса',
  ADDRESS: 'Адрес',
  DELETE_PERSONAL_DATA: 'Удалить личные данные',
  HCP_VERIFIED: 'HCP проверено',
  KEY_OPINION_LEADER: 'Ключевой лидер мнений',
  HOSPITAL_BASED: 'На базе больницы',
  HCP_RELATED_INFORMATION: 'HCP СВЯЗАННАЯ ИНФОРМАЦИЯ',
  ACCEPT: 'принимать',
  REJECT: 'отклонять',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Хотите принять эти предложения?',
  SALES_REP: 'ТОРГОВЫЙ ПРЕДСТАВИТЕЛЬ',
  SUGGEST_A_TOPIC: 'Предложить тему',
  TEAM_SPECIFIC_FIELDS: 'КОМАНДНЫЕ СПЕЦИАЛЬНЫЕ ПОЛЯ',
  ADR_RELATED: 'ADR Связанные',
  NOT_ADR_RELATED: 'Не связано с ДОПОГ',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Пожалуйста, просмотрите сообщение и классифицируйте его как нежелательную лекарственную реакцию или нет.',
  ADVERSE_DRUG_REACTION: 'Неблагоприятная лекарственная реакция',
  CHANGE_DETAILS: 'ИЗМЕНИТЬ ДЕТАЛИ',
  YES: 'да',
  NO: 'нет',
  DUE_DATE: 'Срок оплаты',
  CRITICAL: 'критический',
  NORMAL: 'Обычный',
  PRIORITY: 'приоритет',
  SELECT_DETAILS: 'ВЫБРАТЬ ДЕТАЛИ',
  SAVE_REVIEW: 'Сохранить обзор',
  SET_TO_ALL_INQ: 'УСТАНОВИТЬ НА ВСЕ ЗАПРОСЫ',
  PRODUCT_QUALITY_COMPLAINT: 'Жалоба на качество продукции',
  PQC_PC_A_PRODUCT_:
    'PQC / PC Жалоба или проблема качества продукта определяется как любая проблема, связанная с дефектом или неисправностью какого-либо лекарственного препарата или с качеством, характеристиками, безопасностью любого лекарственного препарата, например, измененный запах и / или вкус, плохая или дефектная упаковка, подозрение на загрязнение и т. д.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Использование не по назначению фармацевтических препаратов для неутвержденных показаний или для неутвержденной возрастной группы, дозировки или пути введения.',
  A_RESPONSE_TO_A_MEDICINAL_:
    'Реакция на лекарственное средство, которое является вредным и непреднамеренным, и причинно-следственная связь между лекарственным средством и неблагоприятным явлением является по меньшей мере разумной возможностью. Кроме того, особые ситуации (например, беременность, использование не по назначению, передозировка) должны быть отмечены как ADR.',
  NORMAL_IS_7_WORKING_DAYS:
    'Нормальный - 7 рабочих дней, Высокий - 48 часов, Критический (связанный с пациентом) - 24 часа.',
  CATEGORY_AND_TOPIC_ARE: 'Категория и тема',
  PRODUCT_IS: 'Продукт',
  DO_YOU_WANT_TO_ACCEPT_: 'Хотите принять эти предложения?',
  MERGE: 'Объединить',
  Duplicate: 'Дублированный',
  Other: 'разное',
  Spam: 'Спам',
  CLOSE_INTERACTION: 'Тесное взаимодействие',
  RE_OPEN_THE_TARGET_: 'Повторно открыть целевой запрос после слияния',
  MARK_AS_INTERNAL_: 'Отметить как внутреннее сообщение для существующего запроса.',
  MARK_AS_AN_ERROR_: 'Отметить как сообщение об ошибке для существующего запроса.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Это сообщение будет помечено как последующий ответ на существующий запрос.',
  THANK_YOU: 'Спасибо',
  AN_INTERNAL_MESSAGE: 'Внутреннее сообщение',
  AN_ERROR_MESSAGE: 'Сообщение об ошибке',
  NEW_INQ: 'Новый запрос',
  A_FOLLOW_UP_RESPONSE: 'Последующий ответ',
  THIS_INQ_IS: 'Этот запрос',
  SPLIT_INTERACTION: 'Сплит Взаимодействие',
  SAVE_AND_CLOSE: 'Сохрани и закрой',
  SAVE_INQ: 'Сохранить запрос',
  REVIEW_THE_TEXT_AND: 'Просмотрите текст и классификацию и подтвердите',
  CHOOSE_THE_RELEVANT_PRODUCT:
    'Выберите соответствующий продукт, категорию и тему для выбранного текста',
  SELECT_THE_TEXT_THAT_:
    'Выберите текст, который идентифицирует вопрос об одном продукте / теме (не включайте приветствия, подписи, отказ от ответственности или другую не относящуюся к делу информацию)',
  REPEAT_THE_PREVIOUS_: 'Повторите предыдущие шаги, чтобы добавить больше запросов',
  READ_MORE: 'Прочитайте больше',
  READ_LESS: 'Читать меньше',
  DO_NOT_INCLUDE_:
    'не включают приветствия, подписи, отказ от ответственности или другую не относящуюся к делу информацию',
  SELECT_THE_TEXT_THAT_IDENT:
    'Выберите текст, который идентифицирует вопрос об одном продукте / теме',
  IF_YOU_HAVE_MULTIPLE_:
    'Если у вас есть несколько продуктов или тем, запрос должен быть разделен.',
  THE_FOLLOWING_PROCESS_:
    'Следующий процесс поможет вам определить соответствующий текст для каждого продукта / темы: ',
  EXPORT_TO_PDF: 'ЭКСПОРТ В PDF',
  Added: 'добавленной',
  Deleted: 'Исключен',
  From: 'От',
  To: 'к',
  BACK_TO_INBOX: 'Вернуться в папку «Входящие»',
  PERMISSION_DENIED: 'Доступ запрещен',
  THIS_INQ_IS_LOCKED_BY: 'Этот запрос заблокирован',
  FORCE_UNLOCK: 'ФОРМА РАЗБЛОКИРОВАТЬ',
  INQUIRIES: 'расспросы',
  Download: 'Скачать',
  Event: 'Мероприятие',
  INSERTED_BY: 'Вставлено',
  LAST_EDITED_ON: 'Последнее редактирование включено',
  LAST_EDITED_BY: 'Последний отредактированный',
  CREATED_ON: 'Создано на',
  TOPIC: 'Тема',
  TABLE_OPTIONS: 'Параметры таблицы',
  DEPARTMENT: 'отдел',
  User: 'пользователь',
  ADD_THERAPEUTIC_AREA: 'Добавить терапевтическую зону',
  CREATE_THERAPEUTIC_AREA: 'Создать новую терапевтическую зону',
  ADD_NEW: 'Добавить новое',
  SELECT_TEAM_TO_PROCEED: 'Выберите команду, чтобы продолжить',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Продажи',
  CALL_CENTER: 'Колл-центр',
  ALL: 'Все',
  Warning: 'Предупреждение',
  clear: 'Чисто',
  THESE_CHANGES_WILL_BE_: 'Эти изменения будут применены к выбранным пользователям',
  INSERT_A_FOLLOW_UP_RESP: 'Вставить ответ',
  INSERT_A_NOTE: 'Вставить заметку',
  MERGED_FROM: 'Объединено с',
  CHANGES: 'изменения',
  SHOW_LESS: 'показывай меньше',
  COMPLETE: 'полный',
  MISSING: 'Отсутствует',
  CLOSE_AS_FORWARDED: 'Закрыть как отправлено',
  CLOSE_EXT_TEAM: 'Закройте запрос, как отправлено внешней команде',
  THE_INQ_BELONG_TO_A_:
    'Этот запрос относится к стране, за которую отвечает внешняя команда. Запрос будет передан этой команде автоматически.',
  RESOLVE: 'Разрешить',
  TERRITORIES: 'территории',
  EXTERNAL_COMMUNICATION: 'ВНЕШНЯЯ СВЯЗЬ',
  FOR_INQ_COMING_DIRECTLY_:
    'Для запросов, поступающих непосредственно от HCP по электронной почте, автоматически отправьте уведомление, чтобы сообщить ему, что запрос был получен.',
  INTERNAL_COMMUNICATION: 'ВНУТРЕННЕЕ ОБЩЕНИЕ',
  SALESREP_RECEIVES_: 'Salesrep получает только уведомления об ответе на запрос',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep получает полный ответ на запросы по этикетке',
  SALESREP_RECEIVES_ONLY_NOTIF_: 'Salesrep получает только уведомления об ответе на запрос',
  Name: 'название',
  Module: 'модуль',
  Function: 'функция',
  LAST_RUN: 'Последний запуск',
  End: 'Конец',
  Every: 'каждый',
  Period: 'период',
  Active: 'активный',
  Resolved: 'Постановили',
  ERROR_PREVIEW: 'Предварительный просмотр ошибки',
  FAILURE_COUNT: 'Счетчик ошибок',
  COMPLETED_ON: 'Завершено на',
  STARTED_ON: 'Начался',
  TASK_NAME: 'Название задачи',
  PRODUCT_SPECIFIC: 'СПЕЦИФИЧЕСКИЙ ПРОДУКТ',
  THERAPEUTIC_AREAS: 'ТЕРАПЕВТИЧЕСКИЕ ОБЛАСТИ',
  PRODUCT_GROUPS: 'ГРУППЫ ПРОДУКТОВ',
  LICENSING_PARTNER: 'ЛИЦЕНЗИОННЫЙ ПАРТНЕР',
  PRODUCT_CODE: 'КОД ПРОДУКТА',
  EXTERNAL_ID: 'Внешний идентификатор',
  OBJECT_TYPE: 'Тип объекта',
  MIGRATION_NAME: 'Название миграции',
  MIGRATION_ERRORS: 'Ошибки миграции',
  ADD_SALES_REP: 'Добавить торгового представителя',
  ASSIGNED_TO_MSL: 'НАЗНАЧЕН MSL',
  NOT_ASSIGNED: 'Не назначен',
  ASSIGNED_TO_OTHER_MSL: 'Назначено на другой MSL',
  ASSIGNED_TO_THIS_MSL: 'Назначен на этот MSL',
  SHOW_ONLY: 'Покажи только',
  SALES_REP_LIST: 'Список торговых представителей',
  ACTIVE_TASK: 'Активировать задачу',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Выберите пользователя и установите смещение для события',
  MANAGE_SHIFTS: 'УПРАВЛЯТЬ СДВИГАМИ',
  CREATE_NEW_USER: 'Создать нового пользователя',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'Этот шаблон будет автоматически добавлен в вашу команду.',
  TEMPLATE_FOR: 'Шаблон для',
  Day: 'День',
  Week: 'Неделю',
  Month: 'Месяц',
  Today: 'Cегодня',
  Archive: 'Архив',
  PARTICIPANTS: 'УЧАСТНИКИ',
  EVENT_MANAGEMENT: 'УПРАВЛЕНИЕ СОБЫТИЯМИ',
  ADD_TASK: 'Добавить задачу',
  EDIT_TASK: 'Изменить задачу',
  IS_ACTIVE: 'Активен',
  Months: 'Месяцы',
  Days: 'дней',
  Hours: 'часов',
  Minutes: 'минут',
  HCP_ADMINISTRATION: 'АДМИНИСТРАЦИЯ ВКП',
  AUTH_REQ_ADMINISTRATION: 'Администрация запросов авторизации',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: 'Продукт будет выделен в выбранных терапевтических зонах.',
  PRODUCT_GROUPS_DEFINE_:
    'Группы продуктов определяют группу, отвечающую за ответы на запросы по данному продукту.',
  USER_ADMINISTRATION: 'Администрирование пользователей',
  IS_PRODUCT_SPECIFIC: 'Является ли продукт конкретным',
  USER_TEMPLATES_ADMIN: 'Администрирование пользовательских шаблонов',
  SET_DEFAULT_VALUES: 'УСТАНОВИТЬ ЗНАЧЕНИЯ ПО УМОЛЧАНИЮ',
  SET_DEFAULT_VALUES_FOR_: 'Установите значения по умолчанию для события',
  INQUIRY_DEFAULT_VAL: 'Значения запроса по умолчанию',
  CREATE_NEW_EVENT: 'СОЗДАТЬ НОВОЕ СОБЫТИЕ',
  EDIT_EVENT: 'РЕДАКТИРОВАТЬ СОБЫТИЕ',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Установить детали для создания нового события',
  ADD_DOC_LINKED_TO_: 'Добавить документы, связанные с событием',
  INC_CHANNEL: 'Inc. Канал',
  CHANGE_DETAILS_OF_THE_EVENT: 'Изменить детали события',
  DESCRIPTION: 'Описание',
  CREATE_APPROVAL_PROCESS: 'Выберите процесс утверждения',
  CREATE_NEW: 'Создавать новое',
  CUSTOMIZE_PROCESS: 'Настроить процесс',
  SELECTED_PROCESS: 'Выбранный процесс',
  CLONE: 'клон',
  PREFERENCES_TITLE: 'предпочтения',
  NOTIFICATIONS_TITLE: 'Уведомления',
  TEMPLATES: 'Шаблоны',
  LETTER_TEMPLATES: 'Шаблон письма',
  USER_TEMPLATES: 'Пользовательские шаблоны',
  TEAM_MEMBERS: 'Члены команды',
  NEW_PASSWORD: 'Новый пароль',
  REPEAT_NEW_PASSWORD: 'повторите новый пароль',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Как минимум 1 строчная буква',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Как минимум 1 заглавная буква',
  AT_LEAST_1_DIGIT: 'Хотя бы 1 цифра',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Как минимум 1 специальный символ',
  MINIMUM_8_CHARACTERS: 'Минимум 8 символов',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Не должен содержать адрес электронной почты или имена',
  SHOULD_MATCH_PASSWORD: 'Должен совпадать с паролем',
  USER_MANUAL: 'Руководство пользователя',
  SUPPORT: 'Служба поддержки',
  BACK_TO_LOGIN: 'Вернуться на страницу авторизации',
  PASSWORD_CONFIRMATION: 'Подтверждение пароля',
  HAVE_AN_ACCOUNT: 'Уже есть аккаунт?',
  Submit: 'представить',
  Gender: 'Пол',
  PROFESSION: 'профессия',
  MEDICAL_INQ_ROLES: 'Роль обработки медицинских запросов',
  MEDICAL_INFO_MANAGER: 'Менеджер медицинской информации',
  MEDICAL_SCIENCE_LIAISON: 'Медицинская связь',
  NOT_PROCESSING_INQUIRIES: 'Не обрабатывает запросы',
  ACTIVE_HEALTH_CARE_SPEC: 'Активный специалист по здравоохранению',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Не активный специалист по здравоохранению',
  PASS_IS_REQUIRED: 'Необходим пароль!',
  EMAIL_IS_REQUIRED: 'Требуется адрес электронной почты!',
  VALID_EMAIL: 'Пожалуйста, введите действительный адрес электронной почты!',
  FIELD_IS_REQUIRED: 'Это поле обязательно к заполнению!',
  ONLY_LETTERS: 'Пожалуйста, введите только буквы!',
  ARE_YOU_daiichi_EMPLOYEE: 'Вы Daiichi Sankyo Сотрудник?',
  here: 'Вот',
  CREATE_AN_ACCOUNT: 'Завести аккаунт',
  FORGOT: 'Забыл?',
  Medical: 'медицинская',
  Medical_not_resp: 'Медицинский - не несет ответственности за реагирование на MIR',
  Commercial: 'коммерческий',
  Market_access: 'Доступ к рынку',
  Pharmacovigilance: 'фармаконадзор',
  PR: 'PR',
  Please_specify: 'Пожалуйста уточни',
  Medical_information_manager: 'Менеджер медицинской информации',
  Medical_advisor: 'Медицинский консультант',
  Are_Medical_inquiries: 'Вы отвечаете на медицинские вопросы?',
  Are_Health_care_professional: 'Вы практикующий врач?',
  Health_care_Industry: 'Индустрия здравоохранения',
  Health_Authority: 'Управление здравоохранения',
  Journalist: 'Журналист',
  Lawyer: 'Юрист',
  Patient: 'Терпеливый',
  Payor: 'плательщик',
  Scientist: 'Ученый',
  Angiologist: 'Angiologist',
  Cardiologist: 'Кардиолог',
  Cardiovascular_Surgeon: 'Сердечно-сосудистый хирург',
  Dentist: 'Дантист',
  Gastroenterologist: 'гастроэнтеролог',
  General_Practitioner: 'Врач общей практики',
  Geriatrician: 'Гериатр',
  Gynecologist: 'Гинеколог',
  Haematologist: 'гематолог',
  Internist: 'терапевт',
  Medical_Student: 'Студент-медик',
  Nephrologist: 'Нефролог',
  Neurologist: 'невролог',
  Neurosurgeon: 'Нейрохирург',
  Nurse: 'Медсестра',
  Oncologist: 'Онколог',
  Oncology_Nurse: 'Онкологическая медсестра',
  Other_HCP: 'Другое HCP',
  Other_Surgeon: 'Другой хирург',
  Pediatrician: 'Педиатр',
  Pharmacist: 'фармацевт',
  Pharmacologist: 'Фармаколог',
  Pharmacy_Technician: 'Аптечный служащий',
  Pneumologist: 'Pneumologist',
  Radiologist: 'радиолог',
  Rheumatologist: 'Ревматолог',
  USER_PASS_INCORRECT: 'Логин или пароль неверны',
  SUBMITTED_REQUEST: 'Ваш запрос успешно отправлен.',
  Reset: 'Сброс настроек',
  Reset_pass: 'Сброс пароля',
  New_pass: 'Новый пароль',
  RESET_PASS_EMAIL: 'Ссылка для сброса пароля была отправлена на вашу электронную почту',
  NEW_PASS_SUCCESS: 'Ваш пароль был успешно сброшен',
  EVENTS: 'События',
  Login_here: 'Войти здесь',
  REGISTRATION: 'Постановка на учет',
  Create_an_account: 'Завести аккаунт',
  NO_RECORDS: 'Нет записей',
  INTERACTION: 'взаимодействие',
  SPECIALIZATION: 'специализация',
  SPECIALIZATION_TYPE: 'Тип специализации',
  SAVED: 'Сохраненный',
  SAVING: 'Сохранение',
  TYPING: 'Typing',
  CREATE_NEW_INTERACTION: 'Создать новое взаимодействие',
  FILTER_BY: 'Фильтровать по',
  SEND_EMAIL: 'Отправить письмо',
  SAVE_WITHOUT_EMAIL: 'Сохранить без электронной почты',
  SUBMIT_TO_INBOX: 'Отправить на Входящие',
  Create_my_acc: 'Создать мой аккаунт',
  NEW_QUESTION: 'Новый вопрос',
  LABEL: 'метка',
  SELECT_A_LABEL: 'Выберите ярлык',
  CITY: 'город',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Предложить новую категорию или выбрать существующую',
  SUGGEST_CATEGORY_OR_TOPIC: 'Предложить новую категорию или тему',
  URGENT: 'неотложный',
  EXISTING_CATEGORY: 'Существующая категория',
  NEW_CATEGORY_NAME: 'Название новой категории',
  NEW_TOPIC_NAME: 'Название новой темы',
  CATEGORY: 'категория',
  MSLA: 'МГЮА',
  OUT_CHANNEL: 'Исходящий канал',
  LOGIN_TO_CONTINUE: 'Войдите, чтобы продолжить Madjenta',
  Email_address: 'Адрес электронной почты',
  FORGOT_PASS: 'Забыли пароль?',
  Remember_me: 'Запомни меня',
  YOU_WILL_BE_NOTIFIED:
    'Письмо с подтверждением было отправлено с дальнейшими инструкциями для вашей регистрации',
  SUCCESS_EMAIL_CONFIRMATION: 'Ваш адрес электронной почты был успешно подтвержден.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Вы будете уведомлены, когда ваш запрос будет рассмотрен',
  FAILED_EMAIL_CONFIRMATION: 'Мы не можем подтвердить вашу электронную почту.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Пожалуйста, повторите попытку, и если проблема не устранена, напишите в поддержку с описанием проблемы.',
  RESET_FILTERS: 'Сбросить фильтры',
  NEW_PASS_RESET_SUCCESS: 'Пароль успешно сброшен!',
  SOMETHING_WENT_WRONG: 'Что-то пошло не так, обратитесь в службу поддержки.',
  Cardiovascular: 'сердечно-сосудистый',
  Oncology: 'онкология',
  Due_On: 'Выполнить к',
  Follow_Up_Sent_On: 'Отслеживание отправлено',
  Follow_Up_Received_On: 'Получено',
  Closed_On: 'Закрыто',
  Reopened_On: 'Вновь открыт на',
  Inquiry_ID: 'Идентификатор запроса',
  Search: 'Поиск',
  Assigned_to_Now: 'Назначено (сейчас)',
  Edited_By: 'Отредактировано',
  Assigned_to_Any_Time: 'Назначено (в любое время)',
  Closed: 'Закрыто',
  Foreign: 'иностранные',
  Complex_search_Sentence_: 'Комплексный поиск: Приговор: «предложение к поиску», а не: -слово',
  Search_inquiries: 'Поисковые запросы',
  SEARCH_REPOSITORY: 'Поиск репозитория',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'фильтры',
  Active_Filters: 'Активные фильтры',
  CONTACT_SUPPORT: 'Контактная поддержка',
  MedicalInformationRequests: 'Запросы (pdf)',
  UserProfileNavigation: 'Профиль пользователя (pdf)',
  UserManualChanges: 'Изменения в руководстве пользователя (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Назначить запрос',
  MedIS_Documentation_Approval_Process: 'Процесс утверждения документации',
  MedIS_Documentation_Overview: 'Обзор документации',
  MedIS_Inquiry_Documentation: 'Справочная документация',
  MedIS_MIR_Inbox_Filters: 'MIR Входящие Фильтры',
  MedIS_Merge_MIR: 'Слияние МИР',
  MedIS_New_Documentation: 'Новая документация',
  MedIS_New_Inquiry: 'Новый запрос',
  MedIS_Out_Of_Office: 'Вне офиса',
  MedIS_Prepare_Custom_Answer: 'Подготовить пользовательский ответ',
  MedIS_Split_MIR_Part_I: 'Сплит МИР Часть I',
  MedIS_Split_MIR_Part_II: 'Сплит МИР Часть II',
  I_AGREE_TO: 'Я согласен',
  TermsAndConditionsLink: 'Условия и положения',
  Title: 'заглавие',
  INTERACTION_TITLE: 'Название взаимодействия',
  Move: 'Шаг',
  Left: 'Осталось',
  Right: 'Правильно',
  Requestor: 'Requestor',
  Assigned_To: 'Назначено',
  Status: 'Положение дел',
  Note: 'Заметка',
  Confirm: 'Подтверждение',
  rejectConfirm: 'Вы уверены, что хотите отклонить запрос?',
  approveConfirm: 'Вы уверены, что хотите одобрить запрос?',
  newUser: 'Новый пользователь',
  editUser: 'Изменить пользователя',
  deleteUser: 'Удалить пользователя',
  newProduct: 'Новый продукт',
  editProduct: 'Редактировать продукт',
  deleteProduct: 'Удалить продукт',
  newCategory: 'Новая категория',
  editCategory: 'Изменить категорию',
  deleteCategory: 'Удалить категорию',
  newTopic: 'Новая тема',
  editTopic: 'Редактировать тему',
  deleteTopic: 'Удалить тему',
  userRequest: 'Запрос пользователя',
  productRequest: 'Запрос продукта',
  categoryRequest: 'Запрос категории',
  categoryTopicRequest: 'Категория / Тема Запрос',
  topicRequest: 'Тема запроса',
  Suggestion: 'Предложение',
  tradeName: 'Торговое наименование',
  activeSubstance: 'Действующее вещество',
  productName: 'наименование товара',
  productSpecific: 'Это конкретный продукт?',
  EMAIL_IN_USE: 'Существует существующая учетная запись с этим адресом электронной почты',
  fromDate: 'С даты',
  toDate: 'Назначить свидание',
  applyFilter: 'Применять фильтр',
  requestReceivedFrom: 'Запрос получен от',
  on: 'на',
  at: 'в',
  to: 'в',
  add: 'Добавить',
  from: 'из',
  approve: 'одобрить',
  reject: 'отклонять',
  withTheFollowingRole: 'со следующей ролью',
  forTheFollowingTeam: 'для следующей команды',
  request: 'запрос',
  submittedBy: 'представленный',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'Запрос на сброс пароля недействителен или истек',
  PASS_IS_RECENT: 'Пароль недавно использовался',
  Assigned_to_me: 'Назначено мне',
  My_drafts: 'Мои шашки',
  My_open_inquiries: 'Мои открытые запросы',
  My_closed_inquiries: 'Мои закрытые запросы',
  Filter_inquiries_by: 'Фильтр запросов по',
  New_inq: 'Новый запрос',
  Inq_inbox: 'Запросы Inbox',
  REPORTS: 'Отчеты',
  My_teams_open_inq: 'Открытые запросы моей команды',
  My_teams_closed_inq: 'Закрытые запросы моей команды',
  All_teams_open_inq: 'Все команды открывают запросы',
  All_teams_closed_inq: 'Все команды закрыли запросы',
  Overdue: 'просроченный',
  Due_this_week: 'Из-за этой недели',
  Pending_approval: 'В ожидании утверждения',
  ADMINISTRATION: 'администрация',
  Show_all: 'Показать все',
  In_progress: 'В ходе выполнения',
  Table_Settings_Columns: 'Настройки таблицы - столбцы',
  Change_column_visibility: 'Изменить видимость столбца',
  Reassign_to: 'Переназначить на',
  set_priority: 'Установить приоритет',
  set_status: 'Установить статус',
  Spam_list: 'Список спама',
  Institution: 'учреждение',
  DUE_ON: 'Выполнить к',
  Profile_settings: 'Настройки профиля',
  Preferences: 'предпочтения',
  Role_settings: 'Настройки ролей',
  Notification_settings: 'Настройки уведомлений',
  User_profile: 'Профиль пользователя',
  Select_person: 'Выберите человека',
  FROM_DATE: 'С даты',
  TO_DATE: 'Назначить свидание',
  JOB_DESC: 'Описание работы',
  INQ_Management: 'Управление запросами',
  DOCS_Management: 'Управление документами',
  USER_Management: 'Управление пользователями',
  Define_Frequency: 'Определить частоту',
  All_teams_all_inq: 'Все запросы для всех команд',
  My_teams_all_inq: 'Все запросы для моей команды',
  Search_people_here: 'Поиск людей здесь',
  Search_team_here: 'Поиск команды здесь',
  people: 'ЛЮДИ',
  teams: 'КОМАНДЫ',
  empty: '(Пусто)',
  can_not_be_assigned: 'Этот пользователь не имеет команды и не может быть назначен',
  select_a_team: 'Пожалуйста, выберите команду для',
  confirm_team_change: 'Подтвердите изменение команды',
  change_the_team_: 'Вы уверены, что хотите сменить команду на',
  can_not_see_it_anymore: 'Вы не сможете увидеть это в текущем',
  filtered_view: 'фильтрованное представление.',
  PHONE: 'Телефон',
  FAX: 'факс',
  LETTER: 'Письмо',
  F2F_SITE_VISIT: 'F2F (посещение сайта)',
  F2F_CONGRESS: 'F2F (Конгресс)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (Другое)',
  WEBINAR_SITE_VISIT: 'Виртуальный (посещение сайта)',
  WEBINAR_CONGRESS: 'Виртуальный (Конгресс)',
  WEBINAR_ADBOARD: 'Виртуальный (AdBoard)',
  WEBINAR_OTHER: 'Виртуальный (Другой)',
  CHAT: 'Чат',
  COURIER: 'курьер',
  REP_DELIVERY: 'Реп. Доставка',
  SYSTEM: 'система',
  WEB_FORM: 'Веб-форма',
  Signature: 'Подпись',
  Disclaimer: 'отказ',
  STATE: 'государство',
  ADDRESS_1: 'адрес 1',
  ADDRESS_2: 'Адрес 2',
  ZIP_CODE: 'Почтовый Индекс',
  HIGH: 'Высокая',
  ADVANCED: 'продвинутый',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Нажмите на текст и поля ниже, чтобы заполнить и отредактировать ответ',
  DOCUMENT_VIEW: 'Просмотр документов',
  FORM_VIEW: 'Форма просмотра',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'У вас нет прав на отправку ответа.',
  ANSWER_TEXT: 'Текст ответа',
  CONTACT_INFORMATION: 'Контактная информация',
  COMPANY: 'Компания',
  SRD_NEEDED_TOOLTIP: 'Для ответа на этот запрос требуется новый документ SRD.',
  ENABLE_ALL: 'Включить все',
  DISABLE_ALL: 'Отключить все',
  BEFORE: 'Перед',
  AFTER: 'После',
  EDIT: 'редактировать',
  MY_TEMPLATES: 'Мои шаблоны',
  TEAM_TEMPLATES: 'Шаблоны команд',
  TEAM_TEMPLATES_HINT: 'Шаблон, созданный менеджером по медицинской информации для всей команды',
  SOURCE: 'Источник',
  TEMPLATE_NO_MATCH_INTERACTION:
    'Этот шаблон не соответствует деталям взаимодействия и не применим',
  EMAIL_SUBJECT: 'Тема письма',
  INQUIRY_SUBMIT_HINT: 'Пожалуйста, заполните все обязательные поля, чтобы ответить на запрос.',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Запланированная публикация до истечения срока действия',
  COPY_ATTACHMENT_TO_ANSWER: 'Скопировать вложение к ответу',
  COPY_TEXT_TO_ANSWER: 'Копировать текст в ответ',
  SELECT_INQUIRY: 'Выберите запрос',
  EDIT_INQ: 'Редактировать вопрос',
  SPLIT_ANOTHER: 'Разделить другого',
  DELETE_INQ: 'Удалить запрос',
  SELECT_TEXT_FOR_SPLIT: 'Добавить новый вопрос в том же взаимодействии',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Создать отдельное взаимодействие / дело',
  SPLIT_ORIGINAL_TEXT: 'Исходный текст (выделите текст для разделения)',
  SPLIT_TEXT_TO_REMAIN_: 'Текст, который останется в первоначальном запросе',
  SPLIT_TEXT_TO_SPLIT_: 'Текст для разделения',
  SPLIT_SELECTED_SECTIONS: 'Выбранные разделы для разделения',
  SPLIT_NO_MODIFICATIONS_HINT:
    'Текст будет заполняться автоматически, когда вы выбираете разделы из исходного текста.',
  SPLIT_MODIFICATIONS_HINT:
    'Текст был изменен вручную, и дальнейшее выделение исходного текста не применяется.\n Щелкните здесь, чтобы отменить изменения.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'Текст будет автоматически обновляться с изменениями в деталях запроса.',
  SELECT_DIFFERENT_TEMPLATE: 'Выберите другой шаблон',
  APPROVE: 'Одобрить',
  LAST_EDIT_ON: 'Последнее изменение',
  LAST_EDIT_BY: 'Автор последнего редактирования',
  INTERNAL_COMM: 'Внутреннее общение',
  INACTIVE: 'Неактивный',
  DEACTIVATE: 'Деактивировать',
  ARCHIVED: 'В архиве',
  REPUBLISH: 'Переиздать',
  INBOX_NO_FILTERS_HINT:
    'Фильтры не выбраны.\n Если вы не можете найти интересующий вас запрос, убедитесь, что вы находитесь в правильном разделе почтового ящика.',
  INTERACTION_TEMPLATE_HINT:
    'Подписи, приветствия и отказ от ответственности являются частью письменного общения, например электронной почты, письма и факса.\n Чтобы использовать их, убедитесь, что исходящий канал установлен на один из них.',
  CONFIRM_AUTH_REQUEST: 'Подтвердить запрос аутентификации',
  VIEW_AUTH_REQUEST: 'Посмотреть запрос аутентификации',
  QUESTION_TITLE: 'Название вопроса: ',
  NO_TITLE_INQUIRY: '-нет-заголовок-запрос-',
  EXISTING_CATEGORIES: 'Существующие категории',
  EXISTING_TOPICS: 'Существующие темы',
  DO_NOT_ASSIGN_PRODUCT: 'Не назначать продукт',
  QUESTION_TEXT: 'Текст вопроса',
  DATE: 'Свидание',
  REJECTED: 'Отклонено',
  FILTER_AUTH_REQUESTS_BY: 'Фильтровать запросы аутентификации по',
  ALL_REQUESTS_ALL_TEAMS: 'Все запросы для всех команд',
  ALL_TEAMS: 'Все команды',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'У вас нет прав на редактирование запросов категорий / тем.',
  SEND_FOLLOW_UP: 'Отправить последующие действия',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Уведомление о получении покупателю',
  GDPR_DISCLAIMER: 'Заявление об ограничении ответственности GDPR',
  ACTIONS: 'Действия',
  SUBMISSION_CONDITIONS: 'Условия подачи',
  TYPE_OF_NOTIFICATION: 'Тип уведомления',
  DIRECT_SUBMISSION: 'Прямая подача',
  DIRECT_SUBMISSION_TOOLTIP:
    'Для запросов, отправленных напрямую от клиента по электронной почте, отправьте уведомление, чтобы проинформировать клиента об обработке его личных данных.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Отправлено через сотрудника компании',
  ENABLE_NOTIFICATION: 'Включить уведомления',
  NOTIFICATION_SENDER: 'Уведомление отправителю',
  SALES_REP_DELIVERED_RESPONSE: 'Торговый представитель для доставленного ответа',
  NOTIFICATION_DELIVERED_RESPONSE: 'Уведомление о доставленном ответе',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'Для запросов, отправленных через представителя компании (торгового представителя, MSL или другого), уведомить отправителя, когда клиент получил ответ на свой запрос.',
  FULL_ANSWER: 'Полный ответ',
  NOTIFICATION: 'Уведомление',
  NAME: 'Название',
  COLUMN_INIT_QUESTION_TEXT: 'Оригинальный текст вопроса',
  NOTIFICATION_ENABLED: 'Уведомление включено',
  DEFAULT_TEMPLATE: 'Шаблон по умолчанию',
  SET_AS_DEFAULT: 'Установить по умолчанию',
  DEFAULT_TEMPLATE_TOOLTIP:
    'Переключатель отключен, так как должен быть хотя бы один шаблон по умолчанию!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'Шаблон по умолчанию удалить нельзя!',
  BATCH_UPDATE_ONLY: 'Возможно только пакетное обновление через самый верхний чекбокс.',
  ACCESS_DENIED: 'Доступ запрещен!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: 'У вас нет разрешения на доступ к этой странице.',
  USER_ROLES: 'Роли пользователей',
  MANAGE_USER_ROLES: 'Управление ролями пользователей',
  ARCHIVE_ROLE: 'Архивная роль',
  ROLE_ARCHIVE_CONFIRMATION: 'Вы действительно хотите заархивировать эту роль?',
  ROLES_FOR: 'Роли для',
  AUDIT_TRAIL: 'Журнал аудита',
  ACTION_BY_USER: 'Действие пользователя',
  ACTION: 'Действие',
  ORIGINAL_VALUE: 'Исходное значение',
  CHANGE: 'Изменить',
  CONDITION: 'Условие',
  IP: 'ИП',
  GEOLOCATION: 'Геолокация',
  NEW_VALUE: 'Новое значение',
  FILTER_BY_ACTION: 'Фильтровать по действию',
  SELECT_DATE: 'Выберите дату',
  TEAM_SAVE: 'Сохранять',
  TEAM_DELETE: 'Удалить',
  TEAM_CREATE: 'Создавать',
  TEAM_CLONE: 'Копия',
  TEAM_NAME: 'Имя',
  TEAM_CODE: 'Код',
  TEAM_ACTION_DELETE_CONFIRM: 'Вы действительно хотите удалить',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Будь осторожен!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'После удаления его нельзя восстановить!',
  TEAM_FULL_ANSWER_ON_LABEL: 'Полный ответ на лейбле',
  TEAM_RESTRICTED_TEAM: 'Ограниченный',
  TEAM_SEGREGATED_DATA: 'Отдельные данные',
  TEAM_PRIVATE_TEAM: 'Частная команда',
  TEAM_AUTO_ASSIGN: 'Автоматическое назначение',
  TEAM_MAILBOX_IN: 'Входящий почтовый ящик',
  TEAM_MAILBOX_OUT: 'Выходящий почтовый ящик',
  TEAM_DEFAULT_LANGUAGE: 'Язык по умолчанию',
  TEAM_COUNTRIES: 'Назначенные страны',
  TEAM_PRODUCT_GROUPS: 'Назначить группы продуктов',
  TEAM_PARENT: 'Родительская команда',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Переопределить кластерную корневую команду',
  TEAM_AFFILIATIONS: 'Аффилированные команды',
  TEAM_TIMEZONE: 'Часовой пояс',
  UPDATE: 'Обновлять',
  AUTO_ASSIGN_BY: 'Автоматическое назначение',
  AUTO_ASSIGNMENTS: 'Автоматические задания',
  NO_ONE: 'Никто',
  NOT_APPLICABLE: 'Непригодный',
  OVERRIDE_NOTIFICATIONS: 'Переопределить уведомления',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Когда пользовательский шаблон с уведомлениями о переопределении устанавливается пользователю, он отключит любые существующие настройки уведомления и включите только настройки уведомления, перечисленные в шаблоне пользователя',
  CREATED_MANUALLY: 'Создано вручную',
  DOCUMENT_TITLE_FOR_WORD:
    'Название для документов Word обычно берется из раздела форматированного заголовка в самом документе. Если документ не содержит заголовка, вы можете указать его здесь. Если вы не указаете заголовок здесь, и в разделе «Word» не содержит раздел «Форматированный заголовок», то идентификатор документа будет использоваться для заголовка.',
  ORDER_BY: 'Сортировать по',
  FORMAT: 'Формат',
  SELECT_QUESTION: 'Выберите вопрос',
  SELECT_QUESTION_FOR_NOTE: 'Выберите вопрос, для которого вы хотите добавить записку',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Добавьте примечание для всего запроса',
  REQUESTED_DATE: 'Запрашиваемая дата',
  UNASSIGNED: 'Не назначен',
  BLANK: 'Пустой',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Ответ сохранен без электронной почты',
  INQ_RELATED_TO: 'Запросы, связанные с',
  QUESTION_TEXT_BEFORE_SPLIT: 'Вопрос текст перед разделением',
  SPLIT_MERGE: 'Расколоть и слияние',
  CLARIFICATION_QUESTION: 'Уточнительный вопрос',
  ACTIVE_ON: 'Активно на',
  DISMISS: 'Увольнять',
  GO_TO_ENQUIRY: 'Перейти к запросу',
  NO_TEAMS: 'Без команды',
  NO_TEAMS_TIP:
    'Содержит пользователей, которые не назначены ни одной команде. Вы можете проверить, являются ли некоторые из них от вашей команды и предоставить им соответствующую роль.',
  TASKS: 'Задачи',
  Therapeutic_Areas: 'Терапевтические зоны',
  Product_Groups: 'Группы продукта',
  Licensing_Partner: 'Лицензирующий партнер',
  Product_Code: 'Код продукта',
  SELECT_EXISTING_ONE: 'Выберите существующий',
  OR: 'или же',
  MANAGE_FORM: 'Управлять формой',
  EVENT_FORM_ADDRESS: 'Адрес формы события',
  EVENT_FORM_PREVIEW_WARING:
    'Убедитесь, что вы сохранили какие -либо изменения, прежде чем открыть форму Конгресса или копировать его адрес',
  SEARCH_SAVED_SEARCHES: 'Поиск сохраненных поисков',
  GO: 'Идти',
  ASSIGN: 'Назначать',
  CERTIFICATION: 'Сертификация',
  SORT: 'Сортировать',
  CREATE_NEW_MAILBOX: 'Создайте новый почтовый ящик',
  EDIT_MAILBOX: 'Редактировать почтовый ящик',
  ADD_MAILBOX: 'Добавить почтовый ящик',
  UNSET_INCOMING_MAILBOX: 'Unset входящий почтовый ящик',
  SET_INCOMING_MAILBOX: 'Установите входящий почтовый ящик',
  UNSET_OUTGOING_MAILBOX: 'Неопределенный исходящий почтовый ящик',
  SET_OUTGOING_MAILBOX: 'Установите исходящий почтовый ящик',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Все команды с текущим почтовым ящиком',
  MAILBOX_HISTORY: 'История почтовых ящиков',
  ALL_TEAMS_WITH_MAILBOX: 'Все команды с почтовым ящиком',
  MAILBOX: 'Почтовый ящик',
  EMAIL_POLLING: 'Электронная почта опроса',
  ADVANCED_SEARCH: 'Расширенный поиск',
  SELECT_FIELDS: 'Выберите поля',
  SEARCH_TERM: 'Искать термин',
  COLUMN_INTERACTION_ID: 'Идентификатор взаимодействия',
  COLUMN_QUESTION_ID: 'Идентификатор вопроса',
  COLUMN_CREATED_ON: 'Создано на',
  COLUMN_LAST_EDITED_DATE: 'Последнее отредактировано',
  COLUMN_ASSIGNED_TO: 'Назначить в',
  COLUMN_DUE_DATE: 'Срок оплаты',
  COLUMN_FOLLOW_SENT_DATE: 'Последующая дата отправлена',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Полученная дата последующего наблюдения',
  COLUMN_ANSWER_DATE: 'Ответ дата',
  COLUMN_CLOSED_ON_DATE: 'Закрыт на дату',
  COLUMN_REOPENED_DATE: 'Повторно открытая дата',
  COLUMN_INSERTED_BY: 'Вставлено',
  COLUMN_LAST_EDITED_BY: 'Последнее отредактировано',
  COLUMN_ANSWERED_BY: 'Ответил',
  COLUMN_APPROVED_BY: 'Одобрено',
  COLUMN_INT_COMM_USER: 'Пользователь внутренней связи',
  COLUMN_INT_COMM_DATE: 'Внутренняя дата связи',
  COLUMN_MY_TEAM: 'Моя команда',
  COLUMN_PQC_RELATED: 'PQC связан',
  COLUMN_INCOMING_CHANNEL: 'Входящий канал',
  COLUMN_OUTGOING_CHANNEL: 'Исходящий канал',
  COLUMN_OFF_LABEL: 'Вне посадки',
  COLUMN_HCP_SPEC: 'HCP + специализация',
  COLUMN_NON_HCP_SPEC: 'Не HCP + профессия',
  COLUMN_KEE: 'Ки',
  COLUMN_HOSPITAL_BASED: 'Больница базируется',
  COLUMN_CITY: 'Город',
  COLUMN_INSTITUTION: 'Учреждение',
  COLUMN_TERRITORY: 'Территория',
  CASE_ID: 'Идентификатор дела',
  OPTION_ADD: 'ДОБАВЛЯТЬ',
  OPTION_AND: 'А ТАКЖЕ',
  OPTION_OR: 'ИЛИ ЖЕ',
  OPTION_NOT: 'НЕТ',
  OPTION_YES: 'Да',
  OPTION_NO: 'Нет',
  QUERY: 'Запрос',
  EXPORT_LABEL: 'Определите столбцы экспорта',
  SAVE_SEARCH: 'Сохраните поиск',
  SHARE_SEARCH: 'Поделиться поиском',
  SEARCH_BTN: 'Поиск',
  BACK_ACTION: 'Назад',
  SAVE_CHANGES: 'Сохранить изменения',
  SAVED_SEARCH: 'Сохраненный поиск',
  NEW: 'Новый',
  SAVE_AS: 'Сохранить как имя',
  ENTER_RECIPIENTS: 'Введите получателей',
  CLEAR_SAVED_SEARCH: 'Ясные сохраненные поиски',
  NO_SAVED_SEARCH: 'Не найдено сохраненного поиска',
  INT_COMM_TEXT: 'Внутренний текст связи',
  FOLLOW_UP_TEXT: 'Последующий текст',
  CONFIRM_DELETION: 'Подтвердить удаление',
  ARE_SURE: 'Вы уверены, что хотите удалить сохраненный поиск',
  PERMANENTLY_DELETED: 'Поиск будет навсегда <b> удален </b>.',
  NOTHING_SAVE: 'Нечего спасти.',
  NOTHING_SEARCH: 'Ничего не искать.',
  NOTHING_EXPORT: 'Поиск не имеет записи для экспорта.',
  SHARE_SUCCESS: 'Поиск был успешно обмен',
  SHARE_FAILURE: 'Поиск не может быть разделен',
  DELETED_SUCCESS: 'Успешно удалил сохраненный поиск',
  ALL_DELETED_SUCCESS: 'Успешно удалил все сохраненные поиски',
  VIEW_SAVED_SEARCH: 'Посмотреть сохраненный поиск',
  EDIT_SAVED_SEARCH: 'Редактировать сохраненный поиск',
  EXPORT_SAVED_SEARCH: 'Экспорт сохранил поиск',
  EXPORT_SEARCH: 'Экспортный поиск',
  DELETE_SAVED_SEARCH: 'Удалить сохраненный поиск',
  SELECT_EXPORT_COLUMNS: 'Выберите столбцы для экспорта',
  SAVED_SEARCH_ALREADY_EXIST: 'Поиск с этим именем уже существует.',
  CHARACTER_LIMIT: 'Мин 3 символов. Макс 35 символов.',
  CHARACTER_LEFT: 'Персонажи остались',
  ACTIVATE: 'Активировать',
  INACTIVE_USER:
    'Этот пользователь больше не активен. Пожалуйста, обратитесь к системному администратору.',
  FOLLOW_UP: 'Следовать за',
  USER_NOT_FOUND: 'Пользователь не найден',
  DEPARTMENTS: 'Отделы',
  EMAIL: 'ЭЛ. АДРЕС',
  ADD_NEW_DEPARTMENT: 'Добавить новый отдел',
  DEPARTMENT_COUNTRY: 'СТРАНА',
  DEPARTMENT_COMPANY: 'КОМПАНИЯ',
  DEPARTMENT_TEAM: 'КОМАНДА',
  DEPARTMENT_TA: 'Терапевтическая зона',
  DEPARTMENT_PRODUCT: 'ТОВАР',
  DEPARTMENT_ACTIONS: 'Действия',
  DEPARTMENT_CONFIRMATION_1: 'Подтвердить удаление?',
  DEPARTMENT_CONFIRMATION_2: 'Это действие необратимо.',
  DEPARTMENT_NAME: 'Название отдела',
  TYPE_DEPARTMENT: 'Тип',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'Страна - пожалуйста, выберите команду сначала',
  DEP_NAME_CHARACTER_LIMIT: 'Макс 200 символов.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Запрос пользователя',
      CategoryTopicRequest: 'Категория/запрос темы',
      ProductRequest: 'Запрос на продукт',
      UserUpdateRequest: 'Запрос на обновление пользователя',
    },
    TYPE: {
      NewUser: 'Новый пользователь',
      NewCategoryTopic: 'Новая категория/тема',
      NewUserUpdate: 'Обновление пользователя',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'Определите список специализации конкретной страны',
      IS_COUNTRY_SPECIFIC: 'Зависит от страны',
      TYPE: 'Тип запрашивающего',
      SPECIALIZATION_EXISTS: 'Конфигурация для «Типа/Специализации запрашивающего» уже существует.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Пол', COUNTRY: 'Страна', TITLE: 'Заголовок' },
  },
  hcp: 'HCP',
  emp: 'Работник',
  cons: 'Не HCP',
  OOO_DEPUTY: 'Ооо, заместитель',
  NO_DEPUTY: 'Нет заместителя',
  UNSPECIFIED_END_DATE: 'Неуказанная дата окончания',
  DELEGATORS: 'Делегаторы',
  NO_DELEGATORS: 'Нет делегаторов',
  PREVIEW_DELEGATORS: 'Предварительный просмотр делегаторов',
  REMOVE_DEPUTY: 'Удалите заместителя',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Пользователи, которые установили вас как заместителя',
  CHANGED_USER: 'Изменен пользователь',
  DEFAULT: 'По умолчанию',
  SPECIALIZATIONS: 'Специализации',
  ENQUIRERS_LIST: 'Список Enquirers',
  EDIT_THE_RECORD: 'Измените запись',
  DEACTIVATE_THE_RECORD: 'Деактивировать запись',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Вне офиса',
      HINT: 'Только активные пользователи с роли обработки запросов, которые не находятся вне офиса, доступны для отбора.',
      MODAL_TITLE: 'Подтвердить настройки офиса',
      MODAL_MSG_1:
        'Вы сразу же отправились в Office Office, все ваши запросы будут немедленно назначены депутату.',
      MODAL_MSG_2:
        'Вы отправились в Office Option, все ваши запросы будут назначены депутату в течение вне офиса.',
    },
  },
  GENDER_MALE: 'Г-н.',
  GENDER_FEMALE: 'Миссис.',
  TITLE_DR: 'Доктор',
  TITLE_PHD: 'кандидат наук',
  TITLE_PROF: 'Профессор',
  TITLE_PROF_DR: 'Проф. Доктор',
  HOME: 'Дом',
  HOSPITAL: 'Больница',
  OFFICE: 'Офис',
  WORK: 'Работа',
  INSERT_IMAGE: 'Вставьте изображение',
  USER_ADMIN: 'Пользовательский администратор',
  MAILBOXES: 'Почтовые ящики',
  AUTHORIZATION_REQUESTS: 'Запросы на авторизацию',
  METADATA: 'Метаданные',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Назначение',
      FORWARD: 'Вперед',
      FOLLOW_UP: 'Следовать за',
      DUE_DATE_SETTINGS: 'Настройки даты срока',
      CLOSE: 'Закрывать',
      DELIVERY_ERROR: 'Ошибка доставки',
      SCHEDULER: 'Планировщик',
      USER_REPORTS: 'Пользовательские отчеты',
      DOCUMENT_APPROVAL: 'Одобрение документа',
      AUTHORIZATION_REQUEST: 'Запросы на авторизацию',
      NEW_INQUIRY: 'Новый запрос',
      INQUIRY_APPROVAL: 'Одобрение запроса',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Запрос на роли был утвержден/отклонен',
      AUTHORIZATION_REQUEST_3: 'Роль ждет моего одобрения',
      DOCUMENT_APPROVAL_1: 'Документ ждет вашего одобрения',
      DOCUMENT_APPROVAL_2: 'Документ был одобрен/отклонен',
      DOCUMENT_APPROVAL_3: 'Документ был заархивирован',
      DOCUMENT_APPROVAL_4: 'Документ был утвержден с изменениями',
      DOCUMENT_APPROVAL_5: 'Документ будет архив в',
      DOCUMENT_APPROVAL_6: 'Список утвержденных документов',
      DUE_DATE_2: 'Повторить после прошлого',
      ERROR_1: 'Ошибка уведомления о доставке (например, неправильная электронная почта)',
      FOLLOW_UP_1: 'Последление или напоминание было отправлено клиенту',
      FOLLOW_UP_4: 'Было получено продолжение',
      IMPORT_DATA_1: 'Новый объект был создан во время импорта',
      IMPORT_DATA_4: 'Новый пользователь был создан во время импорта',
      IMPORT_DATA_5:
        'Пользователь соответствовал уже существующему, но не был из предыдущего импорта',
      IMPORT_DATA_6: 'Произошла ошибка во время импорта данных из внешнего источника',
      INQUIRY_APPROVAL_4:
        'Предложенный вами ответ был утвержден / одобрен с помощью изменений / отклонен',
      METADATA_MANAGEMENT_1: 'Новые метаданные были созданы',
      NEW_DOCUMENT_1: 'Новый утвержденный документ доступен в моей стране',
      NEW_DOCUMENT_2: 'Новый документ доступен для местного одобрения',
      NEW_DOCUMENT_3: 'Новый местный документ доступен для европейского / глобального одобрения',
      NEW_DOCUMENT_4: 'Доступен новый глобальный документ',
      NEW_DOCUMENT_5: 'Доступен новый европейский документ',
      NEW_DOCUMENT_6: 'Новый европейский документ доступен для глобального одобрения',
      SCHEDULER_TASK_1: 'Ошибка произошла во время выполнения задачи',
      USER_REPORTS_1: 'Список активных пользователей',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'Получить уведомления, информирующие вас, когда необходимо утвердить/отклонить запрос на роль пользователя',
        AUTHORIZATION_REQUEST_2:
          'Получите уведомления, когда был создан новый пользователь или когда пользователь запросил роль в вашей команде',
        AUTHORIZATION_REQUEST_4:
          'Получить уведомления, когда была предложена новая тема и/или категория',
        FOLLOW_UP_4:
          'Получать уведомления о запросах, на которые я назначен и на которые получен ответ от отправителя запроса',
        NEW_INQUIRY_1:
          'Получайте уведомления о новых запросах, полученных в вашей стране, введенных пользователем/запросчиком или пересланных другой командой.',
        ANSWER_2:
          'Получать уведомления о запросах, на которые был дан ответ, что я работал в качестве соавтора, но не назначен напрямую на запрос',
      },
      FREQUENCIES: {
        INSTANTLY: 'Немедленно',
        DAILY: 'Повседневная',
        WEEKLY: 'Еженедельно',
        QUARTERLY: 'Ежеквартальный',
        MONTHLY: 'Ежемесячно',
        SECOND_DAY: 'Каждый второй день',
        THIRD_DAY: 'Каждый третий день',
        ONE_WEEK: 'Одна неделя',
        ONE_MONTH: 'Один месяц',
        TWO_MONTHS: 'Два месяца',
        THREE_MONTHS: 'Три месяца',
        SIX_MONTHS: 'Шесть месяцев',
        NINE_MONTHS: 'Девять месяцев',
        EVERY_TWO_WEEKS: 'Каждые две недели',
        EVERY_THREE_WEEKS: 'Каждые три недели',
        EVERY_FOUR_WEEKS: 'Каждые четыре недели',
      },
      OPTIONS: {
        ALMOST_DUE: 'Почти Должжен',
        DUE_NOW: 'Должен сейчас',
        DUE_SOON: 'Скоро вскоре',
        EARLY_ON: 'На ранней стадии',
        TEXT_GUIDES: {
          EARLY_ON:
            'Получить уведомления на раннем этапе для запросов, которые причитаются: нормальные 4 дня - высокие 2 дня - критические 24 часа',
          DUE_SOON:
            'Получить уведомления о запросах, которые должны быть в ближайшее время: нормальные 48 часов - высокие 12 часов - критические 6 часов',
          ALMOST_DUE:
            'Получить уведомления о запросах, которые практически связаны: нормальные 24 часа - высокий 6 часов - критические 3 часа',
          DUE_NOW: 'Получайте уведомления о сроках выполнения запроса',
        },
      },
      AUTHORIZATION_REQUEST_4: 'Категория/ тема была запрошена в моей команде',
      AUTHORIZATION_REQUEST_2: 'Запросы пользователя',
      IMPORT_DATA_2: 'Во время импорта был создан новый запросчик',
      IMPORT_DATA_3:
        'Запрос соответствовал уже существующему, но не был получен из предыдущего импорта',
      ANSWER_1: 'На запрос ответил я',
      ANSWER_2: 'На запрос, в котором я принимал участие, был дан ответ',
      ASSIGNMENT_1: 'Мне был назначен запрос',
      ASSIGNMENT_5: 'Моему заместителю было поручено расследование',
      CLOSE_1: 'Запрос был закрыт',
      DUE_DATE_1: 'Запрос, который мне поручен, близок к дате сдачи, сообщите мне',
      FOLLOW_UP_7: 'Запрос был потерян для продолжения',
      FORWARD_1: 'Запрос был перенаправлен',
      INQUIRY_APPROVAL_1: 'Запрос ожидает вашего одобрения.',
      INTERNAL_1: 'К моему назначенному запросу была добавлена заметка',
      NEW_INQUIRY_1: 'В моей стране получен новый запрос',
      SPAM_1: 'Новый запрос получен в спаме',
    },
  },
  NEW_TEAM: 'Новая команда',
  SmPc: 'SMPC',
  USERS: 'Пользователи',
  PAGINATOR_NEXT_PAGE_LABEL: 'Следующая страница',
  PAGINATOR_PREV_PAGE_LABEL: 'Предыдущая страница',
  PAGINATOR_FIRST_PAGE_LABEL: 'Первая страница',
  PAGINATOR_LAST_PAGE_LABEL: 'Последняя страница',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'пункты на странице',
  Date: 'Свидание',
  Action: 'Действие',
  'Action by user': 'Действие от пользователя',
  'Changed user': 'Изменен пользователь',
  'Original value': 'Исходное значение',
  'New value': 'Новое значение',
  REGION: 'Область, край',
  TEAM_REGION_LABEL: 'Регион - Пожалуйста, выберите компанию первым',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Терапевтические области - пожалуйста, выберите компанию сначала',
  TEAM_PRODUCT_LABEL: 'Продукты - сначала выберите терапевтическую зону',
  TEAM_COUNTRIES_LABEL: 'Страны - пожалуйста, выберите регион первым',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Определите продукты для каждой страны',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Пожалуйста, выберите хотя бы один продукт для',
  VIEW_CHANGES: 'Просмотреть изменения',
  Sales_Representative: 'Торговый представитель',
  Other_Commercial: 'Другой рекламный ролик',
  REGIONS: 'Регионы',
  MAIN_CLIENT: 'Главный клиент',
  LICENSE_PARTNER: 'Лицензионный партнер',
  TEAM_SET_UP: 'Команда настроена',
  LIST_TEAMS: 'Список команд',
  COMPANIES: 'Компании',
  DOMAINS: 'Домены',
  TOKEN_REFRESH_NOTICE:
    'Вы запросили новое подтверждение по электронной почте для регистрации учетной записи Enqmed.',
  TOKEN_REFRESH_SUCCESS: 'Новая ссылка на подтверждение была отправлена на вашу электронную почту.',
  TOKEN_REFRESH_ERROR:
    'Произошла ошибка. Пожалуйста, свяжитесь с поддержкой, если вам нужна дополнительная помощь.',
  PLEASE_ENTER_MAX: 'Пожалуйста, введите максимум',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Пожалуйста, просмотрите изменения ниже, если таковые имеются, и подтвердите, следует ли изменить компанию.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Пожалуйста, продолжайте с осторожностью, потому что изменения повлияют на команды',
  THERE_ARE_NO_CHANGES: 'Нет изменений.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Пожалуйста, проверьте существующие компании в таблице за диалогом, поскольку у нас уже есть компания с одинаковыми значениями.',
  SIMPLE_TEXT:
    'Пожалуйста, введите только буквы (A-Z, A-Z), цифры (0-9) и специальные символы, такие как (Dash, Period, Space и Colon).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS:
    'Пожалуйста, выберите хотя бы одну страну для региона',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Пожалуйста, выберите хотя бы один продукт для терапевтической области',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'Вы выбрали продукт, который принадлежит более чем одной терапевтической области, выберите все необходимые терапевтические области.',
  HTML_CHANGES: 'HTML изменяется',
  SIDE_BY_SIDE_VIEW: 'Бок о бок вид',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Пожалуйста, проверьте существующие домены в таблице за диалогом, так как мы уже домен с одинаковыми значениями.',
  OUT_CHANNEL_ERROR_MSG: 'Почтовый адрес исходящего канала Enquirer',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'Исходящий канал выбран в качестве письма. Пожалуйста, введите почтовый адрес Enquirer в районе Enquirer',
  LABEL_FOR: 'Ярлык для',
  male: 'Мужской',
  female: 'женский',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'У вас нет прав на архивирование этой роли. Только <b> mims </b> для команды могут архивировать роли для членов своей команды.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'Отправить электронную почту пользователю при утверждении/отказе от запроса',
  APPROVE_REQUEST_QUESTION: 'Вы хотите утвердить запрос?',
  NEW_USER_REQUEST: 'Новый пользовательский запрос',
  OPEN_REQUESTS: 'Открытые запросы',
  APPROVED_REQUESTS: 'Утвержденные запросы',
  REJECTED_REQUESTS: 'Отклоненные запросы',
  USER_REQUESTS: 'Запросы пользователя',
  REQUEST_FOR: 'Запрос',
  CATEGORY_TOPIC_REQUESTS: 'Запросы категории/темы',
  WITH_THE_FOLLOWING_PAIRS: 'со следующими парами:',
  MY_TEAM_OPEN_REQUESTS: 'Открытые запросы',
  MY_TEAM_APPROVED_REQUESTS: 'Утвержденные запросы',
  MY_TEAM_REJECTED_REQUESTS: 'Отклоненные запросы',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'Одобрение документа в рамках EnqMed',
  DOCUMENTS_APPROVAL: 'Документы одобрение',
  DOCUMENTS_APPROVAL_TITLE: 'Настройки процесса утверждения документов для выбранной команды',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Исходящий канал выбран в качестве факса. Пожалуйста, введите номер факса Enquirer в районе Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Исходящий канал выбран в качестве телефона. Пожалуйста, введите номер телефона Enquirer в районе Enquirer',
  OUT_CHANNEL_FAX_ERROR_MSG: 'Значение исходящего канала факса Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Значение Enquirer исходящего канала Enquirer',
  COPYRIGHT_PART_ONE: 'авторское право',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Все права защищены.',
  REVIEWED_DATE: 'Рассмотренная дата',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Исходящий канал выбран в качестве электронной почты. Пожалуйста, введите электронное письмо Enquirer в районе Enquirer',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'Электронная почта исходящего канала Enquirer',
  ENTER_USER_COMMENT_OPTIONAL: 'Введите комментарий к пользователю (необязательно)',
  SELECT_ANOTHER_CATEGORY: 'Выберите другую категорию',
  SELECT_ANOTHER_PRODUCT: 'Выберите другой продукт',
  SUGGESTED_TOPIC: 'Предлагаемая тема',
  EDIT_USER_REQUEST: 'Редактировать запрос пользователя',
  NEW_TOPIC_REQUEST: 'Новая тема предложена',
  EDIT_TOPIC_REQUEST: 'Редактировать предложенную тему',
  NEW_CATEGORY_REQUEST: 'Предложенная новая категория',
  EDIT_CATEGORY_REQUEST: 'Редактировать предложенную категорию',
  REVIEW_COMMENT: 'Просмотреть комментарий',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Документ одобрение рабочих процессов',
    CREATE_NEW: 'Создайте новый рабочий процесс',
    CUSTOMIZE: 'Настройте рабочий процесс',
    NAME: 'Название рабочего процесса',
    ERROR_MSG_DUPLICATE_NAME: 'Рабочий процесс с этим именем уже существует.',
    SAVE: 'Сохраните рабочий процесс',
    DELETE: 'Удалить рабочий процесс',
    STEP: 'Шаг',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Все пользователи с роли автора документов.',
    APPROVAL_DEADLINE: 'Крайний срок утверждения',
    SELECT_APPROVAL_TYPE: 'Выберите тип утверждения',
    SELECT_APPROVAL_TYPE_FIRST: 'Выберите тип утверждения первым',
    SELECT_REVIEWERS: 'Выберите рецензентов',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Действие на одобрение с изменениями',
    ACTION_ON_REJECT: 'Действие на отклонение',
    FILL_ALL_REQUIRED_FIELDS:
      'Пожалуйста, заполните все необходимые поля, чтобы добавить новый шаг.',
    ALL_APPROVAL_TYPES_USED:
      'Вы не можете добавить больше шагов одобрения, поскольку вы использовали все типы, доступные для текущего шага.',
    CONFIRMATION_ROW_1:
      'Вы пытаетесь удалить рабочие процесс одобрения документа для команды {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Вы действительно хотите удалить рабочий процесс утверждения?',
    CONFIRMATION_TITLE: 'Удаление рабочего процесса документа',
    TEAM_VIEW_SELECT_EXISTING:
      'Выберите существующий рабочий процесс утверждения документа из приведенного ниже списка или ',
    DOC_VIEW_SELECT_EXISTING: 'Выберите существующий рабочий процесс утверждения документа',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Только один шаг одобрения может быть указан как окончательный.',
    APPROVAL_TYPE_TOOLTIP:
      'Там нет пользователей с роли утверждения документов для этой команды. Пожалуйста, добавьте роли утверждения в первую очередь.',
    INFO_DIALOG_TITLE:
      'Для этой команды не установлено установлено, что рабочий процесс утверждения документов.',
    INFO_DIALOG_MSG:
      'Пожалуйста, свяжитесь с вашим местным MIM, чтобы создать рабочий процесс утверждения документа для команды.',
    STEP_LIMIT_REACHED: 'Вы достигли лимита {{ MAX_STEPS }} шагов одобрения.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Типы документов, которые не требуют одобрения',
  PLEASE_SELECT_TYPE: 'Пожалуйста, выберите тип',
  ABORT_FILE_UPLOAD: 'Загрузить файл',
  USERS_REPORT: 'Пользователи сообщают',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG: 'Закрыв это окно, вы потеряете уже заполненную информацию.',
  DO_YOU_WANT_TO_PROCEED: 'Вы хотите продолжить?',
  USERS_REPORT_DESCRIPTION:
    'Список пользователей всех дополнительных команд, где MIM для Daiichi Sankyo Europe Oncology и сердечно -сосудистых заболеваний играет роль.',
  TEMPLATE_CONFIRM_MSG: 'Как только шаблон удален, его нельзя восстановить!',
  EVENT_CONFIRM_MSG: 'Как только мероприятие заархивировано, его нельзя восстановить!',
  CREATE_DRAFT: 'Создать проект',
  CREATE_USER: 'Создать пользователя',
  ADD_ROLE: 'Добавить роль',
  DOMAIN_ADDRESS_INVALID_FOR: 'Доменное адрес неверный для',
  AVAILABLE_ADDRESSES: 'Доступные адреса',
  IN_TEAM: 'в команде',
  TO_THE_SELECTED_USER: 'выбранному пользователю',
  USER_INFORMATION: 'Информация о пользователе',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'Адрес электронной почты, который вы используете, должен быть обновлен, поскольку адрес домена не соответствует команде. Ваш профиль связан.',
  MESSAGE: 'Сообщение',
  IN_APPROVAL: 'В утверждении',
  IN_REVIEW: 'В обзоре',
  DOCUMENT_APPROVAL: 'Одобрение документа',
  DOCUMENT_TITLE: 'Заголовок документа',
  SEARCH_DOCUMENTS: 'Поисковые документы',
  APPROVAL_INBOX: 'Одобрение почтовых ящиков',
  FILTER_DOCUMENTS_BY: 'Фильтровать документы',
  submit_to_approval: 'Подчиняться утверждению',
  create_document_metadata: 'Создать метаданные документа',
  archive: 'Архив',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'Я признаю, что я обязан сообщать о всех побочных реакциях на лекарства (ADR) всех медицинских продуктов в соответствии с местными требованиями. Enqmed не предназначен для получения сообщений о побочных реакциях на лекарства - ADR должны сообщать о фармаконадзоре непосредственно в соответствии с местными требованиями',
  VERSION_DIALOG: {
    TITLE: 'Доступна новая версия',
    MESSAGE:
      'Доступна новая версия программы!\n Вам необходимо получить новую версию, чтобы воспользоваться ее улучшениями и избежать проблем при использовании старой версии, вам необходимо ее получить.',
    SAVE_BUTTON: 'Получить новую версию',
  },
  ACTIVE_INACTIVE: 'Активный/Неактивный',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Пожалуйста, проверьте существующие категории в таблице позади диалогового окна, так как у нас уже есть категория с такими же значениями.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Ошибка! Неудачная доставка',
  VIA: 'с помощью',
  INSERTED_MANUALLY: 'Вставлено вручную',
  AS_A: 'как',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Пожалуйста, проверьте существующие продукты в таблице позади диалогового окна, так как у нас уже есть продукты с такими же значениями.',
  NOT_PRODUCT_SPECIFIC: 'Не зависит от продукта',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Пожалуйста, проверьте существующие темы в таблице позади диалогового окна, поскольку у нас уже есть темы с такими же значениями.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Введите максимум {{ MAX_CHARS }} символов.',
  THANK_YOU_MESSAGE: 'Спасибо, сообщение',
  AS: 'как',
  THERAPEUTIC_AREA_PARENT: 'Родительская область',
  MAIN_THERAPEUTIC_AREA: 'Основное терапевтическое направление',
  CHOOSE_AN_IMAGE: 'Выберите изображение',
  ALLOWED_FORMATS: 'Разрешены только форматы {{ FORMATS }}.',
  REMOVE_IMAGE: 'Удалить изображение',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Пожалуйста, проверьте существующие терапевтические области в таблице позади диалогового окна, поскольку у нас уже есть области с такими же значениями.',
  PLAIN_TEXT_VIEW: 'Простой текстовый вид',
  RICH_TEXT_VIEW: 'Расширенный текстовый вид',
  EDIT_ITEM: 'Изменить {{ ITEM }}',
  CREATE_NEW_ITEM: 'Создать новый {{ ITEM }}',
  ADD_NEW_ITEM: 'Добавить новый {{ ITEM }}',
  ADD_ITEM: 'Добавьте {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: 'и, следовательно, статус изменился с',
  FOLLOW_UP_WITH_ENQUIRER: 'Дальнейшее общение с запрашивающим',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'Для сохранения изменений необходимо вставить следующее:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'Последующее наблюдение невозможно при закрытом взаимодействии.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Взаимодействие в статусе рассмотрения. Пожалуйста, сохраните отзыв, чтобы включить это меню.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Текст вопроса (минимум 6 символов)',
  PLEASE_PROVIDE_AN_ANSWER_: 'Пожалуйста, дайте ответ (минимум 6 символов или вложение).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    'Обратите внимание, что пользователю необходимо восстановить пароль из «Забыли пароль».',
  ENABLE_USER_LOGIN_MESSAGE: 'Это позволит пользователю войти в систему.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE: 'Это отключит вход пользователя и уведомления.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Пожалуйста, установите настройки уведомлений в своем профиле пользователя!',
  SET_PASSWORD: 'Пожалуйста, установите свой пароль',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'После повторной активации вашей учетной записи ваш пароль был стерт.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Подтвердите деактивацию специализации!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Это удалит его из списка активных специализаций.',
  GO_TO_ROLES: 'Перейти к Ролям',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Ваши изменения в контактной информации пользователя привели к изменению адреса электронной почты для входа с {{ OLD_USERNAME }} на {{ NEW_USERNAME }}.',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Назначьте роли и уведомления.',
  DO_YOU_WANT_TO_CONTINUE: 'Вы хотите продолжать?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Скопировать ответ на новое взаимодействие',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Импортированы новые запросы. Обязательно охарактеризуйте и ответьте на все вопросы.',
  LEAVE_WITHOUT_SAVING: 'Уйти без сохранения',
  PENDING_CHANGES_MESSAGE: 'Ожидаемые изменения: {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Вы уверены, что хотите разделить выделенный текст в новом взаимодействии?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'Это удалит выделенный текст из взаимодействия и создаст из него новый.',
  DOWNLOAD_ERROR: 'Ошибка загрузки!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'Вы пытаетесь загрузить более 1000 запросов. Максимальное поддерживаемое число — 1000. Уточните свой выбор.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION: 'Вы пытаетесь изменить характеристику вопроса!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Обратите внимание, что изменение характеристики вопроса не позволит внести какие-либо изменения в ранее заданный ответ или отправить новый ответ!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'Вы собираетесь повторно открыть закрытое взаимодействие, чтобы отправить новый ответ.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE:
    'Вы уверены, что хотите преобразовать это ответное сообщение в вопрос?',
  ARE_YOU_SURE: 'Вы уверены?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Подтвердите деактивацию {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Подтвердите повторную активацию {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: 'Пользователь {{ USER_INFO }} успешно повторно активирован!',
  DELETE_NOT_POSSIBLE: 'Удалить невозможно!',
  SEARCH_NO_LONGER_EXIST: 'Этот поиск больше не существует, поскольку он был удален отправителем.',
  LOGIN_CREDENTIALS_CHANGED: 'Учетные данные для входа изменены!',
  EDIT_TEXT: 'Редактировать текст',
  FOLLOW_UP_ATTEMPTS: 'Последующие попытки',
  FIRST_FOLLOW_UP_INFO_TEXT: 'Первое сообщение отправлено в рамках индивидуального взаимодействия',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'Если что-либо из перечисленного ниже деактивировано после первоначального запроса, процесс перейдет к следующему шагу.',
  SECOND_ATTEMPT: 'Вторая попытка (первое напоминание)',
  THIRD_ATTEMPT: 'Третья попытка (первое напоминание)',
  FINAL_ATTEMPT: 'Потерян для наблюдения (закрыть)',
  REMINDER_FREQUENCY: 'Частота напоминаний',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Применить автоматический перевод на язык, используемый для связи с запрашивающим',
  ON_OFF_SWITCH: 'Вкл выкл',
  DAY: 'День',
  DAYS: 'Дни',
  MONTH: 'Месяц',
  MONTHS: 'Месяцы',
  RESET_TEMPLATES_TO_DEFAULTS: 'Сбросить шаблоны к настройкам по умолчанию',
  SAVE_FREQUENCY: 'Сохранить частоту',
  SELECT_FREQUENCY: 'Выберите частоту',
  TEMPLATE_MODIFICATIONS_HINT:
    'Текст был изменен вручную и больше не будет меняться автоматически. Нажмите здесь, чтобы отменить изменения и снова включить автоматические обновления.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Неверный адрес электронной почты!',
  AUDIT_TRAIL_FOR: 'Контрольный журнал для {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Контрольный журнал для всех {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'История изменений для',
  HOLIDAY_GDPR_DISCLAIMER: 'Отказ от ответственности в рамках GDPR в праздничные дни',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'Управляйте отказом от ответственности GDPR в праздничные дни',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'Отсутствует праздничный шаблон GDPR для следующих команд',
  BULK_CREATE: 'Массовое создание',
  LAST_SUCCESS: 'Последний успех',
  LAST_FAILURE: 'Последняя неудача',
  FAILURE: 'Отказ',
  TASK: 'Задача',
  Weeks: 'Недели',
  REPEAT_EVERY: 'Повторяйте на каждом',
  DEACTIVATE_ITEM: 'Деактивировать {{ ITEM }}',
  RESET_FAILURE_COUNT: 'Сбросить счетчик ошибок',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Пожалуйста, проверьте существующие задачи в таблице позади диалогового окна, поскольку у нас уже есть задачи с такими же значениями.',
  ACTIVATE_ITEM: 'Активировать {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Пожалуйста, введите число больше {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'Выбранное целевое взаимодействие для слияния уже закрыто. Этот запрос представляет собой новое взаимодействие, и его не нужно объединять.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'В существующий запрос будут добавлены новые вопросы, на все вопросы необходимо ответить. Пожалуйста, выберите вопросы для объединения.',
    VALIDATION_MSG:
      'Чтобы продолжить объединение, выберите хотя бы один вопрос из объединенного взаимодействия.',
  },
  MERGED_INTERACTION: 'Объединенное взаимодействие',
  TARGET_INTERACTION: 'Целевое взаимодействие',
  MERGE_SUGGESTION: 'Объединить предложение',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Пожалуйста, ознакомьтесь с предложением по слиянию ниже',
  INQUIRERS_COMPARISON_TITLE:
    'Обратите внимание на разные запросы для объединенного взаимодействия и целевого взаимодействия.',
  INQUIRERS_COMPARISON_QUESTION:
    'Пожалуйста, подтвердите, хотите ли вы продолжить, несмотря на эту разницу.',
  TERRITORY: 'Территория',
  DOMAIN: 'Домен',
  IN: 'в',
  TEAM_GROUP_FILTER: {
    TITLE: 'Определите разрешения для просмотра дополнительных команд',
    SAVE_BTN_TOOLTIP:
      'Пожалуйста, введите все обязательные поля для команды и сначала сохраните команду.',
  },
  ADR_REF_ID: 'Справочный идентификатор ДОПОГ',
  PQC_REF_ID: 'Идентификатор ссылки PQC',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS:
    'Это вложение присутствует в нескольких взаимодействиях',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Установите флажок, если вы хотите удалить вложение из всех взаимодействий.',
  CONFIRM_DELETE_ATTACHMENT: 'Пожалуйста, подтвердите, хотите ли вы удалить это вложение.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'Удалить из всех взаимодействий, упомянутых выше',
  ATTACHMENT_DELETED: 'Вложение удалено.',
  ROLES: 'Роли',
  SET_TO_ALL_INQ_TOOLTIP:
    'Установите одинаковые характеристики ADR, PQC и Off-label для всех вопросов.',
  TASKS_LIST: 'Список задач',
  TASK_LOGS: 'Журналы задач',
  SELECTED: 'Выбрано',
  LICENSE_COUNTER: 'Лицензионный счетчик',
  LICENSES_LIMIT: 'Ограничение количества лицензий',
  USERS_TO_EXCLUDE: 'Пользователи, которых нужно исключить',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Плата за лицензию по контракту (роли пользователей).',
  SELECT_USER_ROLES_TO_COUNT:
    'Выберите роли пользователей, применимые для подсчета лицензионных сборов.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Исключить пользователей из подсчета лицензионных сборов.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Уведомления о поступлениях и продажах',
  REFRESH_MIGRATION_ERRORS: 'Обновить список ошибок миграции',
  MARKED_AS_RESOLVED_BY: 'Помечено как решенное пользователем',
  MARKED_AS_RESOLVED_ON: 'Помечено как решенное на',
  MARK_AS_RESOLVED: 'Отметить как решенное',
  ERROR_ON: 'Ошибка включена',
  TEXT_WITH_SPECIAL_CHARS:
    'Введите только буквы (a–z, A–Z), цифры (0–9) и специальные символы, такие как тире (-), точка (.), пробел, двоеточие (:), запятая (,), круглые скобки (()), вперед. косая черта (/) и плюс (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'Взаимодействие находится в статусе «Отправлено на утверждение». Пожалуйста, одобрите/отклоните, чтобы включить это меню.',
  REFRESH_TASK_LOGS: 'Обновить список журналов задач',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'Пожалуйста, подтвердите, если вы хотите продолжить изменения!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'Архивирование вручную не разрешено для заместителей.',
  RUN_COUNTER: 'Счетчик пробега',
  EXPORT_LICENSES: 'Экспортные лицензии',
  LICENSE_COUNT_MESSAGE: 'Количество использованных лицензий:',
  QUESTIONS: 'Вопросы',
  CLARIFICATION: 'Разъяснение',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'Чтобы продолжить объединение, выберите хотя бы один вопрос в целевом взаимодействии.',
    DESCRIPTION:
      'Уточняющий вопрос будет добавлен под выбранными вопросами в целевом взаимодействии.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'Выберите этот параметр, чтобы пометить взаимодействие как дубликат существующего взаимодействия.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Обнаружено предыдущее объединение в качестве уточняющего вопроса. Все выбранные поля ответа в дополнение к ранее выбранным полям ответа в целевом взаимодействии будут повторно открыты для ответа.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Взаимодействие возобновлено для предоставления ответа на другой вопрос. Ответ на этот вопрос уже был дан ранее.',
  TIP: 'Кончик',
  MERGE_TIP_TEXT:
    'Если вместо этого вы хотите повторно открыть взаимодействие {{ MERGED_INTO_ID }}, нажмите "Нет" и запустите новый процесс слияния, указав целевое взаимодействие {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'Вы выбираете операцию слияния без открытия целевого взаимодействия.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'Вы выбираете операцию слияния с недопустимым целевым взаимодействием {{ TARGET_INTERACTION_ID }}.<br>Взаимодействие {{ TARGET_INTERACTION_ID }} было закрыто со статусом {{ STATUS }}{{ LAST_MERGE_INFO }}.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Это приведет к закрытому взаимодействию и невозможности дать ответ.',
  INTO_INTERACTION: 'во взаимодействие',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Пожалуйста, выберите взаимодействие для объединения.',
  PLEASE_SELECT_MERGE_REASON: 'Пожалуйста, выберите причину объединения.',
  QUESTION_CANNOT_BE_DELETED:
    'Этот вопрос невозможно удалить, поскольку существует связанный с ним уточняющий вопрос, оставшийся без ответа.',
  MERGE_REASON: 'Причина объединения',
  WITH_REOPEN: 'с повторным открытием',
  MERGED_INTO_QUESTION_IDS: 'объединены с идентификаторами вопросов',
  MERGED_INTO_QUESTIONS: 'объединено в вопрос(ы)',
  MERGED_PREVIOUSLY_FROM: 'ранее объединено из',
  FROM_INTERACTION_ID: 'из идентификатора взаимодействия',
  IN_INTERACTION_ID: 'идентификатор взаимодействия',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'Вы собираетесь отменить предложение по слиянию.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Больше не показывать предложение об объединении?',
  PRODUCT_RESOLVED_VIA_AI: 'Продукт (решается с помощью AI)',
  REQUIRED_FIELDS_HINT: 'Пожалуйста, заполните все обязательные поля',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'Требуется хотя бы один контакт!',
  DISCLAIMER_INFO: 'Информация об отказе от ответственности',
  QUESTION_ID: 'Идентификатор вопроса',
  QUESTION_IDS: 'Идентификаторы вопросов',
  SHOW_TEAMS: 'Показать команды',
  LAST_POLLING: 'Последний опрос',
  HOST: 'Хозяин',
  PROTOCOL: 'Протокол',
  FROM_EMAIL: 'Из электронной почты',
  REASON_FOR_REMOVAL: 'Причина удаления',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'Закрытое взаимодействие недоступно для редактирования.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'Взаимодействие имеет более одного вопроса. Редактирование возможно только из открытого взаимодействия.',
  BULK_EDIT: 'Массовое редактирование',
  HOW_TO_ENABLE_BULK_EDIT:
    'Для выполнения массового обновления необходимо выбрать как минимум два шаблона на одном языке.',
  REASON_FOR_UPDATE: 'Причина обновления',
  FOLLOW_UP_RESEND: 'Повторное сообщение отправлено',
  SENT_FOLLOW_UP_REMINDER: 'отправил дополнительное напоминание',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'закрыл взаимодействие и отправил уведомление о потере для последующего наблюдения',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'Введите текст длиной не менее 15 символов.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'Отслеживание отключено! Пожалуйста, сначала введите запрос.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'Последующий запрос отключен! Пожалуйста, сначала введите действительный адрес электронной почты заявителя.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'Число выбранного типа частоты должно находиться в диапазоне от 1 до {{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Пожалуйста, выберите тип частоты.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'Отсчет начинается с даты и времени первоначального запроса на последующее наблюдение. Для перевода дней в недели умножьте на 7, например. 2 недели = 14 дней, дни и месяцы умножаются на 30, например 1 месяц = 30 дней.',
  VALID_PHONE_FAX: 'Пожалуйста, введите действительный номер телефона/факса!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Выберите язык для просмотра и управления переведенными шаблонами.',
  RETRANSLATE: 'Перевести заново',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'Приведенные ниже шаблоны представлены только на английском языке, переводы находятся ниже.',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Для всех шаблонов, указанных выше, будут восстановлены значения по умолчанию (изменение вручную будет удалено, периодичность будет установлена на 14дней, флажок перевода будет снят), повторно переведены на все языки, а параметр is_manually_modified будет очищен.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Отредактируйте текст шаблона и переведите заново все переводы, которые не были изменены вручную.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Переведите исходный текст шаблона, в том числе измененный вручную.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Шаблоны последующих напоминаний',
  MANUALLY_MODIFIED: 'Изменено вручную',
  SYNC: 'Синхронизировать',
  DIFFERENT: 'Другой',
  DEFAULT_CONFIRMATION_QUESTION: 'Пожалуйста, подтвердите, если вы хотите продолжить.',
  SEND_WELCOME_MAIL_: 'Отправить приветственное письмо со сбросом пароля',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'Я подтверждаю, что запрашивающий получил новую дополнительную информацию и хотел бы возобновить взаимодействие для предоставления ответа.',
  ONLY_LETTERS_SPACE_AND_DASH: 'Пожалуйста, вводите только буквы, пробел и символ -!',
  INFO: 'Информация',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'Изменение страны запрашивающего изменит назначение взаимодействия с команды {{CURRENT_TEAM}} на команду {{FUTURE_TEAM}}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'Изменение страны запрашивающего изменит назначение взаимодействия с команды {{CURRENT_TEAM}} на команду {{FUTURE_TEAM}}. Пользователь {{ASSIGNED_TO}} не имеет разрешений для команды {{FUTURE_TEAM}}, и его персональное назначение будет удалено.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Это изменение повлияет на командные назначения в следующих дополнительных взаимодействиях, при этом личные назначения будут удалены: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Изменение страны отправителя запроса изменит назначения команд в следующих дополнительных взаимодействиях, где персональные назначения будут удалены. {{RELATED_INTERACTIONS_TEAMS_AND_USERS}}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'ID взаимодействия: {{ INTERACTION_ID }} от {{ CURRENT_TEAM }} до {{ FUTURE_TEAM }}, удалено персональное назначение пользователя {{ ASSIGNED_TO }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Пользователь {{EDITING_USER}} изменил страну отправителя запроса {{ENQUIRER}} во взаимодействии с идентификатором: {{UPDATED_INTERACTION_ID}}, что изменило назначение текущего взаимодействия с команды {{OLD_TEAM}} на команду {{NEW_TEAM}}.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Пользователь {{EDITING_USER}} изменил страну запрашивающего {{ENQUIRER}} в администрировании HCP, что изменило назначение текущего взаимодействия с команды {{OLD_TEAM}} на команду {{NEW_TEAM}}.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Пользователь {{ INTERACTION_ASSIGN_TO }} не имеет прав для команды {{ NEW_TEAM }}, и его персональное назначение удалено.',
  PERSONAL_ASSIGNMENT: 'Личное задание',
  DUE_TO_FU_REQUEST: 'в связи с последующим запросом',
  DUE_TO_FU_RESPONSE: 'из-за последующего ответа',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'в связи с обновлением частоты последующих напоминаний со страницы администратора',
  UPDATED_FROM_INBOX: 'обновлено из входящих',
  ARCHIVED_ROLE: 'Архивная роль',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Архивация роли пользователя изменит назначения в следующих дополнительных взаимодействиях, где личные назначения будут удалены: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Пользователь {{EDITING_USER}} отредактировал пользователя {{INTERACTION_ASSIGN_TO}}, архивировав его роль как {{USER_ROLE}} в команде {{TEAM}}. У пользователя {{INTERACTION_ASSIGN_TO}} нет прав для команды {{TEAM}}, и его персональное назначение удалено.',
  ASSIGNMENT: 'Назначение',
  PERIOD_TO_PERIOD_OVERLAP: 'Период и период до перекрываются',
  PERIOD_TO_PERIOD_MISMATCH:
    'Период и период до имеют разную продолжительность - {{ PERIOD }} {{ INTERVAL }} и {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'созданный вопрос из последующего ответа',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Эта опция позволяет создать новый вопрос из последующего ответа.',
  ADD_AS_A_NEW_QUESTION: 'Добавить как новый вопрос',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'Имейте в виду, что вы пытаетесь архивировать последнюю утвержденную роль для этого пользователя, что приведет к деактивации пользователя и запретит его дальнейший доступ к EnqMed.',
  ATTEMPTED_FOLLOW_UP: 'предпринял попытку продолжения',
  FAILED_FU_ATTEMPT: 'Неудачная попытка продолжения',
  UNABLE_TO_REACH_ENQUIRER: 'Не удалось связаться с запрашивающим.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Выберите этот вариант, если попытка связаться с запрашивающим была предпринята, но не увенчалась успехом, например, не удалось связаться по телефону, не удалось встретиться лично или назначить другую встречу.\nПопытка будет зафиксирована в истории взаимодействия.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'Вы будете перенаправлены на страницу, к которой у вас есть разрешения.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    'в связи с остановкой процесса отслеживания в связи со сменой запрашивающего',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Вы собираетесь сменить запрашивающего, что остановит процесс отслеживания.',
  INQUIRER_DETAILS: 'Подробности запроса',
  TEAM_NOTIFY_INQUIRER: 'Уведомить запрашивающего',
  INQUIRER_TYPE: 'Тип запрашивающего',
  DELETE_INQUIRER_PII: 'Удалить личную информацию запрашивающего',
  MARKED_INQUIRER_PII_DELETE: 'отмеченные персональные данные запрашивающего для удаления',
  UNMARKED_INQUIRER_PII_DELETE: 'неотмеченная личная информация запрашивающего для удаления',
  NEW_ENQUIRER: 'Новый опрашивающий',
  NEW_ENQUIRER_TOOLTIP:
    'Используйте эту опцию для создания совершенно нового искателя. Если вместо этого требуется редактирование данных существующего искателя, не используйте эту опцию.',
  CONFIRM_SET_NEW_ENQUIRER:
    'Подтвердите, что вы хотите заменить существующего запрашивающего на нового.<br>Вся введенная информация будет утеряна.<br>Если вы хотите вместо этого отредактировать данные существующего запрашивающего, измените данные непосредственно в форме и нажмите «Сохранить».',
  INQUIRER: 'Опрашивающий',
  KEEP_INQUIRER_DETAILS_OPEN: 'Держите данные запрашивающего открытыми',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'У вас есть несохраненные изменения, которые будут потеряны, если вы закроете данные запрашивающего.',
  SHARE_FROM_HERE_YOU_CAN_:
    'Поделиться: отсюда вы можете назначить, переслать или начать последующую работу с запрашивающим лицом.',
  UPDATED_INQUIRER_DETAILS: 'обновленные данные запрашивающего',
  CREATED_INQUIRER: 'созданный исследователь',
  SUGGESTED_INQ: 'Предложенные запросы',
  SELECT_INQUIRER: 'Выбрать опрашивающего',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'Для подачи заявки на взаимодействие необходимо выбрать согласие на обязательство ADR и запрашивающего.',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'Ответ не был отправлен запрашивающему лицу из-за неверного адреса электронной почты.',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'Спасибо! Уведомление будет отправлено после отправки запроса или ответа на него.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'Спасибо! Уведомление будет отправлено после сохранения запрашивающего.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'Следующее уведомление GDPR будет отправлено запрашивающему лицу по электронной почте.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'Следующее уведомление GDPR было отправлено заявителю по электронной почте.',
  SEND_A_NOTIFICATION_TO_THE_INQ:
    'Отправьте следующее уведомление GDPR запрашивающему лицу по электронной почте.',
  INQ_HAS_NO_EMAIL: 'у запрашивающего нет адреса электронной почты.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'Уведомление о получении запроса заявителю НЕ было отправлено.',
  NOTIFICATION_SENT_TO_THE_INQUIRER: 'Заявителю отправлено уведомление о получении запроса.',
  THIS_IS_JUST_A_THANK_YOU:
    'Это просто благодарственное сообщение от автора существующего запроса.',
  SAVE_INQUIRER: 'Сохранить Исследователя',
  INQ_DEFAULT_VAL: 'Значения по умолчанию для запрашивающего',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'В случае запросов, направленных через представителя компании (торгового представителя, MSL или другого), уведомите запрашивающего об обработке его персональных данных.',
  SELECT_PREFERRED_CONTACT_ERROR:
    'Вам необходимо выбрать предпочтительный контакт для запрашивающего.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Последующее действие, инициированное запрашивающим',
  NO_INQUIRER: 'Нет спрашивающего',
  'Changed inquirer': 'Измененный запрашивающий',
  DELETE_INQUIRY_MESSAGE: 'Вы собираетесь удалить этот запрос. Это действие необратимо.',
  QUESTION_REQUIRE_ANSWER_MESSAGE: 'Для закрытия запроса потребуется ответ на новый вопрос.',
  IMAGE: 'Изображение',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'Терапевтическая зона — {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', так как нет связанных продуктов. Пожалуйста, выберите хотя бы один продукт, чтобы сделать терапевтическую область активной.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'При {{ ACTION_TYPE }} выбранного {{ METADATA_TYPE }} будет применена деактивация следующих связанных {{ AFFECTED_ITEMS_TYPE }}:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'При удалении продуктов будут деактивированы как связанные с ними метаданные, так и сами продукты:',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Расширить все терапевтические области',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Свернуть все терапевтические области',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'Чтобы установить терапевтическую область (ТА) в качестве основной ТА, отмените выбор родительской ТА и сохраните.',
};
