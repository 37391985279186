export const arabicLangData = {
  MEDICAL_INFO_SYSTEM: 'نظام المعلومات الطبية',
  ACTIVE_EVENTS: 'الأحداث النشطة',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'حدد حدثًا لتصفح المستخدمين.',
  BACK_TO_THE_EVENT_SELECTION: 'العودة إلى اختيار الحدث',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'إذا نسيت كلمة المرور الخاصة بك',
  LOGIN: 'تسجيل الدخول',
  RESET_IT_HERE: 'إعادة تعيينها هنا',
  CHANGE_LANGUAGE_TO: 'تغيير اللغة إلى',
  TEST_SYSTEM: 'نظام اختبار',
  HELP: 'مساعدة',
  THIS_IS_TEST_ENV: 'هذه بيئة اختبار ، إذا كنت تريد إدراج استفسار حقيقي انتقل إلى',
  USER_OR_PASS_IS_INCORRECT: 'اسم المستخدم أو كلمة المرور غير صحيحة',
  OPS: 'عذرًا',
  WE_DID_NOT_FIND: 'لم نجد ما تبحث عنه',
  VIDEO_TUTORIALS: 'دروس الفيديو',
  STILL_DIDNT_FIND: 'لم تجد ما تبحث عنه ،',
  ASK_THE_SUPPORT_TEAM: 'اسأل فريق الدعم',
  LOGGED_IN_AS: 'تسجيل دخولك',
  MY_PROFILE: 'ملفي',
  LOGOUT: 'تسجيل خروج',
  RETRIEVING_YOUR_ACCESS_INFO: 'استرجاع معلومات الوصول الخاصة بك',
  LOGGED_IN_CAN_CLOSE: 'عند تسجيل الدخول ، يمكنك الآن إغلاق علامة التبويب هذه',
  TOKEN_HAS_NOT_BEEN_SAVED: 'لم يتم حفظ الرمز بشكل صحيح ، يرجى الاتصال بالدعم',
  SHARE_POINT_LOGIN: 'تسجيل الدخول إلى SharePoint',
  LOGIN_TO_SHARE_POINT: 'يرجى تسجيل الدخول إلى SharePoint للمتابعة',
  PASSWORD_RESET: 'إعادة تعيين كلمة المرور',
  RESET_PASSWORD: 'إعادة تعيين كلمة المرور',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: 'يرجى إدخال بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك',
  CANCEL: 'إلغاء',
  CANCEL_UPPERCASE: 'إلغاء',
  RETURN_TO_LOGIN: 'العودة لتسجيل الدخول',
  USERNAME: 'اسم المستخدم',
  USERNAME_IS_REQUIRED: 'اسم المستخدم مطلوب',
  PASSWORD: 'كلمه السر',
  PASSWORD_IS_REQUIRED: 'كلمة المرور مطلوبة',
  FIRST_NAME: 'الاسم الاول',
  LAST_NAME: 'الكنية',
  REGISTER: 'تسجيل',
  LOADING: 'جار التحميل',
  INSERT_NEW_PASS: 'يرجى إدخال كلمة المرور الجديدة الخاصة بك',
  VERIFYING_TOKEN: 'التحقق من الرمز',
  PASSWORD_DO_NOT_MATCH: 'كلمة المرور غير مطابقة',
  PASS_RESET_SUCCESSFUL: 'تمت إعادة تعيين كلمة المرور بنجاح',
  SAVE_NEW_PASS: 'حفظ كلمة المرور الجديدة',
  GO_TO_INBOX: 'انتقل إلى Inbox',
  SELECT_ALL: 'اختر الكل',
  DESELECT_ALL: 'الغاء تحديد الكل',
  DO_NOT_SHOW_AGAIN: 'لا تظهر مرة أخرى',
  INQUIRY: 'تحقيق',
  ATTACHMENT_WITH_THIS_INQUIRY: '> المرفقات بهذا الاستفسار',
  USER_DETAILS: 'بيانات المستخدم',
  CLOSE: 'قريب',
  TEAMS: 'فرق',
  TEAM: 'الفريق',
  SPECIALIZATION_IS: 'التخصص هو',
  REQUIRED: 'مطلوب',
  RESULTS_PER_PAGE: 'النتائج لكل صفحة',
  RESULTS_FOUND: 'العثور على نتائج',
  EXPORT: 'تصدير',
  PERSONAL_SETTINGS: 'اعدادات شخصية',
  TEAM_SETTINGS: 'إعدادات الفريق',
  USER_SETTINGS_OF: 'إعدادات المستخدم لـ',
  SET_TEMPLATE: 'تعيين القالب',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'سيتم الكتابة فوق الإشعارات الحالية',
  PREVIEW: 'معاينة',
  PREVIEW_AND_SEND: 'معاينة وإرسال',
  NOTIFICATIONS: 'إشعارات',
  ROLE: 'وظيفة',
  TEMPLATE_NAME: 'اسم القالب',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'حفظ هذا المستخدم كقالب',
  ADD_A_TEMPLATE_TO_YOUR_USER: 'أضف نموذجًا إلى المستخدم وانسخ دور وتفضيلات الإشعارات',
  AUTH_AND_NOTIFY_TEMPLATE: 'نموذج التفويض والإشعارات',
  MY_ROLES: 'دوري',
  STATUS: 'الحالة',
  THIS_ROLE_IS_TEMP: 'هذا الدور مؤقت ، وقد تم منحه نظرًا لأن هذا المستخدم نائب',
  CREATED: 'خلقت',
  APPROVED_REJECTED: 'تمت الموافقة عليه / رفضه',
  APPROVED_REJECTED_BY: 'تمت الموافقة عليه / تم رفضه بواسطة',
  ARCHIVE: 'أرشيف',
  SAVE: 'حفظ',
  IMMEDIATE_EFFECT: 'تأثير فوري',
  OUT_OF_OFFICE_DEPUTY: 'خارج مكتب نائب',
  AUTO_ASSIGN_TO: 'تعيين تلقائي إلى',
  GEOGRAPHY: 'جغرافية',
  ALIGNMENT: 'انتقام',
  Alignments: 'المحاذاة',
  Address: 'عنوان',
  PHONE_FAX: 'الهاتف / الفاكس',
  Email: 'البريد الإلكتروني',
  Contacts: 'جهات الاتصال',
  WORK_INFO: 'معلومات العمل',
  LAST_NAME_IS: 'الاسم الاخير هو',
  PERSONAL_INFO: 'معلومات شخصية',
  USER: 'المستعمل',
  ID: 'هوية شخصية',
  UPDATED_FROM: 'تم التحديث من',
  ON: 'على',
  CRM: 'CRM',
  IMPORTED_FROM: 'مستورد من',
  CHANGE_PASS: 'تغيير كلمة المرور',
  NOTIFICATION_IN_TEMPLATE: 'الإخطارات في القالب',
  CONTINUE: 'استمر',
  DONT_SHOW_AGAIN: 'لا تظهر هذه الرسالة مرة أخرى',
  USING_THE_TABS_ON_THE: 'باستخدام علامات التبويب في أعلى الشاشة',
  PERSONAL_PROFILE_SETTINGS: 'إعدادات ملف التعريف الشخصي',
  AND_YOUR: 'وخاصتك',
  YOU_CAN_SWITCH_BETWEEN: 'يمكنك التبديل بين الخاص بك',
  WELCOME_TO_YOUR_USER_PROFILE: 'مرحبًا بك في ملف تعريف المستخدم الخاص بك',
  SAVE_THIS_USER_AS: 'حفظ هذا المستخدم كقالب',
  INCLUDE_NOTIFY_SETTINGS: 'يتضمن إعدادات الإخطار',
  INCLUDE_ROLES_SETTINGS: 'يتضمن إعدادات الأدوار',
  ACTIVE_NOTIFICATIONS: 'الإخطارات النشطة',
  SET_THIS_USER_AS_A_: 'قم بتعيين تفويض المستخدم والإعلامات كقالب جديد',
  REQUEST_AUTH: 'طلب تفويض',
  REQUEST: 'طلب',
  FEEDBACK_SUPPORT: 'ملاحظات ودعم',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'إذا كنت لا تزال غير قادر على العثور على ما تبحث عنه ، فاسأل فريق الدعم! أيضًا إذا كان لديك اقتراح للتحسينات المستقبلية فهذا هو المكان المناسب.',
  TEMPLATE: 'قالب',
  SIGNATURE: 'التوقيعات',
  Disclaimers: 'إخلاء المسؤولية',
  Greetings: 'تحية طيبة',
  CUSTOMIZE: 'يعدل أو يكيف',
  SAVE_UPPERCASE: 'حفظ',
  DELETE: 'حذف',
  RESET_TO_DEFAULT: 'إعادة تعيين إلى الافتراضي',
  AFTER_SIGNATURE: 'بعد التوقيع',
  BEFORE_SIGNATURE: 'قبل التوقيع',
  AFTER_ANSWER: 'بعد الجواب',
  BEFORE_ANSWER: 'قبل الإجابة',
  AFTER_GREET: 'بعد التحية',
  BEFORE_GREET: 'قبل التحية',
  Any: 'أي',
  HCP: 'HCP',
  NO_HCP: 'لا يوجد HCP',
  Position: 'موضع',
  ON_LABEL: 'على الملصق',
  OFF_LABEL: 'خارج التسمية',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'أنت تحاول تخصيص القالب',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'ليس لديك إذن للقيام بذلك. إذا كنت تريد المتابعة ، فسيتم إنشاء نسخة من القالب وستقوم بإجراء التغييرات هناك.',
  CREATE_COPY: 'إنشاء نسخة',
  RECEIVED_ON: 'وردت في',
  SENDER_EMAIL: 'البريد الإلكتروني المرسل',
  SUBJECT: 'موضوع',
  UNSPAM: 'غير مرغوب فيه',
  UPLOAD: 'رفع',
  APPROVED: 'وافق',
  DRAFT: 'مشروع',
  SELECT_STATUS: 'حدد الحالة',
  UPLOAD_AS: 'تحميل باسم',
  TITLE: 'عنوان',
  REPOSITORY: 'مخزن',
  BROWSE: 'تصفح',
  URL: 'URL',
  APPROVED_ON: 'تمت الموافقة عليه',
  AUTH_HOLDER: 'صاحب التفويض',
  AUTH_NUMBERS: 'أرقام التفويض',
  APPROVED_FOR: 'وافقت ل',
  SELECT_ONE_REGION: 'حدد منطقة واحدة',
  OWNED_BY: 'يملكها',
  KEYWORDS: 'الكلمات الدالة',
  TOPICS: 'المواضيع',
  CATEGORIES: 'التصنيفات',
  PRODUCTS: 'منتجات',
  PRODUCT: 'المنتج',
  LANGUAGE_IS_REQUIRED: 'اللغة مطلوبة',
  LANGUAGE: 'لغة',
  TARGET_CUSTOMERS: 'العملاء المستهدفين',
  TYPE_IS_REQUIRED: 'النوع مطلوب',
  TYPE: 'نوع',
  TITLE_IS_REQUIRED: 'العنوان مطلوب',
  DEFAULT_REFERENCE_IS_CREATED_:
    'يتم إنشاء المرجع الافتراضي باستخدام نمط فانكوفر. إذا كنت تريد تجاوز هذا ، فأدخل المرجع بالكامل هنا',
  REFERENCE: 'مرجع',
  DOI: 'دوى',
  PUBMED_ID: 'المعرّف المنشور',
  PAGES: 'الصفحات',
  ISSUE: 'القضية',
  VOLUME: 'الصوت',
  JOURNAL: 'مجلة',
  AUTHORS: 'المؤلفون',
  ABSTRACT: 'نبذة مختصرة',
  REFERENCE_IS_REQUIRED: 'المرجع مطلوب',
  THIS_DOC_IS_APPROVED: 'تمت الموافقة على هذا المستند. انقر هنا لفتح',
  ORIGINAL_DOCUMENT_AND_: 'المستند الأصلي وأدخل تعليقاتك',
  EDIT_FILE: 'تعديل ملف',
  PROPERTIES: 'الخصائص',
  OPEN: 'افتح',
  LOCALIZED_DOCUMENTS: 'وثائق مترجمة',
  ORIGINAL_DOCUMENTS: 'المستند الأصلي',
  INTERNAL_APPROVAL_NUMBER: 'رقم الموافقة الداخلية',
  CREATED_BY: 'انشأ من قبل',
  EXPIRATION_DATE: 'تاريخ الإنتهاء',
  VERSION_NUMBER: 'رقم الإصدار',
  NO_VERSION_AVAILABLE: 'لا يوجد إصدار متاح',
  ARCHIVED_IF_NO_DRAFT: 'مؤرشفة: في حالة عدم وجود نسخ مسودة / معتمدة.',
  APPROVED_FOR_USERS: 'موافق عليه: للمستخدمين.',
  DRAFT_FOR_AUTHORS_: 'مسودة: للمؤلفين والمحررين.',
  VERSION_LEGEND: 'أسطورة الإصدار',
  VERSION: 'الإصدار',
  SELECT_ONE_LANG: 'حدد لغة واحدة.',
  SELECT_ONE_TYPE: 'حدد نوع واحد.',
  INTERNAL_NUMBER_REQUIRED: 'مطلوب رقم الموافقة الداخلية.',
  FILE: 'ملف',
  ATTACH_FILE: 'أرفق ملف',
  LITERATURE: 'المؤلفات',
  SLIDE_DECK: 'سطح الشريحة',
  PIL: 'PIL',
  FAQ: 'التعليمات',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'أضف خطوة الموافقة',
  ADD_PARALLEL_STEP: 'أضف خطوة متوازية',
  DELETE_STEP: 'حذف الخطوة',
  DELETE_ALL_PARALLEL_STEPS: 'حذف جميع الخطوات المتوازية',
  CHANGE_STATUS_NAME: 'تغيير اسم الحالة',
  SET_REQUIRED_ROLE: 'قم بتعيين الدور المطلوب',
  SET_TEAM_MEMBER: 'تعيين عضو الفريق',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'ليس لديك أذونات لتغيير هذه العملية.',
  SAVE_ROLE: 'حفظ الدور',
  CREATE_NEW_WORKFLOW: 'إنشاء تدفق عمل جديد',
  CREATE_NEW_STEP: 'إنشاء خطوة جديدة',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'يُرجى إدخال عدد خطوات الموافقة الموازية ، أي الخطوات التي تمت الموافقة عليها في نفس الوقت (وليس بالتسلسل). إذا لم يكن لديك أي خطوات موافقة موازية ، فأدخل 1. يمكنك دائمًا تغيير عدد خطوات الموافقة لاحقًا.',
  APPROVAL_MUST_BE_AT_LEAST: 'يجب أن تكون الموافقة على الأقل',
  INSERT_THE_NEW_STATUS_NAME: 'أدخل اسم الحالة الجديد',
  INSERT_THE_NEW_WORKFLOW_NAME: 'أدخل اسم سير العمل الجديد.',
  INSERT_THE_NEW_STEP_NAME: 'أدخل اسم الخطوة الجديد.',
  NEW_DOCUMENT: 'مستند جديد',
  CREATE: 'خلق',
  DOCUMENT_MANAGEMENT: 'إدارة المستندات',
  CONFIRM: 'تؤكد',
  CONFIRM_PROCESS: 'عملية التأكيد',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'حدد خطوات الموافقة والفريق',
  SET_DETAILS: 'تعيين التفاصيل',
  SELECT_TYPE: 'اختر صنف',
  COMPLETE_LOCALIZATION_PROCESS: 'إكمال عملية التعريب',
  CREATE_NEW_VERSION: 'إنشاء إصدار جديد',
  CREATE_NEW_DOCUMENT: 'إنشاء مستند جديد',
  IS_PRIVATE: 'هو خاص',
  SHARED_WITH_OTHER_TEAMS: 'مشترك مع فرق أخرى',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'يرجى إدخال عدد خطوات الموافقة التسلسلية اللازمة. إذا كانت هناك موافقاتان بالتوازي ، فسيتم اعتبارها خطوة واحدة. يمكنك دائمًا تغيير عدد خطوات الموافقة لاحقًا.',
  DONE: 'منجز',
  SUBMIT_FOR_APPROVAL: 'تقدم للحصول على موافقة',
  YOU_HAVE_TO_CONFIRM_THE_: 'يجب عليك تأكيد العملية قبل التحميل.',
  SELECT_YOUR_DEC_TYPE: 'حدد نوع المستند الخاص بك',
  TARGET_AUDIENCE: 'الجمهور المستهدف',
  PROCESS_DETAILS: 'تفاصيل العملية',
  START_DATE: 'تاريخ البدء',
  DOC_DETAILS: 'تفاصيل الوثيقة',
  CONFIRMATION: 'التأكيد',
  APPROVAL_PROCESS: 'عملية الموافقة',
  SCHEDULED_EXP_DATE: 'تاريخ انتهاء الصلاحية المقرر',
  SCHEDULED_PUBLISHING_DATE: 'تاريخ النشر المجدول',
  NO_PROCESS_SELECTED: 'لم يتم تحديد عملية',
  COUNTRIES: 'بلدان',
  DOC_ID: 'معرف المستند',
  THERAPEUTIC_AREA: 'المنطقة العلاجية',
  FILTER: 'منقي',
  BACK: 'عودة',
  ATTACH: 'يربط',
  COPY_ANSWER: 'نسخ الإجابة',
  SIMILAR_INQUIRIES: 'استفسارات مماثلة',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'مجموعات الشرائح',
  MEDICAL_DOC: 'وثيقة طبية',
  SHOW: 'تبين',
  LOAD_MORE: 'تحميل المزيد',
  NO_RESULT_FOUND: 'لم يتم العثور على نتائج.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'يمكن استخدامها للبحث عن جملة كاملة ، - يمكن استخدامها للاستبعاد من نتائج البحث ضمن الكلمة المحددة',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'ابحث عن المستندات الطبية أو الاستفسارات المماثلة',
  SEARCH: 'بحث',
  DRAFT_VERSION: 'مسودة الإصدار',
  AVAILABLE_ACTIONS: 'الإجراءات المتاحة',
  OPEN_DOC: 'افتح المستند',
  UPLOAD_FILE: 'رفع ملف',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    'لا يزال المستند قيد الموافقة ، إذا كنت تريد تغيير محتوى المستند ، يمكنك فتح المستند في Sharepoint أو تحميل نسخة محدثة.',
  FINALIZE_LOCALIZATION: 'وضع اللمسات الأخيرة على التعريب',
  THIS_DOC_HAS_BEEN_LOCALIZED: 'تم ترجمة هذا المستند ، ويجب إنهاء العملية قبل الموافقة عليها.',
  Preview: 'معاينة',
  PREVIEW_AS_PDF: 'معاينة بتنسيق pdf',
  PUBLISHED_VERSION: 'الإصدار المنشور',
  NEW_VERSION: 'نسخة جديدة',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'لا يزال المستند قيد الموافقة ، إذا كنت تريد تغيير محتوى المستند ، فقم بتحميل ملف آخر.',
  OUT_TO: 'خارج الى',
  NEXT: 'التالى',
  SELECTED_SLIDES: 'شرائح مختارة',
  GO_TO_SLIDE: 'انتقل إلى الشريحة',
  PREV: 'السابق',
  SELECT_SLIDE: 'حدد شريحة',
  ANSWER: 'إجابة',
  BACK_TO_STANDARD_VIEW: 'العودة إلى العرض القياسي.',
  UPLOAD_NEW_FILE: 'تحميل ملف جديد',
  CREATE_FILE: 'إنشاء ملف',
  EDITING_WORD_DOC: 'تحرير مستند Word',
  INSERT_FILE_NAME: 'أدخل اسم الملف',
  PROCEED_WITH_THE_LINK_: 'تابع ارتباط الملف',
  A_NEW_WINDOW_WILL_BE_: 'سيتم فتح نافذة جديدة بملف فارغ ، وكتابة المحتوى وإغلاق علامة التبويب',
  INSERT_THE_NAME_FOR_: 'أدخل اسم المرفق الذي تريد إنشاءه وتابع إنشاء الملف',
  INSTRUCTIONS: 'تعليمات',
  CREATE_NEW_WORD_FILE_FOR: 'إنشاء ملف Word جديد لـ',
  FROM: 'من عند',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'يمكنك أن تجد هنا آخر الاستفسارات التي أدخلتها في النظام سواء التي أرسلتها أو لم ترسلها بعد. يمكنك إعادة استخدام الاستعلامات التي قمت بإدخالها لإنشاء استفسارات أكثر مماثلة أو العودة إلى الاستفسارات التي لم تنته من إدراجها.',
  MY_DRAFT_INQ: 'مسوداتي الاستفسارات',
  WARNING_CHANGES_NOT_SAVED: 'تحذير: لم يتم حفظ التغييرات',
  PAGE_THAT_WILL_BE_LOST: 'الصفحة التي سيتم فقدانها إذا قررت المتابعة.',
  YOU_HAVE_UNUSED: 'لديك تغييرات غير محفوظة في',
  ARE_YOU_SURE_YOU_WANT_TO_: 'هل أنت متأكد أنك تريد مغادرة هذه الصفحة بدون حفظ؟',
  LEAVE_THIS_PAGE: 'غادر هذه الصفحة',
  STAY_ON: 'ابقي مركزا',
  ASSIGNED_TO: 'مخصص ل',
  THIS_INQ_HAS_BEEND_INSERTED_: 'تم إدراج هذا الاستفسار خلال الحدث.',
  STATUS_IN_APPROVAL: 'الحالة: قيد الموافقة',
  THE_RESPONSIBLE_FOR_THE_ANSWER_: 'يجب أن يقبل المسؤول عن الموافقة على الإجابة الإجابة.',
  STATUS_DRAFT: 'الحالة: مسودة',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'إذا كنت ترغب في إنشاء الاستفسار الفعلي ، فانتقل إلى علامة التبويب إرسال وانقر على زر إنشاء.',
  INQ_IMPORTED_FROM: 'استعلام مستورد من',
  SEND_NEW_ANSWER: 'إرسال إجابة جديدة',
  RETURN_TO_INBOX: 'العودة إلى البريد الوارد',
  SUBMITTED_FOR_APPROVAL: 'المقدمة للموافقة عليها',
  ANSWER_SUBMITTED_ON: 'تم إرسال الإجابة في',
  BY: 'بواسطة',
  INQ_HAS_BEEN_CLOSED: 'تم إغلاق التحقيق دون إجابة.',
  BCC: 'نسخة مخفية الوجهة',
  ANSWER_GIVEN_ON: 'الإجابة على',
  ANSWER_SENT_ON: 'تم إرسال الإجابة',
  INBOX: 'صندوق الوارد',
  OPEN_ORIGINAL_INQ: 'افتح الاستفسار الأصلي',
  CLOSE_ANSWER_NOT_NEEDED: 'إغلاق: Anser غير مطلوب',
  HISTORY: 'التاريخ',
  ADD_A_NOTE: 'أضف ملاحظة',
  ADD_A_NOTE_INTO_: 'أضف ملاحظة إلى تاريخ الاستفسار.',
  SHARE_OR_START_A_FOLLOW_UP: 'شارك أو ابدأ المتابعة',
  SEE_ALL_INQ_RELATED_TO: 'انظر جميع الاستفسارات المتعلقة',
  SRD_NEEDED: 'مطلوب SRD',
  ANSWER_UNAVAILABLE: 'الجواب غير متاح',
  QUESTION: 'سؤال',
  SUBMIT: 'إرسال',
  ANSWER_UPPERCASE: 'إجابة',
  DETAILS: 'تفاصيل',
  SPLIT_INQ: 'استعلام سبليت',
  ADD_QUESTION: 'أضف سؤال',
  ENTER_QUESTION: 'أدخل السؤال',
  MARK_AS_ANSWERED: 'وضع علامة "تمت الإجابة عليها"',
  TO_BE_ANSWERED: 'للرد',
  FOLLOW_UP_RECEIVED: 'تم استلام المتابعة',
  ERROR: 'خطأ',
  SHOW_ORIGINAL_MESSAGE: 'إظهار الرسالة الأصلية',
  SEND: 'إرسال',
  DO_NO_SEND: 'لا ترسل',
  OK: 'حسنا',
  MAX_LENGTH_FOR_ZIP_CODE: 'أقصى طول للرمز البريدي هو',
  CHARACTERS: 'الشخصيات',
  ADDRESS_1_IS_: 'العنوان 1 هو',
  LABEL_IS: 'التسمية هي',
  NO_QUESTION_INSERTED: 'لم يتم إدراج أي سؤال',
  WARNING: 'تحذير',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'المتابعة غير متاحة لهذا التفاعل.',
  INSERT_INQ_TEXT: 'أدخل نص الاستفسار',
  SHOW_MORE: 'أظهر المزيد',
  OUT_OF: 'بعيدا عن المكان',
  INQ_TO_BE_SENT: 'يتم إرسال الاستفسارات',
  HIDE_INQ: 'إخفاء الاستفسارات',
  SHARE: 'شارك',
  HCP_PROFILE_OF: 'ملف HCP الخاص بـ',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'املأ جميع الحقول الإلزامية أدناه وعنوان بريد إلكتروني واحد على الأقل ثم احفظ لمعاينة إشعار استلام القانون العام لحماية البيانات.',
  CONFIRMATION_SENT_BY: 'تم إرسال الإخطار من قبل',
  COUNTRY_IS: 'البلد',
  COUNTRY: 'بلد',
  AT_LEAST_ONE_CONTACT_IS_: 'جهة اتصال واحدة على الأقل',
  EMAIL_IS: 'البريد الإلكتروني هو',
  EMAIL_LABEL_IS: 'تسمية البريد الإلكتروني هو',
  PHONE_FAX_IS: 'تسمية الهاتف / الفاكس',
  PHONE_FAX_NUMBER_IS: 'رقم الهاتف / الفاكس هو',
  ADDRESS: 'عنوان',
  DELETE_PERSONAL_DATA: 'حذف البيانات الشخصية',
  HCP_VERIFIED: 'تم التحقق من HCP',
  KEY_OPINION_LEADER: 'قادة الرأي الرئيسيين',
  HOSPITAL_BASED: 'مقر المستشفى',
  HCP_RELATED_INFORMATION: 'معلومات ذات صلة HCP',
  ACCEPT: 'قبول',
  REJECT: 'رفض',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'هل تريد قبول هذه الاقتراحات؟',
  SALES_REP: 'مندول المبيعات',
  SUGGEST_A_TOPIC: 'اقتراح موضوع',
  TEAM_SPECIFIC_FIELDS: 'المجالات المحددة للفريق',
  ADR_RELATED: 'ADR ذات الصلة',
  NOT_ADR_RELATED: 'ليست ذات صلة ADR',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'يرجى مراجعة الرسالة وتصنيفها على أنها ذات صلة برد الفعل المعاكس للأدوية أم لا.',
  ADVERSE_DRUG_REACTION: 'تاثير العقار السلبي',
  CHANGE_DETAILS: 'تفاصيل التغيير',
  YES: 'نعم',
  NO: 'لا',
  DUE_DATE: 'تاريخ الاستحقاق',
  CRITICAL: 'حرج',
  NORMAL: 'عادي',
  PRIORITY: 'أفضلية',
  SELECT_DETAILS: 'حدد التفاصيل',
  SAVE_REVIEW: 'حفظ المراجعة',
  SET_TO_ALL_INQ: 'تعيين لجميع الاستفسارات',
  PRODUCT_QUALITY_COMPLAINT: 'شكوى جودة المنتج',
  PQC_PC_A_PRODUCT_:
    'PQC / PC يتم تعريف شكوى جودة المنتج (QC) أو المشكلة على أنها أي مخاوف تنطوي على عيب أو خلل في أي منتج دوائي أو فيما يتعلق بجودة أي منتج دوائي وأدائه وسلامته ، على سبيل المثال تغيير الرائحة و / أو الذوق ، التغليف السيئ أو المعيب ، التلوث المشتبه به ، إلخ.',
  OFF_LABEL_USE_OF_PHARMA___:
    'استخدام العقاقير الصيدلانية خارج التسمية لإشارة غير مصرح بها أو في فئة عمرية غير معتمدة أو جرعة أو طريق الإعطاء.',
  A_RESPONSE_TO_A_MEDICINAL_:
    'إن الاستجابة لمنتج طبي ضار وغير مقصود والعلاقة السببية بين منتج طبي وحدث ضار هي على الأقل احتمال معقول. يجب أيضًا تمييز حالات الحالات الخاصة (مثل حالات الحمل ، خارج استخدام الملصقات ، الجرعة الزائدة) على أنها ADR.',
  NORMAL_IS_7_WORKING_DAYS: 'عادي 7 أيام عمل ، ومرتفع 48 ساعة وحرج (متعلق بالمريض) 24 ساعة',
  CATEGORY_AND_TOPIC_ARE: 'الفئة والموضوع',
  PRODUCT_IS: 'المنتج',
  DO_YOU_WANT_TO_ACCEPT_: 'هل تريد قبول هذه الاقتراحات؟',
  MERGE: 'دمج',
  Duplicate: 'مكرر',
  Other: 'آخر',
  Spam: 'بريد مؤذي',
  CLOSE_INTERACTION: 'تفاعل وثيق',
  RE_OPEN_THE_TARGET_: 'أعد فتح التحقيق الهدف بعد الدمج',
  MARK_AS_INTERNAL_: 'وضع علامة كرسالة داخلية للاستعلام الحالي.',
  MARK_AS_AN_ERROR_: 'وضع علامة كرسالة خطأ للاستعلام الحالي.',
  THIS_MESSAGE_WILL_BE_EXISTING_: 'سيتم وضع علامة على هذه الرسالة كرد متابعة لاستفسار موجود.',
  THANK_YOU: 'شكرا لكم',
  AN_INTERNAL_MESSAGE: 'رسالة داخلية',
  AN_ERROR_MESSAGE: 'رسالة خطأ',
  NEW_INQ: 'استفسار جديد',
  A_FOLLOW_UP_RESPONSE: 'استجابة متابعة',
  THIS_INQ_IS: 'هذا الاستفسار',
  SPLIT_INTERACTION: 'انقسام التفاعل',
  SAVE_AND_CLOSE: 'احفظ وأغلق',
  SAVE_INQ: 'حفظ استفسار',
  REVIEW_THE_TEXT_AND: 'راجع النص والتصنيف وأكد عليه',
  CHOOSE_THE_RELEVANT_PRODUCT: 'اختر المنتج والفئة والموضوع ذي الصلة للنص المحدد',
  SELECT_THE_TEXT_THAT_:
    'حدد النص الذي يحدد السؤال حول منتج / موضوع واحد (لا تقم بتضمين التحيات أو التوقيعات أو إخلاء المسؤولية أو معلومات أخرى غير ذات صلة)',
  REPEAT_THE_PREVIOUS_: 'كرر الخطوات السابقة لإضافة المزيد من الاستفسارات',
  READ_MORE: 'قراءة المزيد',
  READ_LESS: 'أقرأ أقل',
  DO_NOT_INCLUDE_:
    'لا تقم بتضمين التحيات أو التوقيعات أو إخلاء المسؤولية أو معلومات أخرى غير ذات صلة',
  SELECT_THE_TEXT_THAT_IDENT: 'حدد النص الذي يحدد السؤال حول منتج / موضوع واحد',
  IF_YOU_HAVE_MULTIPLE_: 'إذا كان لديك منتجات أو مواضيع متعددة ، فيجب تقسيم الاستفسار.',
  THE_FOLLOWING_PROCESS_: 'ستساعدك العملية التالية في تحديد النص ذي الصلة لكل منتج / موضوع: ',
  EXPORT_TO_PDF: 'تصدير إلى PDF',
  Added: 'تمت الإضافة',
  Deleted: 'تم الحذف',
  From: 'من عند',
  To: 'إلى',
  BACK_TO_INBOX: 'الرجوع إلى البريد الوارد',
  PERMISSION_DENIED: 'طلب الاذن مرفوض',
  THIS_INQ_IS_LOCKED_BY: 'تم تأمين هذا الاستفسار',
  FORCE_UNLOCK: 'فتح القوة',
  INQUIRIES: 'استفسارات',
  Download: 'تحميل',
  Event: 'حدث',
  INSERTED_BY: 'تم الإدراج بواسطة',
  LAST_EDITED_ON: 'التعديل الأخير بتاريخ',
  LAST_EDITED_BY: 'التعديل الأخير تم بواسطة',
  CREATED_ON: 'تم إنشاؤها على',
  TOPIC: 'موضوع',
  TABLE_OPTIONS: 'خيارات الجدول',
  DEPARTMENT: 'قسم',
  User: 'المستعمل',
  ADD_THERAPEUTIC_AREA: 'أضف منطقة علاجية',
  CREATE_THERAPEUTIC_AREA: 'إنشاء منطقة علاجية جديدة',
  ADD_NEW: 'اضف جديد',
  SELECT_TEAM_TO_PROCEED: 'حدد الفريق للمتابعة',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'مبيعات',
  CALL_CENTER: 'مركز الاتصال',
  ALL: 'الكل',
  Warning: 'تحذير',
  clear: 'واضح',
  THESE_CHANGES_WILL_BE_: 'سيتم تطبيق هذه التغييرات على المستخدمين المحددين',
  INSERT_A_FOLLOW_UP_RESP: 'أدخل استجابة متابعة',
  INSERT_A_NOTE: 'أدخل ملاحظة',
  MERGED_FROM: 'اندمجت من',
  CHANGES: 'التغييرات',
  SHOW_LESS: 'تظهر أقل',
  COMPLETE: 'اكتمال',
  MISSING: 'مفقود',
  CLOSE_AS_FORWARDED: 'الإغلاق مثل المعاد توجيهه',
  CLOSE_EXT_TEAM: 'أغلق الاستفسار كما تم توجيهه إلى فريق خارجي',
  THE_INQ_BELONG_TO_A_:
    'هذا الاستفسار يخص دولة تقع على عاتق فريق خارجي. سيتم إرسال الاستفسار إلى هذا الفريق تلقائيًا.',
  RESOLVE: 'حل',
  TERRITORIES: 'إقليم',
  EXTERNAL_COMMUNICATION: 'التواصل الخارجي',
  FOR_INQ_COMING_DIRECTLY_:
    'للاستفسارات الواردة مباشرة من HCP عبر البريد الإلكتروني ، أرسل إشعارًا تلقائيًا لإبلاغه بأنه تم استلام الاستفسار.',
  INTERNAL_COMMUNICATION: 'الاتصالات الداخلية',
  SALESREP_RECEIVES_: 'يتلقى Salesrep الإخطارات التي تم الرد على الاستفسار فقط',
  SALESREP_RECEIVES_FULL_ANSWER_: 'يتلقى Salesrep إجابة كاملة عن استعلامات On Label',
  SALESREP_RECEIVES_ONLY_NOTIF_: 'يتلقى Salesrep الإخطارات التي تم الرد على الاستفسار فقط',
  Name: 'اسم',
  Module: 'وحدة',
  Function: 'وظيفة',
  LAST_RUN: 'التشغيل الأخير',
  End: 'النهاية',
  Every: 'كل',
  Period: 'فترة',
  Active: 'نشيط',
  Resolved: 'تم الحل',
  ERROR_PREVIEW: 'معاينة الخطأ',
  FAILURE_COUNT: 'عدد الفشل',
  COMPLETED_ON: 'اكتمل في',
  STARTED_ON: 'بدأ في',
  TASK_NAME: 'اسم المهمة',
  PRODUCT_SPECIFIC: 'منتج محدد',
  THERAPEUTIC_AREAS: 'المناطق العلاجية',
  PRODUCT_GROUPS: 'مجموعات المنتجات',
  LICENSING_PARTNER: 'شريك الترخيص',
  PRODUCT_CODE: 'كود المنتج',
  EXTERNAL_ID: 'معرف خارجي',
  OBJECT_TYPE: 'نوع الكائن',
  MIGRATION_NAME: 'اسم الهجرة',
  MIGRATION_ERRORS: 'أخطاء الترحيل',
  ADD_SALES_REP: 'إضافة مندوب مبيعات',
  ASSIGNED_TO_MSL: 'مخصص لـ MSL',
  NOT_ASSIGNED: 'غيرمعتمد',
  ASSIGNED_TO_OTHER_MSL: 'مخصص لـ MSL أخرى',
  ASSIGNED_TO_THIS_MSL: 'مخصص لـ MSL',
  SHOW_ONLY: 'أظهر فقط',
  SALES_REP_LIST: 'قائمة مندوب المبيعات',
  ACTIVE_TASK: 'تنشيط المهمة',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'اختر المستخدم وقم بتعيين التحول لهذا الحدث',
  MANAGE_SHIFTS: 'إدارة التحولات',
  CREATE_NEW_USER: 'إنشاء مستخدم جديد',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'ستتم إضافة هذا القالب تلقائيًا إلى عضو فريقك.',
  TEMPLATE_FOR: 'قالب',
  Day: 'يوم',
  Week: 'أسبوع',
  Month: 'شهر',
  Today: 'اليوم',
  Archive: 'أرشيف',
  PARTICIPANTS: 'المشاركين',
  EVENT_MANAGEMENT: 'أدارة الحدث',
  ADD_TASK: 'إضافة مهمة',
  EDIT_TASK: 'تحرير المهمة',
  IS_ACTIVE: 'نشط',
  Months: 'الشهور',
  Days: 'أيام',
  Hours: 'ساعات',
  Minutes: 'الدقائق',
  HCP_ADMINISTRATION: 'إدارة HCP',
  AUTH_REQ_ADMINISTRATION: 'إدارة طلبات المصادقة',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: 'سيتم إبراز المنتج في المجالات العلاجية المختارة.',
  PRODUCT_GROUPS_DEFINE_: 'تحدد مجموعات المنتجات الفريق المسؤول عن الرد على استفسارات هذا المنتج.',
  USER_ADMINISTRATION: 'إدارة المستخدمين',
  IS_PRODUCT_SPECIFIC: 'هو منتج محدد',
  USER_TEMPLATES_ADMIN: 'إدارة قوالب المستخدم',
  SET_DEFAULT_VALUES: 'تعيين القيم الافتراضية',
  SET_DEFAULT_VALUES_FOR_: 'قم بتعيين القيم الافتراضية للحدث',
  INQUIRY_DEFAULT_VAL: 'قيم الاستفسار الافتراضية',
  CREATE_NEW_EVENT: 'إنشاء حدث جديد',
  EDIT_EVENT: 'تعديل الحدث',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'قم بتعيين التفاصيل لإنشاء حدث جديد',
  ADD_DOC_LINKED_TO_: 'أضف المستندات المرتبطة بالحدث',
  INC_CHANNEL: 'قناة إنك',
  CHANGE_DETAILS_OF_THE_EVENT: 'قم بتغيير تفاصيل الحدث',
  DESCRIPTION: 'وصف',
  CREATE_APPROVAL_PROCESS: 'اختر عملية الموافقة',
  CREATE_NEW: 'خلق جديد إبداع جديد',
  CUSTOMIZE_PROCESS: 'تخصيص العملية',
  SELECTED_PROCESS: 'عملية مختارة',
  CLONE: 'استنساخ',
  PREFERENCES_TITLE: 'التفضيلات',
  NOTIFICATIONS_TITLE: 'إشعارات',
  TEMPLATES: 'قوالب',
  LETTER_TEMPLATES: 'قالب الرسالة',
  USER_TEMPLATES: 'قوالب المستخدم',
  TEAM_MEMBERS: 'أعضاء الفريق',
  NEW_PASSWORD: 'كلمة سر جديدة',
  REPEAT_NEW_PASSWORD: 'كرر كلمة المرور الجديدة',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'حرف واحد صغير على الأقل',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'حرف واحد كبير على الأقل',
  AT_LEAST_1_DIGIT: 'رقم واحد على الأقل',
  AT_LEAST_1_SPECIAL_CHARACTER: 'حرف خاص واحد على الأقل',
  MINIMUM_8_CHARACTERS: '8 أحرف على الأقل',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'يجب ألا يحتوي على بريد إلكتروني أو أسماء',
  SHOULD_MATCH_PASSWORD: 'يجب أن تتطابق مع كلمة المرور',
  USER_MANUAL: 'دليل الاستخدام',
  SUPPORT: 'الدعم',
  BACK_TO_LOGIN: 'العودة إلى تسجيل الدخول',
  PASSWORD_CONFIRMATION: 'تأكيد كلمة المرور',
  HAVE_AN_ACCOUNT: 'هل لديك حساب بالفعل؟',
  Submit: 'إرسال',
  Gender: 'جنس',
  PROFESSION: 'مهنة',
  MEDICAL_INQ_ROLES: 'دور معالجة الاستفسارات الطبية',
  MEDICAL_INFO_MANAGER: 'مدير المعلومات الطبية',
  MEDICAL_SCIENCE_LIAISON: 'متخصص العلوم الطبية',
  NOT_PROCESSING_INQUIRIES: 'عدم معالجة الاستفسارات',
  ACTIVE_HEALTH_CARE_SPEC: 'أخصائي رعاية صحية نشط',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'ليس أخصائي رعاية صحية نشط',
  PASS_IS_REQUIRED: 'كلمة المرور مطلوبة!',
  EMAIL_IS_REQUIRED: 'البريد الالكتروني مطلوب!',
  VALID_EMAIL: 'من فضلك أدخل بريد أليكترونى صحيح!',
  FIELD_IS_REQUIRED: 'هذه الخانة مطلوبه!',
  ONLY_LETTERS: 'الرجاء إدخال الحروف فقط!',
  ARE_YOU_daiichi_EMPLOYEE: 'هل أنت موظف في Daiichi Sankyo؟',
  here: 'هنا',
  CREATE_AN_ACCOUNT: 'انشئ حساب',
  FORGOT: 'نسيت؟',
  Medical: 'طبي',
  Medical_not_resp: 'طبي - غير مسؤول عن الاستجابة لمتطلبات MIR',
  Commercial: 'تجاري',
  Market_access: 'الوصول إلى السوق',
  Pharmacovigilance: 'اليقظة الدوائية',
  PR: 'العلاقات العامة',
  Please_specify: 'رجاء حدد',
  Medical_information_manager: 'مدير المعلومات الطبية',
  Medical_advisor: 'المستشار الطبي',
  Are_Medical_inquiries: 'هل أنت مسؤول عن الإجابة على الاستفسارات الطبية؟',
  Are_Health_care_professional: 'هل أنت محترف رعاية صحية ممارس؟',
  Health_care_Industry: 'قطاع الرعاية الصحية',
  Health_Authority: 'هيئة الصحة',
  Journalist: 'صحافي',
  Lawyer: 'محامي',
  Patient: 'صبور',
  Payor: 'دافع',
  Scientist: 'عالم',
  Angiologist: 'طبيب الأوعية الدموية',
  Cardiologist: 'طبيب القلب',
  Cardiovascular_Surgeon: 'جراح القلب والأوعية الدموية',
  Dentist: 'دكتورالاسنان',
  Gastroenterologist: 'طبيب الجهاز الهضمي',
  General_Practitioner: 'طبيب عام',
  Geriatrician: 'طب الشيخوخة',
  Gynecologist: 'دكتور امراض نساء',
  Haematologist: 'طبيب أمراض الدم',
  Internist: 'طبيب باطني',
  Medical_Student: 'طالب طب',
  Nephrologist: 'طبيب أمراض الكلى',
  Neurologist: 'طبيب أعصاب',
  Neurosurgeon: 'جراح الأعصاب',
  Nurse: 'ممرضة',
  Oncologist: 'طبيب الأورام',
  Oncology_Nurse: 'ممرضة علم الأورام',
  Other_HCP: 'HCP أخرى',
  Other_Surgeon: 'جراح آخر',
  Pediatrician: 'طبيب الأطفال',
  Pharmacist: 'صيدلاني',
  Pharmacologist: 'صيدلي',
  Pharmacy_Technician: 'فني صيدلة',
  Pneumologist: 'طبيب أمراض الرئة',
  Radiologist: 'أخصائي أشعة',
  Rheumatologist: 'طبيب روماتيزم',
  USER_PASS_INCORRECT: 'اسم المستخدم أو كلمة المرور غير صحيحة',
  SUBMITTED_REQUEST: 'تم إرسال طلبك بنجاح.',
  Reset: 'إعادة تعيين',
  Reset_pass: 'إعادة تعيين كلمة المرور',
  New_pass: 'كلمة سر جديدة',
  RESET_PASS_EMAIL: 'تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني',
  NEW_PASS_SUCCESS: 'تم إعادة تعيين كلمة المرور الخاصة بك بنجاح',
  EVENTS: 'الأحداث',
  Login_here: 'تسجيل الدخول هنا',
  REGISTRATION: 'التسجيل',
  Create_an_account: 'انشئ حساب',
  NO_RECORDS: 'لا تسجيلات',
  INTERACTION: 'التفاعل',
  SPECIALIZATION: 'تخصص',
  SPECIALIZATION_TYPE: 'نوع التخصص',
  SAVED: 'تم الحفظ',
  SAVING: 'إنقاذ',
  TYPING: 'الكتابة',
  CREATE_NEW_INTERACTION: 'إنشاء تفاعل جديد',
  FILTER_BY: 'مصنف بواسطة',
  SEND_EMAIL: 'ارسل بريد الكتروني',
  SAVE_WITHOUT_EMAIL: 'حفظ بدون بريد إلكتروني',
  SUBMIT_TO_INBOX: 'إرسال إلى Inbox',
  Create_my_acc: 'إنشيء حسابي',
  NEW_QUESTION: 'سؤال جديد',
  LABEL: 'ضع الكلمة المناسبة',
  SELECT_A_LABEL: 'حدد تسمية',
  CITY: 'مدينة',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'اقتراح فئة جديدة أو حدد فئة موجودة',
  SUGGEST_CATEGORY_OR_TOPIC: 'اقتراح فئة أو موضوع جديد',
  URGENT: 'العاجلة',
  EXISTING_CATEGORY: 'الفئة الحالية',
  NEW_CATEGORY_NAME: 'اسم الفئة الجديدة',
  NEW_TOPIC_NAME: 'اسم موضوع جديد',
  CATEGORY: 'الفئة',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'القناة الصادرة',
  LOGIN_TO_CONTINUE: 'تسجيل الدخول للمتابعة إلى Madjenta',
  Email_address: 'عنوان البريد الالكترونى',
  FORGOT_PASS: 'هل نسيت كلمة المرور؟',
  Remember_me: 'تذكرنى',
  YOU_WILL_BE_NOTIFIED: 'تم إرسال بريد إلكتروني للتأكيد مع تعليمات إضافية لتسجيلك',
  SUCCESS_EMAIL_CONFIRMATION: 'تم تأكيد بريدك الإلكتروني بنجاح.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'سيتم إعلامك عند مراجعة طلبك',
  FAILED_EMAIL_CONFIRMATION: 'لا يمكننا تأكيد بريدك الإلكتروني.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'يُرجى إعادة المحاولة ، وإذا استمرت المشكلة في الكتابة لدعم مع وصف المشكلة',
  RESET_FILTERS: 'إعادة تعيين الفلاتر',
  NEW_PASS_RESET_SUCCESS: 'تمت إعادة تعيين كلمة المرور بنجاح!',
  SOMETHING_WENT_WRONG: 'حدث خطأ ، يرجى الاتصال بالدعم.',
  Cardiovascular: 'القلب والأوعية الدموية',
  Oncology: 'علم الأورام',
  Due_On: 'مستحق',
  Follow_Up_Sent_On: 'المتابعة المرسلة',
  Follow_Up_Received_On: 'تم استلام المتابعة بتاريخ',
  Closed_On: 'أغلقت يوم',
  Reopened_On: 'أعيد فتحه',
  Inquiry_ID: 'معرف الاستعلام',
  Search: 'بحث',
  Assigned_to_Now: 'معين لـ (الآن)',
  Edited_By: 'حررت بواسطة',
  Assigned_to_Any_Time: 'تم التعيين إلى (في أي وقت)',
  Closed: 'مغلق',
  Foreign: 'أجنبي',
  Complex_search_Sentence_: 'بحث معقد: جملة: "جملة للبحث" ، ليس: -الكلمة',
  Search_inquiries: 'استفسارات البحث',
  SEARCH_REPOSITORY: 'مستودع البحث',
  MIR_INBOX: 'البريد الوارد',
  Filters: 'مرشحات',
  Active_Filters: 'مرشحات نشطة',
  CONTACT_SUPPORT: 'اتصل بالدعم',
  MedicalInformationRequests: 'استفسارات (pdf)',
  UserProfileNavigation: 'ملف تعريف المستخدم (pdf)',
  UserManualChanges: 'تغييرات دليل المستخدم (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'تعيين الاستفسار',
  MedIS_Documentation_Approval_Process: 'عملية الموافقة على الوثائق',
  MedIS_Documentation_Overview: 'نظرة عامة على التوثيق',
  MedIS_Inquiry_Documentation: 'توثيق الاستفسار',
  MedIS_MIR_Inbox_Filters: 'مرشحات صندوق البريد MIR',
  MedIS_Merge_MIR: 'دمج مير',
  MedIS_New_Documentation: 'وثائق جديدة',
  MedIS_New_Inquiry: 'استفسار جديد',
  MedIS_Out_Of_Office: 'خارج المكتب',
  MedIS_Prepare_Custom_Answer: 'إعداد إجابة مخصصة',
  MedIS_Split_MIR_Part_I: 'انقسام MIR الجزء الأول',
  MedIS_Split_MIR_Part_II: 'انقسام MIR الجزء الثاني',
  I_AGREE_TO: 'أنا موافق',
  TermsAndConditionsLink: 'الأحكام والشروط',
  Title: 'عنوان',
  INTERACTION_TITLE: 'عنوان التفاعل',
  Move: 'نقل',
  Left: 'اليسار',
  Right: 'حق',
  Requestor: 'الطالب',
  Assigned_To: 'مخصص ل',
  Status: 'الحالة',
  Note: 'ملحوظة',
  Confirm: 'تؤكد',
  rejectConfirm: 'هل أنت متأكد أنك تريد رفض الطلب؟',
  approveConfirm: 'هل أنت متأكد أنك تريد الموافقة على الطلب؟',
  newUser: 'مستخدم جديد',
  editUser: 'تحرير العضو',
  deleteUser: 'مسح المستخدم',
  newProduct: 'منتج جديد',
  editProduct: 'تحرير المنتج',
  deleteProduct: 'حذف المنتج',
  newCategory: 'فئة جديدة',
  editCategory: 'تحرير الفئة',
  deleteCategory: 'حذف الفئة',
  newTopic: 'موضوع جديد',
  editTopic: 'تحرير الموضوع',
  deleteTopic: 'حذف الموضوع',
  userRequest: 'طلب المستخدم',
  productRequest: 'طلب المنتج',
  categoryRequest: 'طلب فئة',
  categoryTopicRequest: 'طلب فئة / موضوع',
  topicRequest: 'طلب موضوع',
  Suggestion: 'اقتراح',
  tradeName: 'اسم تجاري',
  activeSubstance: 'المادة الفعالة',
  productName: 'اسم المنتج',
  productSpecific: 'هل هو منتج محدد؟',
  EMAIL_IN_USE: 'يوجد حساب موجود مع هذا البريد الإلكتروني',
  fromDate: 'من التاريخ',
  toDate: 'حتى الآن',
  applyFilter: 'تطبيق عامل التصفية',
  requestReceivedFrom: 'تم استلام الطلب من',
  on: 'على',
  at: 'في',
  to: 'إلى',
  add: 'أضف',
  from: 'من عند',
  approve: 'يوافق',
  reject: 'رفض',
  withTheFollowingRole: 'مع الدور التالي',
  forTheFollowingTeam: 'للفريق التالي',
  request: 'طلب',
  submittedBy: 'مقدم من',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'طلب إعادة تعيين كلمة المرور غير صالح أو منتهي الصلاحية',
  PASS_IS_RECENT: 'تم استخدام كلمة المرور مؤخرًا',
  Assigned_to_me: 'تعيين لي',
  My_drafts: 'مسوداتي',
  My_open_inquiries: 'استفساراتي المفتوحة',
  My_closed_inquiries: 'استفساراتي المغلقة',
  Filter_inquiries_by: 'تصفية الاستفسارات حسب',
  New_inq: 'استفسار جديد',
  Inq_inbox: 'استفسارات صندوق الوارد',
  REPORTS: 'التقارير',
  My_teams_open_inq: 'استفسارات فريقي المفتوحة',
  My_teams_closed_inq: 'استفسارات فريقي المغلقة',
  All_teams_open_inq: 'جميع الفرق تفتح الاستفسارات',
  All_teams_closed_inq: 'جميع الفرق مغلقة الاستفسارات',
  Overdue: 'تأخر',
  Due_this_week: 'مستحق هذا الأسبوع',
  Pending_approval: 'ما زال يحتاج بتصدير',
  ADMINISTRATION: 'الادارة',
  Show_all: 'عرض الكل',
  In_progress: 'في تقدم',
  Table_Settings_Columns: 'إعدادات الجدول - الأعمدة',
  Change_column_visibility: 'تغيير رؤية العمود',
  Reassign_to: 'إعادة تعيين إلى',
  set_priority: 'يضع أولويات',
  set_status: 'تعيين الحالة',
  Spam_list: 'قائمة البريد العشوائي',
  Institution: 'المعهد',
  DUE_ON: 'مستحق في',
  Profile_settings: 'إعدادات الملف الشخصي',
  Preferences: 'التفضيلات',
  Role_settings: 'إعدادات الدور',
  Notification_settings: 'إعدادات الإشعار',
  User_profile: 'ملف تعريفي للمستخدم',
  Select_person: 'حدد الشخص',
  FROM_DATE: 'من التاريخ',
  TO_DATE: 'حتى الآن',
  JOB_DESC: 'المسمى الوظيفي',
  INQ_Management: 'إدارة الاستفسار',
  DOCS_Management: 'إدارة المستندات',
  USER_Management: 'إدارةالمستخدم',
  Define_Frequency: 'تحديد التردد',
  All_teams_all_inq: 'جميع الاستفسارات لجميع الفرق',
  My_teams_all_inq: 'جميع الاستفسارات الخاصة بفريقي',
  Search_people_here: 'ابحث عن الناس هنا',
  Search_team_here: 'فريق البحث هنا',
  people: 'اشخاص',
  teams: 'فرق',
  empty: '(فارغة)',
  can_not_be_assigned: 'هذا المستخدم ليس لديه فريق ولا يمكن تعيينه',
  select_a_team: 'الرجاء اختيار فريق ل',
  confirm_team_change: 'تأكيد تغيير الفريق',
  change_the_team_: 'هل أنت متأكد أنك تريد تغيير الفريق إلى',
  can_not_see_it_anymore: 'لن تتمكن من رؤيته في التيار',
  filtered_view: 'عرض مصفى.',
  PHONE: 'هاتف',
  FAX: 'فاكس',
  LETTER: 'رسالة',
  F2F_SITE_VISIT: 'F2F (زيارة الموقع)',
  F2F_CONGRESS: 'F2F (الكونجرس)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (أخرى)',
  WEBINAR_SITE_VISIT: 'الظاهري (زيارة الموقع)',
  WEBINAR_CONGRESS: 'الظاهري (الكونغرس)',
  WEBINAR_ADBOARD: 'الظاهري (AdBoard)',
  WEBINAR_OTHER: 'افتراضي (أخرى)',
  CHAT: 'دردشة',
  COURIER: 'التوصيل',
  REP_DELIVERY: 'مندوب التسليم',
  SYSTEM: 'النظام',
  WEB_FORM: 'نموذج ويب',
  Signature: 'التوقيع',
  Disclaimer: 'تنصل',
  STATE: 'حالة',
  ADDRESS_1: 'العنوان 1',
  ADDRESS_2: 'العنوان 2',
  ZIP_CODE: 'الرمز البريدي',
  HIGH: 'عالي',
  ADVANCED: 'المتقدمة',
  CLICK_BELOW_TO_POPULATE_ANSWER: 'انقر على النص والحقول أدناه لملء الإجابة وتحريرها',
  DOCUMENT_VIEW: 'عرض المستند',
  FORM_VIEW: 'عرض النموذج',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'ليس لديك حقوق لإرسال الجواب.',
  ANSWER_TEXT: 'جواب النص',
  CONTACT_INFORMATION: 'معلومات للتواصل',
  COMPANY: 'شركة',
  SRD_NEEDED_TOOLTIP: 'تتطلب الإجابة عن هذا الاستفسار وثيقة SRD جديدة',
  ENABLE_ALL: 'تمكين الكل',
  DISABLE_ALL: 'أوقف عمل الكل',
  BEFORE: 'قبل',
  AFTER: 'بعد',
  EDIT: 'تعديل',
  MY_TEMPLATES: 'قوالبي',
  TEAM_TEMPLATES: 'قوالب الفريق',
  TEAM_TEMPLATES_HINT: 'نموذج تم إنشاؤه بواسطة مدير المعلومات الطبية للفريق بأكمله',
  SOURCE: 'مصدر',
  TEMPLATE_NO_MATCH_INTERACTION: 'هذا النموذج لا يتطابق مع تفاصيل التفاعل ولا ينطبق',
  EMAIL_SUBJECT: 'موضوع البريد الإلكتروني',
  INQUIRY_SUBMIT_HINT: 'يرجى تعبئة جميع الحقول المطلوبة للرد على الاستفسار',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'النشر المجدول حتى تاريخ انتهاء الصلاحية',
  COPY_ATTACHMENT_TO_ANSWER: 'انسخ المرفق للإجابة',
  COPY_TEXT_TO_ANSWER: 'انسخ النص للرد',
  SELECT_INQUIRY: 'حدد الاستفسار',
  EDIT_INQ: 'تحرير السؤال',
  SPLIT_ANOTHER: 'انقسام آخر',
  DELETE_INQ: 'حذف الاستعلام',
  SELECT_TEXT_FOR_SPLIT: 'أضف سؤال جديد في نفس التفاعل',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'توليد تفاعل / حالة منفصلة',
  SPLIT_ORIGINAL_TEXT: 'النص الأصلي (حدد نصًا للتقسيم)',
  SPLIT_TEXT_TO_REMAIN_: 'نص للبقاء في التحقيق الأولي',
  SPLIT_TEXT_TO_SPLIT_: 'النص المراد تقسيمه',
  SPLIT_SELECTED_SECTIONS: 'أقسام مختارة للتقسيم',
  SPLIT_NO_MODIFICATIONS_HINT: 'سيتم ملء النص تلقائيًا أثناء تحديد أقسام من النص الأصلي',
  SPLIT_MODIFICATIONS_HINT: 'تم تعديل النص يدويًا ولن يتم تطبيق أي تحديد آخر من النص الأصلي.',
  TEMPLATE_NO_MODIFICATIONS_HINT: 'سيتم تحديث النص تلقائيًا بالتغييرات في تفاصيل الاستعلام.',
  SELECT_DIFFERENT_TEMPLATE: 'حدد قالبًا مختلفًا',
  APPROVE: 'يوافق',
  LAST_EDIT_ON: 'آخر تحرير في',
  LAST_EDIT_BY: 'آخر تحرير بواسطة',
  INTERNAL_COMM: 'الاتصالات الداخلية',
  INACTIVE: 'غير نشط',
  DEACTIVATE: 'تعطيل',
  ARCHIVED: 'مؤرشف',
  REPUBLISH: 'إعادة النشر',
  INBOX_NO_FILTERS_HINT: 'لا توجد مرشحات محددة.',
  INTERACTION_TEMPLATE_HINT:
    'تعد التوقيعات والتحية وإخلاء المسؤولية جزءًا من الاتصالات الكتابية ، مثل البريد الإلكتروني والخطاب والفاكس.',
  CONFIRM_AUTH_REQUEST: 'تأكيد طلب المصادقة',
  VIEW_AUTH_REQUEST: 'عرض طلب المصادقة',
  QUESTION_TITLE: 'عنوان السؤال: ',
  NO_TITLE_INQUIRY: '-لا-استعلام-عنوان-',
  EXISTING_CATEGORIES: 'الفئات الموجودة',
  EXISTING_TOPICS: 'المواضيع الموجودة',
  DO_NOT_ASSIGN_PRODUCT: 'لا تعين المنتج',
  QUESTION_TEXT: 'نص السؤال',
  DATE: 'تاريخ',
  REJECTED: 'مرفوض',
  FILTER_AUTH_REQUESTS_BY: 'تصفية طلبات المصادقة حسب',
  ALL_REQUESTS_ALL_TEAMS: 'جميع الطلبات لجميع الفرق',
  ALL_TEAMS: 'كل الفرق',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST: 'ليس لديك حقوق لتحرير طلبات الفئة / الموضوع.',
  SEND_FOLLOW_UP: 'إرسال متابعة',
  RECEIPT_NOTIFICATION_CUSTOMER: 'إشعار الاستلام للعميل',
  GDPR_DISCLAIMER: 'إخلاء مسؤولية اللائحة العامة لحماية البيانات (GDPR)',
  ACTIONS: 'أجراءات',
  SUBMISSION_CONDITIONS: 'شروط التقديم',
  TYPE_OF_NOTIFICATION: 'نوع الإخطار',
  DIRECT_SUBMISSION: 'التقديم المباشر',
  DIRECT_SUBMISSION_TOOLTIP:
    'للاستفسارات المرسلة مباشرة من العميل عبر البريد الإلكتروني ، أرسل إشعارًا لإبلاغ العميل بمعالجة بياناته الشخصية.',
  COMPANY_EMPLOYEE_SUBMISSION: 'مقدم عن طريق موظف الشركة',
  ENABLE_NOTIFICATION: 'تفعيل الإشعارات',
  NOTIFICATION_SENDER: 'إخطار المرسل',
  SALES_REP_DELIVERED_RESPONSE: 'مندوب مبيعات للاستجابة المسلمة',
  NOTIFICATION_DELIVERED_RESPONSE: 'الإخطار لتسليم الرد',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'للاستفسارات المقدمة عبر ممثل الشركة (مندوب مبيعات أو MSL أو غير ذلك) ، قم بإخطار المرسل عند تلقي العميل إجابة على استفساره',
  FULL_ANSWER: 'إجابة كاملة',
  NOTIFICATION: 'تنبيه',
  NAME: 'اسم',
  COLUMN_INIT_QUESTION_TEXT: 'نصالسؤالالأصلي',
  NOTIFICATION_ENABLED: 'تمكين الإعلام',
  DEFAULT_TEMPLATE: 'القالب الافتراضي',
  SET_AS_DEFAULT: 'تعيين كافتراضي',
  DEFAULT_TEMPLATE_TOOLTIP: 'تم تعطيل "التبديل" حيث يجب أن يكون هناك قالب افتراضي واحد على الأقل!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'لا يمكن حذف القالب الافتراضي!',
  BATCH_UPDATE_ONLY: 'يمكن فقط تحديث الدُفعة من خلال مربع الاختيار العلوي.',
  ACCESS_DENIED: 'تم الرفض!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: 'ليس لديك إذن للوصول إلى هذه الصفحة.',
  USER_ROLES: 'أدوار المستخدمين',
  MANAGE_USER_ROLES: 'إدارة أدوار المستخدم',
  ARCHIVE_ROLE: 'دور الأرشيف',
  ROLE_ARCHIVE_CONFIRMATION: 'هل تريد حقًا أرشفة هذا الدور؟',
  ROLES_FOR: 'أدوار',
  AUDIT_TRAIL: 'سجل تدقيق',
  ACTION_BY_USER: 'إجراء من قبل المستخدم',
  ACTION: 'عمل',
  ORIGINAL_VALUE: 'القيمة الأصلية',
  CHANGE: 'يتغيرون',
  CONDITION: 'حالة',
  IP: 'IP',
  GEOLOCATION: 'تحديد الموقع الجغرافي',
  NEW_VALUE: 'قيمة جديدة',
  FILTER_BY_ACTION: '',
  SELECT_DATE: '',
  TEAM_SAVE: 'يحفظ',
  TEAM_DELETE: 'حذف',
  TEAM_CREATE: 'خلق',
  TEAM_CLONE: 'ينسخ',
  TEAM_NAME: 'اسم',
  TEAM_CODE: 'شفرة',
  TEAM_ACTION_DELETE_CONFIRM: 'هل تريد حقا حذف',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'كن حذرا!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'بمجرد حذفه ، لا يمكن استعادته!',
  TEAM_FULL_ANSWER_ON_LABEL: 'إجابة كاملة على التسمية',
  TEAM_RESTRICTED_TEAM: 'مقيد',
  TEAM_SEGREGATED_DATA: 'البيانات المنفصلة',
  TEAM_PRIVATE_TEAM: 'فريق خاص',
  TEAM_AUTO_ASSIGN: 'انتقاء ذاتي',
  TEAM_MAILBOX_IN: 'صندوق البريد الداخلي',
  TEAM_MAILBOX_OUT: 'صندوق البريد الصادر',
  TEAM_DEFAULT_LANGUAGE: 'اللغة الافتراضية',
  TEAM_COUNTRIES: 'البلدان المعينة',
  TEAM_PRODUCT_GROUPS: 'تعيين مجموعات المنتجات',
  TEAM_PARENT: 'فريق الوالدين',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'تجاوز فريق الجذر العنقودي',
  TEAM_AFFILIATIONS: 'فرق تابعة',
  TEAM_TIMEZONE: 'وحدة زمنية',
  UPDATE: 'تحديث',
  AUTO_ASSIGN_BY: 'تلقائي تلقائي بواسطة',
  AUTO_ASSIGNMENTS: 'مهام السيارات',
  NO_ONE: 'لا احد',
  NOT_APPLICABLE: 'لا ينطبق',
  OVERRIDE_NOTIFICATIONS: 'تجاوز الإخطارات',
  OVERRIDE_NOTIFICATIONS_HINT:
    'عندما يتم تعيين قالب المستخدم مع الإخطارات التجاوز على المستخدم ، فإنه سيعزى أي إعدادات إشعار مسبقًا وتمكين إعدادات الإخطار فقط في قالب المستخدم',
  CREATED_MANUALLY: 'تم إنشاؤها يدويًا',
  DOCUMENT_TITLE_FOR_WORD:
    'عادةً ما يتم أخذ عنوان مستندات Word من قسم تنسيق العنوان داخل المستند نفسه. إذا لم يحتوي المستند على عنوان ، فيمكنك تحديده هنا. إذا لم تحدد العنوان هنا ولم يحتوي مستند Word على قسم تنسيق العنوان ، فسيتم استخدام معرف المستند للعنوان.',
  ORDER_BY: 'ترتيب حسب',
  FORMAT: 'شكل',
  SELECT_QUESTION: 'اختر سؤالا',
  SELECT_QUESTION_FOR_NOTE: 'حدد سؤالًا تريد إضافة ملاحظة من أجله',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'أضف ملاحظة للاستفسار الكامل',
  REQUESTED_DATE: 'موعد مطلوب',
  UNASSIGNED: 'غير معروف',
  BLANK: 'فارغ',
  ANSWER_SAVED_WITHOUT_EMAIL: 'تم حفظ الإجابة بدون بريد إلكتروني',
  INQ_RELATED_TO: 'الاستفسارات المتعلقة',
  QUESTION_TEXT_BEFORE_SPLIT: 'سؤال سؤال قبل الانقسام',
  SPLIT_MERGE: 'تقسيم ودمج',
  CLARIFICATION_QUESTION: 'سؤال التوضيح',
  ACTIVE_ON: 'نشط على',
  DISMISS: 'رفض',
  GO_TO_ENQUIRY: 'اذهب إلى الاستفسار',
  NO_TEAMS: 'بدون فريق',
  NO_TEAMS_TIP:
    'يحتوي على مستخدمين ، لا يتم تعيينهم لأي فريق. يمكنك التحقق مما إذا كان بعض هذه من فريقك ومنحهم الدور المناسب.',
  TASKS: 'مهام',
  Therapeutic_Areas: 'المناطق العلاجية',
  Product_Groups: 'مجموعات المنتجات',
  Licensing_Partner: 'شريك الترخيص',
  Product_Code: 'كود المنتج',
  SELECT_EXISTING_ONE: 'حدد واحد موجود',
  OR: 'أو',
  MANAGE_FORM: 'إدارة النموذج',
  EVENT_FORM_ADDRESS: 'عنوان نموذج الحدث',
  EVENT_FORM_PREVIEW_WARING: 'تأكد من حفظ أي تغييرات قبل فتح نموذج الكونغرس أو نسخ عنوانه',
  SEARCH_SAVED_SEARCHES: 'البحث المحفوظ عمليات البحث',
  GO: 'يذهب',
  ASSIGN: 'تعيين',
  CERTIFICATION: 'شهادة',
  SORT: 'فرز',
  CREATE_NEW_MAILBOX: 'إنشاء صندوق بريد جديد',
  EDIT_MAILBOX: 'تحرير صندوق البريد',
  ADD_MAILBOX: 'إضافة صندوق البريد',
  UNSET_INCOMING_MAILBOX: 'قم بتفكيك صندوق البريد الوارد',
  SET_INCOMING_MAILBOX: 'تعيين صندوق بريد وارد',
  UNSET_OUTGOING_MAILBOX: 'قم بإلغاء ضبط صندوق البريد الصادر',
  SET_OUTGOING_MAILBOX: 'تعيين صندوق البريد الصادر',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'جميع الفرق مع صندوق البريد الحالي',
  MAILBOX_HISTORY: 'تاريخ صندوق البريد',
  ALL_TEAMS_WITH_MAILBOX: 'جميع الفرق مع صندوق البريد',
  MAILBOX: 'صندوق بريد',
  EMAIL_POLLING: 'استطلاع البريد الإلكتروني',
  ADVANCED_SEARCH: 'البحث المتقدم',
  SELECT_FIELDS: 'حدد الحقول',
  SEARCH_TERM: 'مصطلح البحث',
  COLUMN_INTERACTION_ID: 'معرف التفاعل',
  COLUMN_QUESTION_ID: 'معرف السؤال',
  COLUMN_CREATED_ON: 'تم إنشاؤها على',
  COLUMN_LAST_EDITED_DATE: 'آخر تحرير على',
  COLUMN_ASSIGNED_TO: 'يسند إلى',
  COLUMN_DUE_DATE: 'تاريخ الاستحقاق',
  COLUMN_FOLLOW_SENT_DATE: 'موعد المرسلة المتابعة',
  COLUMN_FOLLOW_RECEIVED_DATE: 'متابعة تاريخ المتابعة',
  COLUMN_ANSWER_DATE: 'تاريخ الإجابة',
  COLUMN_CLOSED_ON_DATE: 'مغلق في التاريخ',
  COLUMN_REOPENED_DATE: 'تاريخ إعادة فتحه',
  COLUMN_INSERTED_BY: 'إدراجها',
  COLUMN_LAST_EDITED_BY: 'آخر تحرير من قبل',
  COLUMN_ANSWERED_BY: 'أجاب من قبل',
  COLUMN_APPROVED_BY: 'تمت الموافقة عليه من قبل',
  COLUMN_INT_COMM_USER: 'مستخدم الاتصالات الداخلية',
  COLUMN_INT_COMM_DATE: 'تاريخ الاتصال الداخلي',
  COLUMN_MY_TEAM: 'فريقي',
  COLUMN_PQC_RELATED: 'PQC ذات الصلة',
  COLUMN_INCOMING_CHANNEL: 'قناة واردة',
  COLUMN_OUTGOING_CHANNEL: 'قناة الصادرة',
  COLUMN_OFF_LABEL: 'خارج العلامة',
  COLUMN_HCP_SPEC: 'HCP + التخصص',
  COLUMN_NON_HCP_SPEC: 'مهنة غير HCP +',
  COLUMN_KEE: 'كي',
  COLUMN_HOSPITAL_BASED: 'مقرها المستشفى',
  COLUMN_CITY: 'مدينة',
  COLUMN_INSTITUTION: 'مؤسسة',
  COLUMN_TERRITORY: 'إِقلِيم',
  CASE_ID: 'ID القضية',
  OPTION_ADD: 'يضيف',
  OPTION_AND: 'و',
  OPTION_OR: 'أو',
  OPTION_NOT: 'ليس',
  OPTION_YES: 'نعم',
  OPTION_NO: 'رقم',
  QUERY: 'استفسار',
  EXPORT_LABEL: 'تحديد أعمدة التصدير',
  SAVE_SEARCH: 'حفظ البحث',
  SHARE_SEARCH: 'مشاركة البحث',
  SEARCH_BTN: 'يبحث',
  BACK_ACTION: 'خلف',
  SAVE_CHANGES: 'حفظ التغييرات',
  SAVED_SEARCH: 'البحث المحفوظ',
  NEW: 'جديد',
  SAVE_AS: 'حفظ كاسم',
  ENTER_RECIPIENTS: 'أدخل المستلمين',
  CLEAR_SAVED_SEARCH: 'عمليات تفتيش محفوظة واضحة',
  NO_SAVED_SEARCH: 'لم يتم العثور على بحث محفوظ',
  INT_COMM_TEXT: 'نص الاتصال الداخلي',
  FOLLOW_UP_TEXT: 'نص المتابعة',
  CONFIRM_DELETION: 'تأكيد الحذف',
  ARE_SURE: 'هل أنت متأكد أنك تريد حذف البحث المحفوظ',
  PERMANENTLY_DELETED: 'سيتم حذف البحث بشكل دائم <b> </b>.',
  NOTHING_SAVE: 'لا شيء لإنقاذ.',
  NOTHING_SEARCH: 'لا شيء للبحث.',
  NOTHING_EXPORT: 'البحث ليس له سجل للتصدير.',
  SHARE_SUCCESS: 'تمت مشاركة البحث بنجاح',
  SHARE_FAILURE: 'لا يمكن مشاركة البحث',
  DELETED_SUCCESS: 'تم حذف البحث المحفوظ بنجاح',
  ALL_DELETED_SUCCESS: 'تم حذف جميع عمليات البحث المحفوظة بنجاح',
  VIEW_SAVED_SEARCH: 'عرض البحث المحفوظ',
  EDIT_SAVED_SEARCH: 'تحرير حفظ البحث',
  EXPORT_SAVED_SEARCH: 'تصدير البحث المحفوظ',
  EXPORT_SEARCH: 'البحث عن التصدير',
  DELETE_SAVED_SEARCH: 'حذف البحث المحفوظ',
  SELECT_EXPORT_COLUMNS: 'حدد أعمدة للتصدير',
  SAVED_SEARCH_ALREADY_EXIST: 'ابحث بهذا الاسم موجود بالفعل.',
  CHARACTER_LIMIT: 'دقيقة 3 شخصيات. كحد أقصى 35 حرفًا.',
  CHARACTER_LEFT: 'ترك الشخصيات',
  ACTIVATE: 'تفعيل',
  INACTIVE_USER: 'لم يعد هذا المستخدم نشطًا. من فضلك تواصل مع مسؤول نظامك.',
  FOLLOW_UP: 'متابعة',
  USER_NOT_FOUND: 'المستخدم ليس موجود',
  DEPARTMENTS: 'الإدارات',
  EMAIL: 'البريد الإلكتروني',
  ADD_NEW_DEPARTMENT: 'إضافة قسم جديد',
  DEPARTMENT_COUNTRY: 'بلد',
  DEPARTMENT_COMPANY: 'شركة',
  DEPARTMENT_TEAM: 'فريق',
  DEPARTMENT_TA: 'منطقة علاجية',
  DEPARTMENT_PRODUCT: 'منتج',
  DEPARTMENT_ACTIONS: 'أجراءات',
  DEPARTMENT_CONFIRMATION_1: 'تأكيد الحذف؟',
  DEPARTMENT_CONFIRMATION_2: 'هذا الإجراء لا رجعة فيه.',
  DEPARTMENT_NAME: 'اسم القسم',
  TYPE_DEPARTMENT: 'يكتب',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'البلد - الرجاء اختيار الفريق أولاً',
  DEP_NAME_CHARACTER_LIMIT: 'أقصى 200 حرف.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'طلب المستخدم',
      CategoryTopicRequest: 'طلب الفئة/الموضوع',
      ProductRequest: 'طلب المنتج',
      UserUpdateRequest: 'طلب تحديث المستخدم',
    },
    TYPE: {
      NewUser: 'مستخدم جديد',
      NewCategoryTopic: 'فئة/موضوع جديد',
      NewUserUpdate: 'تحديث المستخدم',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'حدد قائمة التخصص المحددة للبلد',
      IS_COUNTRY_SPECIFIC: 'هل بلد محدد',
      TYPE: 'نوع السائل',
      SPECIALIZATION_EXISTS: 'يوجد بالفعل تكوين لـ "نوع/تخصص المستفسر".',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'جنس', COUNTRY: 'دولة', TITLE: 'عنوان' },
  },
  hcp: 'HCP',
  emp: 'موظف',
  cons: 'غير HCP',
  OOO_DEPUTY: 'نائب أوو',
  NO_DEPUTY: 'لا نائب',
  UNSPECIFIED_END_DATE: 'تاريخ نهاية غير محدد',
  DELEGATORS: 'مفيلون',
  NO_DELEGATORS: 'لا ممنورون',
  PREVIEW_DELEGATORS: 'معاينة عنقات',
  REMOVE_DEPUTY: 'إزالة نائب',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'المستخدمون الذين قاموا بتعيينك كنائب',
  CHANGED_USER: 'تم تغيير المستخدم',
  DEFAULT: 'تقصير',
  SPECIALIZATIONS: 'التخصصات',
  ENQUIRERS_LIST: 'قائمة enquirers',
  EDIT_THE_RECORD: 'تحرير السجل',
  DEACTIVATE_THE_RECORD: 'إلغاء تنشيط السجل',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'خارج المكتب',
      HINT: 'فقط المستخدمون النشطون ، مع دور معالجة الاستفسار ، الذين ليسوا خارج المكتب متاحون للاختيار.',
      MODAL_TITLE: 'تأكد من إعدادات المكتب',
      MODAL_MSG_1:
        'لقد تم تعيين خيار Office Office مع تأثير فوري ، سيتم تعيين جميع استفساراتك على الفور إلى النائب.',
      MODAL_MSG_2:
        'لقد تم تعيينك خارج Office Option ، سيتم تعيين جميع استفساراتك إلى النائب خلال فترة خارج المكتب.',
    },
  },
  GENDER_MALE: 'السيد.',
  GENDER_FEMALE: 'السّيدة.',
  TITLE_DR: 'دكتور.',
  TITLE_PHD: 'دكتوراه',
  TITLE_PROF: 'البروفيسور',
  TITLE_PROF_DR: 'البروفيسور د.',
  HOME: 'مسكن',
  HOSPITAL: 'مستشفى',
  OFFICE: 'مكتب',
  WORK: 'عمل',
  INSERT_IMAGE: 'إدراج صورة',
  USER_ADMIN: 'مسؤول المستخدم',
  MAILBOXES: 'صناديق البريد',
  AUTHORIZATION_REQUESTS: 'طلبات التفويض',
  METADATA: 'البيانات الوصفية',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'مهمة',
      FORWARD: 'إلى الأمام',
      FOLLOW_UP: 'متابعة',
      DUE_DATE_SETTINGS: 'إعدادات تاريخ الاستحقاق',
      CLOSE: 'قريب',
      DELIVERY_ERROR: 'خطأ في التسليم',
      SCHEDULER: 'جدولة',
      USER_REPORTS: 'تقارير المستخدم',
      DOCUMENT_APPROVAL: 'موافقة الوثيقة',
      AUTHORIZATION_REQUEST: 'طلبات التفويض',
      NEW_INQUIRY: 'استفسار جديد',
      INQUIRY_APPROVAL: 'موافقة الاستفسار',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'تمت الموافقة/رفض طلب الدور',
      AUTHORIZATION_REQUEST_3: 'الدور هو انتظار موافقتي',
      DOCUMENT_APPROVAL_1: 'وثيقة تنتظر موافقتك',
      DOCUMENT_APPROVAL_2: 'تمت الموافقة/رفض وثيقة',
      DOCUMENT_APPROVAL_3: 'تم أرشفة وثيقة',
      DOCUMENT_APPROVAL_4: 'تمت الموافقة على وثيقة مع التغييرات',
      DOCUMENT_APPROVAL_5: 'سيتم أرشفة وثيقة في',
      DOCUMENT_APPROVAL_6: 'قائمة الوثائق المعتمدة',
      DUE_DATE_2: 'كرر بعد الماضي',
      ERROR_1: 'فشل إشعار التسليم (مثل البريد الإلكتروني غير الصحيح)',
      FOLLOW_UP_1: 'تم إرسال متابعة أو تذكير إلى العميل',
      FOLLOW_UP_4: 'تم استلام متابعة',
      IMPORT_DATA_1: 'تم إنشاء كائن جديد أثناء الاستيراد',
      IMPORT_DATA_4: 'تم إنشاء مستخدم جديد أثناء الاستيراد',
      IMPORT_DATA_5: 'كان المستخدم يطابق واحدة موجودة بالفعل ولكن لم يكن قادمًا من استيراد سابق',
      IMPORT_DATA_6: 'حدث خطأ أثناء استيراد البيانات من مصدر خارجي',
      INQUIRY_APPROVAL_4: 'تمت الموافقة / الموافقة على إجابتك المقترحة مع التغييرات / الرفض',
      METADATA_MANAGEMENT_1: 'تم إنشاء بيانات تعريف جديدة',
      NEW_DOCUMENT_1: 'وثيقة جديدة معتمدة متوفرة في بلدي',
      NEW_DOCUMENT_2: 'وثيقة جديدة متاحة للموافقة المحلية',
      NEW_DOCUMENT_3: 'وثيقة محلية جديدة متاحة للموافقة الأوروبية / العالمية',
      NEW_DOCUMENT_4: 'وثيقة عالمية جديدة متاحة',
      NEW_DOCUMENT_5: 'وثيقة أوروبية جديدة متوفرة',
      NEW_DOCUMENT_6: 'وثيقة أوروبية جديدة متاحة للموافقة العالمية',
      SCHEDULER_TASK_1: 'حدث خطأ أثناء تنفيذ المهمة',
      USER_REPORTS_1: 'قائمة المستخدمين النشطين',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'تلقي الإخطارات التي تُعلمك عندما يحتاج طلب دور المستخدم إلى الموافقة عليه/رفضه',
        AUTHORIZATION_REQUEST_2:
          'تلقي الإخطارات عند إنشاء مستخدم جديد أو عندما طلب أحد المستخدمين دورًا في فريقك',
        AUTHORIZATION_REQUEST_4: 'تلقي الإخطارات عند اقتراح موضوع جديد و/أو فئة',
        FOLLOW_UP_4:
          'تلقي إشعارات بشأن الاستفسارات التي تم تعييني عليها والتي تلقيت ردًا متابعة من السائل',
        NEW_INQUIRY_1:
          'تلقي إشعارات بشأن الاستفسارات الجديدة الواردة في بلدك، أو التي أدخلها السائل/المستخدم أو تم إعادة توجيهها من فريق آخر',
        ANSWER_2:
          'تلقي إشعارات بشأن الاستفسارات التي تم الرد عليها والتي تفيد بأنني عملت كمتعاون، ولكن لم يتم تعييني بشكل مباشر في الاستفسار',
      },
      FREQUENCIES: {
        INSTANTLY: 'فورا',
        DAILY: 'اليومي',
        WEEKLY: 'أسبوعي',
        QUARTERLY: 'ربعي',
        MONTHLY: 'شهريا',
        SECOND_DAY: 'كل يوم ثاني',
        THIRD_DAY: 'كل يوم ثالث',
        ONE_WEEK: 'اسبوع واحد',
        ONE_MONTH: 'شهر واحد',
        TWO_MONTHS: 'شهرين',
        THREE_MONTHS: 'ثلاثة أشهر',
        SIX_MONTHS: 'ستة أشهر',
        NINE_MONTHS: 'تسعة أشهر',
        EVERY_TWO_WEEKS: 'كل أسبوعين',
        EVERY_THREE_WEEKS: 'كل ثلاثة أسابيع',
        EVERY_FOUR_WEEKS: 'كل أربعة أسابيع',
      },
      OPTIONS: {
        ALMOST_DUE: 'تقريبا بسبب',
        DUE_NOW: 'بسبب الآن',
        DUE_SOON: 'قريب',
        EARLY_ON: 'مبكرا على',
        TEXT_GUIDES: {
          EARLY_ON:
            'تلقي الإخطارات في وقت مبكر للاستفسارات المستحقة: طبيعية 4 أيام - مرتفعة يومين - حرجة 24 ساعة',
          DUE_SOON:
            'تلقي إشعارات للاستفسارات المستحقة قريبًا: طبيعية 48 ساعة - مرتفعة 12 ساعة - حرجة 6 ساعات',
          ALMOST_DUE:
            'تلقي إشعارات للاستفسارات المستحقة تقريبًا: طبيعية على مدار 24 ساعة - 6 ساعات مرتفعة - 3 ساعات حاسمة',
          DUE_NOW: 'تلقي الإشعارات عندما يحين موعد الاستفسار',
        },
      },
      AUTHORIZATION_REQUEST_4: 'تم طلب فئة/ موضوع في فريقي',
      AUTHORIZATION_REQUEST_2: 'طلبات المستخدم',
      IMPORT_DATA_2: 'تم إنشاء مستفسر جديد أثناء عملية الاستيراد',
      IMPORT_DATA_3:
        'كان أحد المستعلمين يطابق أحد المستعلمين الموجودين بالفعل ولكنه لم يكن قادمًا من عملية استيراد سابقة',
      ANSWER_1: 'لقد تم الرد على استفساري',
      ANSWER_2: 'تم الرد على الاستفسار الذي كنت متورطًا فيه',
      ASSIGNMENT_1: 'لقد تم تكليفي بالاستفسار',
      ASSIGNMENT_5: 'تم تكليف نائبي بالاستفسار',
      CLOSE_1: 'تم إغلاق الاستفسار',
      DUE_DATE_1: 'الاستفسار الذي تم تكليفي به يقترب من تاريخ الاستحقاق، أبلغني',
      FOLLOW_UP_7: 'لقد ضاع الاستفسار للمتابعة',
      FORWARD_1: 'تم توجيه الاستفسار',
      INQUIRY_APPROVAL_1: 'استفسار في انتظار موافقتك',
      INTERNAL_1: 'تمت إضافة ملاحظة إلى استفساري المخصص',
      NEW_INQUIRY_1: 'لقد تم تلقي استفسار جديد في بلدي',
      SPAM_1: 'استفسار جديد ورد في البريد العشوائي',
    },
  },
  NEW_TEAM: 'فريق جديد',
  SmPc: 'SMPC',
  USERS: 'المستخدمون',
  PAGINATOR_NEXT_PAGE_LABEL: 'الصفحة التالية',
  PAGINATOR_PREV_PAGE_LABEL: 'الصفحة السابقة',
  PAGINATOR_FIRST_PAGE_LABEL: 'الصفحة الأولى',
  PAGINATOR_LAST_PAGE_LABEL: 'آخر صفحة',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'مواد لكل صفحة',
  Date: 'تاريخ',
  Action: 'عمل',
  'Action by user': 'الإجراء من قبل المستخدم',
  'Changed user': 'تم تغيير المستخدم',
  'Original value': 'القيمة الأصلية',
  'New value': 'قيمة جديدة',
  REGION: 'منطقة',
  TEAM_REGION_LABEL: 'المنطقة - يرجى اختيار الشركة أولاً',
  TEAM_THERAPEUTIC_AREA_LABEL: 'المناطق العلاجية - يرجى تحديد الشركة أولاً',
  TEAM_PRODUCT_LABEL: 'المنتجات - يرجى اختيار المنطقة العلاجية أولاً',
  TEAM_COUNTRIES_LABEL: 'البلدان - يرجى اختيار المنطقة أولاً',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'تحديد المنتجات لكل بلد',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'الرجاء تحديد منتج واحد على الأقل ل',
  VIEW_CHANGES: 'عرض التغييرات',
  Sales_Representative: 'مندوب مبيعات',
  Other_Commercial: 'تجارية أخرى',
  REGIONS: 'المناطق',
  MAIN_CLIENT: 'العميل الرئيسي',
  LICENSE_PARTNER: 'شريك الترخيص',
  TEAM_SET_UP: 'أنشأ الفريق',
  LIST_TEAMS: 'قائمة الفرق',
  COMPANIES: 'شركات',
  DOMAINS: 'المجالات',
  TOKEN_REFRESH_NOTICE: 'لقد طلبت بريدًا إلكترونيًا جديدًا للتأكيد لتسجيل حساب ENQMed الخاص بك.',
  TOKEN_REFRESH_SUCCESS: 'تم إرسال رابط تأكيد جديد إلى بريدك الإلكتروني.',
  TOKEN_REFRESH_ERROR: 'حدث خطأ. يرجى الاتصال بالدعم إذا كنت بحاجة إلى مزيد من المساعدة.',
  PLEASE_ENTER_MAX: 'الرجاء إدخال الحد الأقصى',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'يرجى مراجعة التغييرات أدناه ، إن وجدت ، وتأكيد ما إذا كان ينبغي تغيير الشركة.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE: 'يرجى المتابعة بحذر لأن التغييرات ستؤثر على الفرق.',
  THERE_ARE_NO_CHANGES: 'لا توجد تغييرات.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'يرجى التحقق من الشركات الموجودة في الجدول خلف مربع الحوار كما لدينا بالفعل شركة لها نفس القيم.',
  SIMPLE_TEXT:
    'يرجى إدخال الحروف فقط (A-Z و A-Z) والأرقام (0-9) ورموز خاصة مثل (DASH ، الفترة ، الفضاء والقولون).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'يرجى اختيار بلد واحد على الأقل للمنطقة',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'يرجى تحديد منتج واحد على الأقل للمنطقة العلاجية',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'لقد قمت باختيار منتج/S الذي ينتمي إلى أكثر من منطقة علاجية واحدة ، يرجى تحديد جميع المناطق العلاجية المطلوبة.',
  HTML_CHANGES: 'التغييرات HTML',
  SIDE_BY_SIDE_VIEW: 'منظر جنبًا إلى جنب',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'يرجى التحقق من المجالات الموجودة في الجدول خلف مربع الحوار حيث لدينا بالفعل نطاقات مع نفس القيم.',
  OUT_CHANNEL_ERROR_MSG: 'خطاب القناة الصادر عن عنوان البريد الإلكتروني',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'القناة الصادرة المحددة كرسالة. الرجاء إدخال عنوان البريد Enquirer داخل منطقة Enquirer',
  LABEL_FOR: 'تسمية ل',
  male: 'ذكر',
  female: 'أنثى',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'ليس لديك حقوق لأرشفة هذا الدور. يمكن فقط <b> mims </b> للفريق أرشفة أدوار أعضاء فريقهم.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION: 'أرسل بريدًا إلكترونيًا إلى المستخدم عند الطلب/الرفض',
  APPROVE_REQUEST_QUESTION: 'هل تريد الموافقة على الطلب؟',
  NEW_USER_REQUEST: 'طلب مستخدم جديد',
  OPEN_REQUESTS: 'طلبات مفتوحة',
  APPROVED_REQUESTS: 'الطلبات المعتمدة',
  REJECTED_REQUESTS: 'طلبات مرفوضة',
  USER_REQUESTS: 'طلبات المستخدم',
  REQUEST_FOR: 'طلب ل',
  CATEGORY_TOPIC_REQUESTS: 'طلبات الفئة/الموضوع',
  WITH_THE_FOLLOWING_PAIRS: 'مع الأزواج التالية:',
  MY_TEAM_OPEN_REQUESTS: 'طلبات مفتوحة',
  MY_TEAM_APPROVED_REQUESTS: 'الطلبات المعتمدة',
  MY_TEAM_REJECTED_REQUESTS: 'طلبات مرفوضة',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'موافقة الوثيقة داخل enqmed',
  DOCUMENTS_APPROVAL: 'موافقة الوثائق',
  DOCUMENTS_APPROVAL_TITLE: 'إعدادات عملية موافقة المستندات للفريق المحدد',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'القناة الصادرة المحددة كفاكس. الرجاء إدخال رقم الفاكس في Enquirer داخل منطقة Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'القناة الصادرة المحددة كهاتف. الرجاء إدخال رقم هاتف Enquirer داخل منطقة Enquirer',
  OUT_CHANNEL_FAX_ERROR_MSG: 'قيمة الفاكس الصادرة عن القناة الفاكس',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'قيمة هاتف القناة المنتهية ولايته القناة',
  COPYRIGHT_PART_ONE: 'حقوق النشر',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. جميع الحقوق محفوظة.',
  REVIEWED_DATE: 'تاريخ المراجعة',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'القناة الصادرة المحددة كبريد إلكتروني. الرجاء إدخال بريد إلكتروني Enquirer داخل منطقة Enquirer',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'قيمة البريد الإلكتروني للقناة الصادرة',
  ENTER_USER_COMMENT_OPTIONAL: 'أدخل التعليق للمستخدم (اختياري)',
  SELECT_ANOTHER_CATEGORY: 'حدد فئة أخرى',
  SELECT_ANOTHER_PRODUCT: 'حدد منتجًا آخر',
  SUGGESTED_TOPIC: 'موضوع مقترح',
  EDIT_USER_REQUEST: 'تحرير طلب المستخدم',
  NEW_TOPIC_REQUEST: 'موضوع جديد اقترح',
  EDIT_TOPIC_REQUEST: 'تحرير الموضوع المقترح',
  NEW_CATEGORY_REQUEST: 'فئة جديدة اقترح',
  EDIT_CATEGORY_REQUEST: 'تحرير الفئة المقترحة',
  REVIEW_COMMENT: 'مراجعة التعليق',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'وثيقة سير عمل موافقة',
    CREATE_NEW: 'إنشاء سير عمل جديد',
    CUSTOMIZE: 'تخصيص سير العمل',
    NAME: 'اسم سير العمل',
    ERROR_MSG_DUPLICATE_NAME: 'سير العمل مع هذا الاسم موجود بالفعل.',
    SAVE: 'حفظ سير العمل',
    DELETE: 'حذف سير العمل',
    STEP: 'خطوة',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'جميع المستخدمين مع دور مؤلف المستند.',
    APPROVAL_DEADLINE: 'الموعد النهائي للموافقة',
    SELECT_APPROVAL_TYPE: 'حدد نوع الموافقة',
    SELECT_APPROVAL_TYPE_FIRST: 'حدد نوع الموافقة أولاً',
    SELECT_REVIEWERS: 'حدد المراجعين',
    ACTION_ON_APPROVE_WITH_CHANGES: 'العمل على الموافقة على التغييرات',
    ACTION_ON_REJECT: 'العمل على رفض',
    FILL_ALL_REQUIRED_FIELDS: 'يرجى ملء جميع الحقول المطلوبة لإضافة خطوة جديدة.',
    ALL_APPROVAL_TYPES_USED:
      'لا يمكنك إضافة المزيد من الخطوات الفرعية للموافقة حيث استخدمت جميع الأنواع المتاحة للخطوة الحالية.',
    CONFIRMATION_ROW_1: 'أنت تحاول حذف سير عمل موافقة المستندات للفريق {{ NAME }}.',
    CONFIRMATION_ROW_2: 'هل تريد حقًا حذف سير عمل الموافقة؟',
    CONFIRMATION_TITLE: 'حذف سير عمل موافقة الوثيقة',
    TEAM_VIEW_SELECT_EXISTING: 'حدد سير عمل حالي لموافقة المستند من القائمة أدناه أو',
    DOC_VIEW_SELECT_EXISTING: 'حدد سير عمل موافقة مستند موجود',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'يمكن إدراج خطوة موافقة واحدة فقط على أنها نهائية.',
    APPROVAL_TYPE_TOOLTIP:
      'لا يوجد مستخدمون لديهم دور موافقة المستند لهذا الفريق. الرجاء إضافة أدوار الموافقة أولاً.',
    INFO_DIALOG_TITLE: 'لا يوجد سير عمل للموافقة على موافقة المستند لهذا الفريق.',
    INFO_DIALOG_MSG: 'يرجى الاتصال بـ MIM المحلي لإنشاء سير عمل موافقة المستند للفريق.',
    STEP_LIMIT_REACHED: 'لقد وصلت إلى الحد الأقصى من خطوات الموافقة وهو {{ MAX_STEPS }}',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'أنواع المستندات التي لا تتطلب موافقة',
  PLEASE_SELECT_TYPE: 'الرجاء تحديد النوع',
  ABORT_FILE_UPLOAD: 'إحباط تحميل الملف',
  USERS_REPORT: 'تقرير المستخدمين',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'من خلال إغلاق هذه النافذة ، ستفقد المعلومات التي تم ملؤها بالفعل.',
  DO_YOU_WANT_TO_PROCEED: 'هل تريد المتابعة؟',
  USERS_REPORT_DESCRIPTION:
    'قائمة مستخدمي جميع الفرق الإضافية حيث يكون لـ MIM لـ Daiichi Sankyo Europe Oncology و Cardiovascular دور MIM.',
  TEMPLATE_CONFIRM_MSG: 'بمجرد حذف القالب ، لا يمكن استعادته!',
  EVENT_CONFIRM_MSG: 'بمجرد أرشفة الحدث ، لا يمكن استعادته!',
  CREATE_DRAFT: 'إنشاء مسودة',
  CREATE_USER: 'إنشاء المستخدم',
  ADD_ROLE: 'إضافة دور',
  DOMAIN_ADDRESS_INVALID_FOR: 'عنوان المجال غير صالح ل',
  AVAILABLE_ADDRESSES: 'العناوين المتاحة',
  IN_TEAM: 'في فريق',
  TO_THE_SELECTED_USER: 'للمستخدم المحدد',
  USER_INFORMATION: 'معلومات المستخدم',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'يجب تحديث عنوان البريد الإلكتروني الذي تستخدمه لأن عنوان المجال لا يتوافق مع الفريق/ملفك الشخصي المرتبط به. <br> يرجى استشارة مسؤول النظام الخاص بك للحصول على المساعدة أو الاتصال بفريق الدعم.',
  MESSAGE: 'رسالة',
  IN_APPROVAL: 'في الموافقة',
  IN_REVIEW: 'في مراجعة',
  DOCUMENT_APPROVAL: 'موافقة الوثيقة',
  DOCUMENT_TITLE: 'عنوان الوثيقة',
  SEARCH_DOCUMENTS: 'وثائق البحث',
  APPROVAL_INBOX: 'صندوق الوارد للموافقة',
  FILTER_DOCUMENTS_BY: 'مرشح المستندات بواسطة',
  submit_to_approval: 'تقديم للموافقة',
  create_document_metadata: 'إنشاء بيانات بيانات الوثيقة',
  archive: 'أرشيف',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'أقر بأنه من ملابسي الإبلاغ عن جميع ردود الفعل الضارة للعقاقير (ADRs) لجميع المنتجات الطبية وفقًا للمتطلبات المحلية. لا يهدف Enqmed إلى التقاط تقارير حول تفاعلات الدواء الضارة - يجب الإبلاغ عن ADRs إلى الأدوية مباشرة وفقًا للمتطلبات المحلية',
  VERSION_DIALOG: {
    TITLE: 'إصدار جديد متاح',
    MESSAGE:
      'هناك نسخة جديدة من البرنامج متاحة!\n تحتاج إلى الحصول على الإصدار الجديد ، للاستفادة من التحسينات فيه وتجنب المشكلات من استخدام الإصدار القديم ، تحتاج إلى الحصول عليه.',
    SAVE_BUTTON: 'احصل على الإصدار الجديد',
  },
  ACTIVE_INACTIVE: 'نشط نشط',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'يرجى التحقق من الفئات الموجودة في الجدول خلف مربع الحوار حيث لدينا بالفعل فئة بنفس القيم.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'خطأ! تسليم فاشلة',
  VIA: 'عبر',
  INSERTED_MANUALLY: 'يتم إدراجه يدويًا',
  AS_A: 'ك',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'يرجى التحقق من المنتجات الموجودة في الجدول الموجود خلف مربع الحوار حيث أن لدينا بالفعل منتج بنفس القيم.',
  NOT_PRODUCT_SPECIFIC: 'ليس منتجًا محددًا',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'يرجى التحقق من المواضيع الموجودة في الجدول الموجود خلف مربع الحوار حيث أن لدينا موضوعات بنفس القيم بالفعل.',
  PLEASE_ENTER_MAX_CHARACTERS: 'الرجاء إدخال {{ MAX_CHARS }} من الأحرف كحد أقصى.',
  THANK_YOU_MESSAGE: 'رسالة شكر',
  AS: 'مثل',
  THERAPEUTIC_AREA_PARENT: 'منطقة الوالدين',
  MAIN_THERAPEUTIC_AREA: 'المنطقة العلاجية الرئيسية',
  CHOOSE_AN_IMAGE: 'اختر صورة',
  ALLOWED_FORMATS: 'يُسمح فقط بتنسيقات {{ FORMATS }}',
  REMOVE_IMAGE: 'إزالة الصورة',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'يرجى التحقق من المناطق العلاجية الموجودة في الجدول الموجود خلف مربع الحوار حيث أن لدينا بالفعل مناطق بنفس القيم.',
  PLAIN_TEXT_VIEW: 'عرض نص عادي',
  RICH_TEXT_VIEW: 'عرض النص الغني',
  EDIT_ITEM: 'قم بتحرير {{ ITEM }}',
  CREATE_NEW_ITEM: 'أنشئ {{ ITEM }} جديد',
  ADD_NEW_ITEM: 'أضف {{ ITEM }} جديد',
  ADD_ITEM: 'أضف {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: 'ومن ثم تغير الوضع من',
  FOLLOW_UP_WITH_ENQUIRER: 'متابعة مع المستفسر',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'يجب إدخال ما يلي لحفظ التغييرات:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION: 'المتابعة غير ممكنة للتفاعل المغلق.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'التفاعل في حالة المراجعة. الرجاء حفظ المراجعة لتمكين هذه القائمة.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'نص السؤال (6 أحرف على الأقل)',
  PLEASE_PROVIDE_AN_ANSWER_: 'يرجى تقديم إجابة (6 أحرف على الأقل أو مرفق).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    'يرجى ملاحظة أنه يتعين على المستخدم استعادة كلمة المرور من "نسيت كلمة المرور".',
  ENABLE_USER_LOGIN_MESSAGE: 'سيؤدي هذا إلى تمكين تسجيل دخول المستخدم.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'سيؤدي هذا إلى تعطيل تسجيل دخول المستخدم والإشعارات.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'يرجى ضبط تفضيلات الإشعارات من ملف تعريف المستخدم الخاص بك!',
  SET_PASSWORD: 'يرجى تعيين كلمة المرور الخاصة بك',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'بعد إعادة تنشيط حسابك، تم مسح كلمة المرور الخاصة بك.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'تأكيد تعطيل التخصص!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'سيؤدي هذا إلى إزالته من قائمة التخصصات النشطة.',
  GO_TO_ROLES: 'انتقل إلى الأدوار',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'أدت التغييرات التي أجريتها على معلومات اتصال المستخدم إلى تغيير البريد الإلكتروني لتسجيل الدخول من {{ OLD_USERNAME }} إلى {{ NEW_USERNAME }}',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'يرجى تعيين الأدوار والإخطارات.',
  DO_YOU_WANT_TO_CONTINUE: 'هل تريد الاستمرار؟',
  COPY_ANSWER_TO_NEW_INTERACTION: 'انسخ الإجابة على التفاعل الجديد',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'تم استيراد استفسارات جديدة. تأكد من وصف جميع الاستفسارات والإجابة عليها.',
  LEAVE_WITHOUT_SAVING: 'ترك دون حفظ',
  PENDING_CHANGES_MESSAGE: 'التغييرات المعلقة هي {{ CHANGES }}؟',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'هل أنت متأكد أنك تريد تقسيم النص المحدد في التفاعل الجديد؟',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'سيؤدي هذا إلى إزالة النص المحدد من التفاعل وإنشاء نص جديد منه.',
  DOWNLOAD_ERROR: 'الخطأ تحميل!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'أنت تحاول تنزيل أكثر من 1000 استفسار. الحد الأقصى للرقم المدعوم هو 1000. يرجى تحسين اختيارك.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION: 'أنت تحاول تغيير توصيف السؤال!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'يرجى ملاحظة أن تغيير توصيف السؤال لن يسمح بأي تغييرات في الإجابة المقدمة مسبقًا أو يسمح بإرسال إجابة جديدة!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE: 'أنت على وشك إعادة فتح تفاعل مغلق لإرسال إجابة جديدة.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: 'هل أنت متأكد أنك تريد تحويل هذه المتابعة إلى سؤال؟',
  ARE_YOU_SURE: 'هل أنت متأكد؟',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'تأكيد إلغاء تنشيط {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'تأكيد إعادة تنشيط {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: 'تمت إعادة تنشيط المستخدم {{ USER_INFO }} بنجاح!',
  DELETE_NOT_POSSIBLE: 'الحذف غير ممكن!',
  SEARCH_NO_LONGER_EXIST: 'لم يعد هذا البحث موجودًا لأنه تم حذفه بواسطة المرسل.',
  LOGIN_CREDENTIALS_CHANGED: 'تم تغيير بيانات اعتماد تسجيل الدخول!',
  EDIT_TEXT: 'تحرير النص',
  FOLLOW_UP_ATTEMPTS: 'محاولات المتابعة',
  FIRST_FOLLOW_UP_INFO_TEXT: 'المتابعة الأولى المرسلة ضمن التفاعل الفردي',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'إذا تم إلغاء تنشيط أي مما يلي بعد الطلب الأولي، فستنتقل العملية إلى الخطوة التالية',
  SECOND_ATTEMPT: 'المحاولة الثانية (التذكير الأول)',
  THIRD_ATTEMPT: 'المحاولة الثالثة (التذكير الأول)',
  FINAL_ATTEMPT: 'فقدت المتابعة (إغلاق)',
  REMINDER_FREQUENCY: 'تردد التذكير',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE: 'تطبيق الترجمة الآلية إلى اللغة المستخدمة للتواصل مع المستفسر',
  ON_OFF_SWITCH: 'تشغيل/إيقاف',
  DAY: 'يوم',
  DAYS: 'أيام',
  MONTH: 'شهر',
  MONTHS: 'شهور',
  RESET_TEMPLATES_TO_DEFAULTS: 'إعادة تعيين القوالب إلى الإعدادات الافتراضية',
  SAVE_FREQUENCY: 'حفظ التردد',
  SELECT_FREQUENCY: 'حدد التردد',
  TEMPLATE_MODIFICATIONS_HINT:
    'تم تعديل النص يدويًا ولن يتم تغييره تلقائيًا بعد الآن. انقر هنا لتجاهل التعديلات وإعادة تمكين التحديثات التلقائية.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'عنوان البريد الألكتروني غير صحيح!',
  AUDIT_TRAIL_FOR: 'مسار التدقيق لـ {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'مسار التدقيق لجميع {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'التاريخ يتغير ل',
  HOLIDAY_GDPR_DISCLAIMER:
    'إخلاء المسؤولية عن اللائحة العامة لحماية البيانات (GDPR) الخاصة بالعطلات',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER:
    'إدارة إخلاء المسؤولية عن اللائحة العامة لحماية البيانات أثناء العطلات',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'نموذج اللائحة العامة لحماية البيانات (GDPR) الخاص بالعطلة مفقود للفريق/الفرق التالية',
  BULK_CREATE: 'إنشاء بالجملة',
  LAST_SUCCESS: 'النجاح الأخير',
  LAST_FAILURE: 'الفشل الأخير',
  FAILURE: 'فشل',
  TASK: 'مهمة',
  Weeks: 'أسابيع',
  REPEAT_EVERY: 'كرر على كل',
  DEACTIVATE_ITEM: 'إلغاء تنشيط {{ ITEM }}',
  RESET_FAILURE_COUNT: 'إعادة تعيين عدد الفشل',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'يرجى التحقق من المهام الموجودة في الجدول الموجود خلف مربع الحوار حيث لدينا بالفعل مهمة بنفس القيم.',
  ACTIVATE_ITEM: 'تفعيل {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'الرجاء إدخال رقم أكبر من {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'التفاعل المستهدف المحدد للدمج مغلق بالفعل. يعد هذا الاستعلام تفاعلًا جديدًا ولا حاجة لدمجه.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'سيتم إضافة سؤال/أسئلة جديدة في الاستفسار الموجود، ويجب الإجابة على جميع الأسئلة. الرجاء تحديد الأسئلة للدمج.',
    VALIDATION_MSG: 'يرجى تحديد سؤال واحد على الأقل من التفاعل المدمج لمتابعة عملية الدمج.',
  },
  MERGED_INTERACTION: 'التفاعل المدمج',
  TARGET_INTERACTION: 'التفاعل المستهدف',
  MERGE_SUGGESTION: 'دمج الاقتراح',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'يرجى مراجعة اقتراح الدمج أدناه',
  INQUIRERS_COMPARISON_TITLE: 'يرجى ملاحظة المستفسرين المختلفين للتفاعل المدمج والتفاعل المستهدف',
  INQUIRERS_COMPARISON_QUESTION: 'يرجى تأكيد ما إذا كنت ترغب في المتابعة بالرغم من هذا الاختلاف.',
  TERRITORY: 'إِقلِيم',
  DOMAIN: 'اِختِصاص',
  IN: 'في',
  TEAM_GROUP_FILTER: {
    TITLE: 'تحديد الأذونات لعرض فرق إضافية',
    SAVE_BTN_TOOLTIP: 'الرجاء إدخال جميع الحقول الإلزامية للفريق وحفظ الفريق أولاً.',
  },
  ADR_REF_ID: 'معرف مرجع ADR',
  PQC_REF_ID: 'معرف مرجع PQC',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: 'هذا المرفق موجود في تفاعلات متعددة',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'يرجى تحديد خيار خانة الاختيار إذا كنت ترغب في حذف المرفق من جميع التفاعلات.',
  CONFIRM_DELETE_ATTACHMENT: 'يرجى تأكيد ما إذا كنت ترغب في حذف هذا المرفق.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'حذف من كافة التفاعلات المذكورة أعلاه',
  ATTACHMENT_DELETED: 'تم حذف المرفق',
  ROLES: 'الأدوار',
  SET_TO_ALL_INQ_TOOLTIP: 'قم بتعيين نفس ADR، وPQC، والتوصيف خارج التسمية لجميع الأسئلة.',
  TASKS_LIST: 'قائمة المهام',
  TASK_LOGS: 'سجلات المهام',
  SELECTED: 'المحدد',
  LICENSE_COUNTER: 'عداد الترخيص',
  LICENSES_LIMIT: 'حدود أرقام الترخيص',
  USERS_TO_EXCLUDE: 'المستخدمين المراد استبعادهم',
  ENTER_MAX_NUMBER_OF_LICENSES: 'رسوم الترخيص المتعاقد عليها (أدوار المستخدم).',
  SELECT_USER_ROLES_TO_COUNT: 'حدد أدوار المستخدم المطبقة على حساب رسوم الترخيص.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'استبعاد المستخدمين من حساب رسوم الترخيص.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'إشعارات الاستلام والمبيعات',
  REFRESH_MIGRATION_ERRORS: 'قم بتحديث قائمة أخطاء الترحيل',
  MARKED_AS_RESOLVED_BY: 'تم وضع علامة "تم الحل" بواسطة',
  MARKED_AS_RESOLVED_ON: 'تم وضع علامة "تم الحل" عليه',
  MARK_AS_RESOLVED: 'وضع علامة "تم الحل".',
  ERROR_ON: 'حدث خطأ',
  TEXT_WITH_SPECIAL_CHARS:
    'الرجاء إدخال الحروف فقط (a-z، A-Z)، والأرقام (0-9)، والرموز الخاصة مثل الشرطة (-)، والنقطة (.)، والمسافة، والنقطتين (:)، والفاصلة (،)، والأقواس (())، والأمام شرطة مائلة (/)، وعلامة الجمع (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'التفاعل في حالة التقديم للموافقة. يرجى الموافقة/الرفض لتمكين هذه القائمة.',
  REFRESH_TASK_LOGS: 'تحديث قائمة سجلات المهام',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE: 'يرجى التأكيد، إذا كنت ترغب في متابعة التغييرات!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'الأرشفة اليدوية غير مسموح بها للأدوار المفوضة.',
  RUN_COUNTER: 'تشغيل العداد',
  EXPORT_LICENSES: 'تراخيص التصدير',
  LICENSE_COUNT_MESSAGE: 'عدد التراخيص المستخدمة هو:',
  QUESTIONS: 'أسئلة',
  CLARIFICATION: 'إيضاح',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG: 'يرجى تحديد سؤال واحد على الأقل في التفاعل المستهدف لمتابعة عملية الدمج.',
    DESCRIPTION: 'ستتم إضافة سؤال التوضيح ضمن السؤال (الأسئلة) المحددة في التفاعل المستهدف.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION: 'حدد هذا الخيار لوضع علامة على التفاعل كتكرار للتفاعل الموجود.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'الدمج السابق عند اكتشاف سؤال توضيحي. سيتم إعادة فتح جميع حقول الإجابة المحددة بالإضافة إلى حقول الإجابة المحددة مسبقًا في التفاعل المستهدف للإجابة.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'تم إعادة فتح التفاعل لتقديم إجابة على سؤال آخر. لقد تم بالفعل تقديم الإجابة على هذا السؤال مسبقًا.',
  TIP: 'نصيحة',
  MERGE_TIP_TEXT:
    'إذا كنت ترغب في إعادة فتح التفاعل {{ MERGED_INTO_ID }} بدلاً من ذلك، يرجى الضغط على "لا" وبدء عملية دمج جديدة تشير إلى التفاعل المستهدف {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN: 'أنت تقوم بتحديد عملية دمج دون فتح التفاعل المستهدف.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'أنت تقوم بتحديد عملية دمج في تفاعل هدف غير صالح {{ TARGET_INTERACTION_ID }}.<br>تم إغلاق التفاعل {{ TARGET_INTERACTION_ID }} بالحالة {{ STATUS }}{{ LAST_MERGE_INFO }}.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'سيؤدي ذلك إلى تفاعل مغلق وعدم القدرة على تقديم إجابة.',
  INTO_INTERACTION: 'في التفاعل',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'الرجاء تحديد التفاعل للدمج.',
  PLEASE_SELECT_MERGE_REASON: 'الرجاء تحديد سبب الدمج.',
  QUESTION_CANNOT_BE_DELETED:
    'لا يمكن حذف هذا السؤال نظرًا لوجود سؤال توضيحي مرتبط به لم تتم الإجابة عليه.',
  MERGE_REASON: 'دمج السبب',
  WITH_REOPEN: 'مع إعادة فتح',
  MERGED_INTO_QUESTION_IDS: 'تم دمجها في معرفات الأسئلة',
  MERGED_INTO_QUESTIONS: 'تم دمجها في السؤال (الأسئلة)',
  MERGED_PREVIOUSLY_FROM: 'تم دمجها من قبل',
  FROM_INTERACTION_ID: 'من معرف التفاعل',
  IN_INTERACTION_ID: 'في معرف التفاعل',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'أنت على وشك إلغاء اقتراح الدمج.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'لا تظهر اقتراح الدمج مرة أخرى؟',
  PRODUCT_RESOLVED_VIA_AI: 'المنتج (تم الحل عبر الذكاء الاصطناعي)',
  REQUIRED_FIELDS_HINT: 'يرجى ملء جميع الحقول المطلوبة',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'مطلوب جهة اتصال واحدة على الأقل!',
  DISCLAIMER_INFO: 'معلومات إخلاء المسؤولية',
  QUESTION_ID: 'معرف السؤال',
  QUESTION_IDS: 'معرفات السؤال',
  SHOW_TEAMS: 'عرض الفرق',
  LAST_POLLING: 'آخر الاقتراع',
  HOST: 'يستضيف',
  PROTOCOL: 'بروتوكول',
  FROM_EMAIL: 'من البريد الإلكترونى',
  REASON_FOR_REMOVAL: 'سبب إزالة',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'التفاعل المغلق غير قابل للتحرير.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT: 'التفاعل فيه أكثر من سؤال. التحرير ممكن من التفاعل المفتوح فقط.',
  BULK_EDIT: 'تحرير بالجملة',
  HOW_TO_ENABLE_BULK_EDIT: 'يجب تحديد نموذجين على الأقل بنفس اللغة لإجراء تحديث مجمع.',
  REASON_FOR_UPDATE: 'سبب التحديث',
  FOLLOW_UP_RESEND: 'إعادة إرسال المتابعة',
  SENT_FOLLOW_UP_REMINDER: 'أرسلت تذكير المتابعة',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'تم إغلاق التفاعل وإرسال إشعار مفقود للمتابعة',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'الرجاء إدخال نص بطول لا يقل عن 15 حرفًا.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST: 'المتابعة معطلة! الرجاء إدخال المستفسر أولا.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'تم تعطيل طلب المتابعة! الرجاء إدخال البريد الإلكتروني الصحيح للمستفسر أولاً.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'يجب أن يكون الرقم الخاص بنوع التردد المحدد بين 1 و{{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'الرجاء تحديد نوع التردد.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'يبدأ العد من تاريخ ووقت طلب المتابعة الأولي. لتحويل الأيام إلى أسابيع، اضرب في 7، على سبيل المثال. أسبوعين = 14 يومًا، للأيام إلى الأشهر مضروبًا في 30، على سبيل المثال. شهر واحد = 30 يومًا.',
  VALID_PHONE_FAX: 'الرجاء إدخال رقم هاتف / فاكس صالح!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES: 'حدد لغة لعرض القوالب المترجمة وإدارتها',
  RETRANSLATE: 'إعادة الترجمة',
  FOLLOW_UP_REMINDERS_INFO_TEXT: 'القوالب أدناه باللغة الإنجليزية فقط، ويتم إدارة الترجمات أدناه',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'سيتم إعادة تعيين جميع القوالب أعلاه إلى الإعدادات الافتراضية (سيتم إزالة التعديل اليدوي، وإعادة تعيين التردد إلى 14 يومًا، وإلغاء تحديد مربع الاختيار الخاص بالترجمة)، وإعادة ترجمتها إلى جميع اللغات وسيتم مسح is_manually_modified.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'تحرير نص القالب وإعادة ترجمة جميع الترجمات التي لم يتم تعديلها يدويًا.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'إعادة ترجمة نص القالب الأصلي، بما في ذلك تلك التي تم تعديلها يدويًا.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'قوالب تذكير المتابعة',
  MANUALLY_MODIFIED: 'تم تعديلها يدويًا',
  SYNC: 'مزامنة',
  DIFFERENT: 'مختلف',
  DEFAULT_CONFIRMATION_QUESTION: 'يرجى تأكيد ما إذا كنت ترغب في المتابعة.',
  SEND_WELCOME_MAIL_: 'إرسال بريد إلكتروني ترحيبي مع إعادة تعيين كلمة المرور',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'أؤكد أن المستفسر قد تلقى معلومات متابعة جديدة وأنني أرغب في إعادة فتح التفاعل لتقديم الإجابات.',
  ONLY_LETTERS_SPACE_AND_DASH: 'من فضلك أدخل فقط الأحرف والمسافة والرمز -!',
  INFO: 'معلومات',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'سيؤدي التغيير في بلد السائل إلى تغيير مهمة التفاعل من الفريق {{ CURRENT_TEAM }} إلى الفريق {{ FUTURE_TEAM }}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'سيؤدي تغيير بلد السائل إلى تغيير مهمة التفاعل من الفريق {{ CURRENT_TEAM }} إلى الفريق {{ FUTURE_TEAM }}. لا يمتلك المستخدم {{ ASSIGNED_TO }} أذونات لفريق {{ FUTURE_TEAM }} وسيتم إزالة مهمته الشخصية.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'سيؤثر التغيير على مهام الفريق في التفاعلات الإضافية التالية، حيث سيتم إزالة المهام الشخصية: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'سيؤدي تغيير بلد السائل إلى تغيير مهام الفريق في التفاعلات الإضافية التالية، حيث سيتم إزالة التعيين الشخصي. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'معرف التفاعل: {{ INTERACTION_ID }} من {{ CURRENT_TEAM }} إلى {{ FUTURE_TEAM }}، تمت إزالة التعيين الشخصي من المستخدم {{ ASSIGNED_TO }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'قام المستخدم {{ EDITING_USER }} بتغيير بلد السائل {{ ENQUIRER }} في معرف التفاعل: {{ UPDATED_INTERACTION_ID }}، مما أدى إلى تغيير تعيين التفاعل الحالي من الفريق {{ OLD_TEAM }} إلى الفريق {{ NEW_TEAM }}.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'قام المستخدم {{ EDITING_USER }} بتغيير بلد المستعلم {{ ENQUIRER }} في إدارة HCP، مما أدى إلى تغيير تعيين التفاعل الحالي من الفريق {{ OLD_TEAM }} إلى الفريق {{ NEW_TEAM }}.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'المستخدم {{ INTERACTION_ASSIGN_TO }} ليس لديه أذونات لفريق {{ NEW_TEAM }} وتم إزالة مهمته الشخصية.',
  PERSONAL_ASSIGNMENT: 'مهمة شخصية',
  DUE_TO_FU_REQUEST: 'بسبب طلب المتابعة',
  DUE_TO_FU_RESPONSE: 'بسبب الرد المتابعة',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE: 'بسبب تحديث تردد التذكيرات المتابعة من صفحة الإدارة',
  UPDATED_FROM_INBOX: 'تم التحديث من البريد الوارد',
  ARCHIVED_ROLE: 'الدور المؤرشف',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'سيؤدي أرشفة دور المستخدم إلى تغيير المهام في التفاعلات الإضافية التالية، حيث سيتم إزالة المهام الشخصية: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'قام المستخدم {{ EDITING_USER }} بتحرير المستخدم {{ INTERACTION_ASSIGN_TO }}، وأرشفة دوره كـ {{ USER_ROLE }} في الفريق {{ TEAM }}. ليس لدى المستخدم {{ INTERACTION_ASSIGN_TO }} أذونات لفريق {{ TEAM }} وتمت إزالة مهمته الشخصية.',
  ASSIGNMENT: 'تكليف',
  PERIOD_TO_PERIOD_OVERLAP: 'الفترة و الفترة إلى متداخلة',
  PERIOD_TO_PERIOD_MISMATCH:
    'الفترة والفترة إلى لها مدة مختلفة - {{ PERIOD }} {{ INTERVAL }} و {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'تم إنشاء سؤال من الاستجابة المتابعة',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP: 'يسمح لك هذا الخيار بإنشاء سؤال جديد من المتابعة.',
  ADD_AS_A_NEW_QUESTION: 'أضف كسؤال جديد',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'يرجى الانتباه إلى أنك تحاول أرشفة آخر دور معتمد لهذا المستخدم، مما سيؤدي إلى إلغاء تنشيط المستخدم ومنع وصوله إلى EnqMed.',
  ATTEMPTED_FOLLOW_UP: 'حاولت المتابعة',
  FAILED_FU_ATTEMPT: 'محاولة متابعة فاشلة',
  UNABLE_TO_REACH_ENQUIRER: 'لم نتمكن من الوصول إلى السائل.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'حدد هذا الخيار إذا كانت محاولة الاتصال بالمستفسر قد فشلت، على سبيل المثال، تعذر الاتصال عبر الهاتف، أو فشل الاتصال وجهاً لوجه أو تحديد موعد آخر.\nسيتم تسجيل المحاولة في سجل التفاعل.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'سيتم إعادة توجيهك إلى الصفحة التي تمتلك الأذونات الخاصة بها.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE: 'بسبب توقف عملية المتابعة نتيجة تغيير المستفسر',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'أنت على وشك تبديل السائل، مما سيؤدي إلى إيقاف عملية المتابعة.',
  INQUIRER_DETAILS: 'تفاصيل المستعلم',
  TEAM_NOTIFY_INQUIRER: 'إخطار السائل',
  INQUIRER_TYPE: 'نوع السائل',
  DELETE_INQUIRER_PII: 'حذف المعلومات الشخصية للمستفسر',
  MARKED_INQUIRER_PII_DELETE: 'تم وضع علامة على المعلومات الشخصية للمستفسر للحذف',
  UNMARKED_INQUIRER_PII_DELETE: 'معلومات شخصية للمستفسر غير محددة للحذف',
  NEW_ENQUIRER: 'سائل جديد',
  NEW_ENQUIRER_TOOLTIP:
    'استخدم هذا الخيار لإنشاء مستفسر جديد تمامًا. إذا كان من المطلوب تحرير بيانات المستفسر الحالية بدلاً من ذلك، فلا تستخدم هذا الخيار.',
  CONFIRM_SET_NEW_ENQUIRER:
    'يرجى تأكيد رغبتك في استبدال المستعلم الحالي بآخر جديد.<br>سيتم فقد جميع المعلومات المدخلة.<br>إذا كنت ترغب بدلاً من ذلك في تحرير بيانات المستعلم الحالي، فقم بتعديل البيانات مباشرة في النموذج واضغط على حفظ.',
  INQUIRER: 'السائل',
  KEEP_INQUIRER_DETAILS_OPEN: 'إبقاء تفاصيل المستفسر مفتوحة',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'لقد قمت بإجراء تغييرات غير محفوظة، والتي سيتم فقدها إذا قمت بإغلاق تفاصيل المستعلم.',
  SHARE_FROM_HERE_YOU_CAN_: 'المشاركة: من هنا يمكنك تعيين أو إعادة توجيه أو بدء متابعة مع السائل.',
  UPDATED_INQUIRER_DETAILS: 'تم تحديث تفاصيل المستعلم',
  CREATED_INQUIRER: 'تم إنشاء السائل',
  SUGGESTED_INQ: 'الاستفسارات المقترحة',
  SELECT_INQUIRER: 'اختر المستعلم',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'يجب اختيار موافقة التزام تسوية المنازعات البديلة والمستفسر، من أجل تقديم التفاعل',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'لم يتم إرسال الإجابة إلى السائل بسبب عنوان البريد الإلكتروني الخاطئ.',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'شكرًا لك! سيتم إرسال الإشعار بمجرد تقديم الاستفسار أو الرد عليه.',
  MSG_NOTIFICATION_WILL_BE_SEND_2: 'شكرًا لك! سيتم إرسال الإشعار بمجرد حفظ السائل.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'سيتم إرسال إشعار GDPR التالي إلى المستفسر عبر البريد الإلكتروني.',
  NOTIFICATION_SENT_TO_THE_INQ: 'تم إرسال إشعار GDPR التالي إلى السائل عبر البريد الإلكتروني.',
  SEND_A_NOTIFICATION_TO_THE_INQ: 'أرسل إشعار GDPR التالي إلى المستفسر عبر البريد الإلكتروني.',
  INQ_HAS_NO_EMAIL: 'السائل ليس لديه بريد إلكتروني.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ: 'لم يتم إرسال إشعار إلى السائل بأنه تم استلام الاستفسار.',
  NOTIFICATION_SENT_TO_THE_INQUIRER: 'تم إرسال إشعار إلى السائل بأنه تم استلام الاستفسار.',
  THIS_IS_JUST_A_THANK_YOU: 'هذه مجرد رسالة شكر من السائل عن الاستفسار الموجود.',
  SAVE_INQUIRER: 'احفظ المستفسر',
  INQ_DEFAULT_VAL: 'القيم الافتراضية للمستفسر',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'بالنسبة للاستفسارات المقدمة عن طريق ممثل الشركة (مندوب المبيعات، MSL، أو غير ذلك)، قم بإخطار السائل حول التعامل مع بياناته الشخصية.',
  SELECT_PREFERRED_CONTACT_ERROR: 'يجب عليك تحديد جهة الاتصال المفضلة للسائل.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'المتابعة التي بدأها السائل',
  NO_INQUIRER: 'لا يوجد سائل',
  'Changed inquirer': 'تم تغيير السائل',
  DELETE_INQUIRY_MESSAGE: 'أنت على وشك حذف هذا الاستفسار. هذا الإجراء غير قابل للتراجع.',
  QUESTION_REQUIRE_ANSWER_MESSAGE: 'السؤال الجديد سيتطلب إجابة لإغلاق الاستفسار.',
  IMAGE: 'صورة',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'المنطقة العلاجية هي {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    'نظرًا لعدم وجود منتجات مرتبطة، يُرجى تحديد منتج واحد على الأقل لتعيين المنطقة العلاجية على نشطة.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'من خلال {{ ACTION_TYPE }}، سيتم تطبيق {{ METADATA_TYPE }} المحدد، على إلغاء تنشيط {{ AFFECTED_ITEMS_TYPE }} ذات الصلة التالية:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'من خلال إزالة المنتجات، سيتم إلغاء تنشيط البيانات الوصفية ذات الصلة والمنتجات نفسها:',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'توسيع كافة المجالات العلاجية',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'انهيار جميع المجالات العلاجية',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'لتعيين منطقة علاجية (TA) كمنطقة علاجية رئيسية، قم بإلغاء تحديد منطقة العلاج الأساسية ثم احفظ.',
};
